import IconButton from "../icon-button";
import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledDialogTitleWrapper,
  StyledDownloadBtn
} from "./qrcode-dialog-styles";
import { TQrcodeDialogProps } from "./types";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const QrcodeDialog = ({
  isOpen,
  onClose,
  children,
  handleDownload,
}: TQrcodeDialogProps) => {
  
  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
    >
      <StyledDialogTitleWrapper>
        <StyledDialogTitle>QR Code</StyledDialogTitle>
        <IconButton
          onClick={onClose}
          isWhite
          iconSize={1.5}
        />
      </StyledDialogTitleWrapper>
      <StyledDialogContent>
        {children}
      </StyledDialogContent>
      <StyledDownloadBtn onClick={handleDownload}>
        <CloudDownloadIcon />
        Fazer download
      </StyledDownloadBtn>
    </StyledDialog>
  )
};

export default QrcodeDialog;