import styled from "styled-components";
import { TextField } from "@mui/material";

export const StyledInput = styled(TextField)`
  height: 2.5rem;

  .MuiOutlinedInput-root {
    height: 2.5rem;
  }

  .MuiOutlinedInput-input {
    padding: 0;
    padding-left: 14px;
    height: 2.5rem;
  }
`;