import { SvgIcon } from '@mui/material';
import React from 'react';

const WarningIcon: React.FC = () => {
    return <SvgIcon viewBox="0 0 30 29">
        <rect x="0.812744" y="0.61676" width="29.0308" height="27.4391" rx="8" fill="#FFB219" />
        <path d="M13.0762 7.91689C14.0771 6.18338 16.5792 6.18338 17.58 7.9169L23.4913 18.1555C24.4921 19.889 23.241 22.0559 21.2394 22.0559H9.41689C7.4152 22.0559 6.16415 19.889 7.16499 18.1555L13.0762 7.91689Z" fill="white" />
        <path d="M16.5137 10.4765L16.2296 16.3681H14.1669L13.8829 10.4765H16.5137ZM15.2045 19.7276C14.7763 19.7276 14.4304 19.6124 14.1669 19.3818C13.9117 19.143 13.7841 18.8425 13.7841 18.4802C13.7841 18.1179 13.9117 17.8173 14.1669 17.5785C14.4304 17.3397 14.7763 17.2203 15.2045 17.2203C15.6244 17.2203 15.962 17.3397 16.2173 17.5785C16.4808 17.8173 16.6125 18.1179 16.6125 18.4802C16.6125 18.8342 16.4808 19.1307 16.2173 19.3694C15.962 19.6082 15.6244 19.7276 15.2045 19.7276Z" fill="#FFB219" />
    </SvgIcon>
}

export default WarningIcon;