import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { FileUploader } from "react-drag-drop-files";
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import CheckCircle from '@mui/icons-material/CheckCircle';

import { 
  Description, 
  DescriptionWrapper, 
  StyledFileUpload, 
  StyledInputBox,
  StyledLabelGroup,
  StyledInputLabel,
  StyledButtonAction,
  IconTooltip,
} from "./file-input-style";
import { TFileInputProps } from "./type";
import IconButton from "../../icon-button";
import MidiaFileDialog from "../../midia-file-dialog";
import { Tooltip } from "@mui/material";

const FileInput = ({ label, name, onChange, value, activeBottomClose, fileOrFiles, handleRemoveImage, titleTooltip }: TFileInputProps) => {
  const fileTypes = ["PNG"];
  const [ isMidiaModalOpen, setIsMidiaModalOpen ] = useState(false);
  const [ fileName, setFileName ] = useState('');
  const [ imageUrl, setImageUrl ] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let file = ''
    
    if(value[name]) {
      if(typeof value[name] === 'string'){
        file = value[name];
        const splitFile = file.split('/');
        setFileName(splitFile[splitFile.length - 1]);
        setImageUrl(value[name])
      } else {
        setFileName(value[name].name);
        UrlImage(value[name])
        setImageUrl('')
      }
    }

  }, [name, value])
  
  
  function UrlImage(value: File) {
    const fr = new FileReader();
    fr.onload = () => {
      const url = fr.result;
      setImageUrl(String(url));
    }
    fr.readAsDataURL(value);
  }

  function handleChangeFile(file: File) {
    onChange(name, file);
    UrlImage(file);
  }

  function handleResetInputFile() {
    handleRemoveImage();
    setImageUrl('');
    setFileName('');
  }
  
  return (
    <StyledInputBox>
      <StyledLabelGroup>
        <label htmlFor="first_logo">{label}</label>
        <Tooltip title={titleTooltip}>
          <IconTooltip />
        </Tooltip>
      </StyledLabelGroup>
        <FileUploader 
          label="Carregue ou solte um arquivo aqui"
          classes="drag_styled"
          name={name} 
          types={fileTypes}
          onTypeError={() => enqueueSnackbar("O tipo de arquivo não é compatível!", {variant: 'warning'})}
          handleChange={handleChangeFile}
          fileOrFiles={fileOrFiles}
        >
          <StyledInputLabel hasFiles={value[name]}>
            {value[name] ? <CheckCircle /> : <AddPhotoAlternateOutlinedIcon />}
            <DescriptionWrapper>
              <Description>
                { value[name] ? 'Carregado com sucesso!' : 'Carregue um arquivo!' }
              </Description>
              <span title='types: PNG'>{fileTypes}</span>
            </DescriptionWrapper>
          </StyledInputLabel>
        </FileUploader>

      { fileName && (
        <StyledFileUpload>
          <p>{`Nome do arquivo: ${fileName}`}</p>
          <StyledButtonAction>
            {activeBottomClose && (
              <IconButton
                iconSize={1.5}
                onClick={handleResetInputFile} 
                iconType="Close"
                title="Excluir mídia"
              />
            )}

            { imageUrl  && 
            <IconButton
              iconSize={1.5}
              onClick={()=>setIsMidiaModalOpen(true)} 
              iconType="Visibility"
              title="Visualizar mídia"
            /> 
            }
          </StyledButtonAction>
        </StyledFileUpload>
      )}

      <MidiaFileDialog
        isOpen={isMidiaModalOpen}
        onClose={() => setIsMidiaModalOpen(false)}
      >
        <img src={imageUrl} alt=""/>
      </MidiaFileDialog>
    </StyledInputBox>
  )
}

export default FileInput;
