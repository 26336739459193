import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import Icon from '../Icon/Icon';
import EllipseIcon from '../icons/elipse';
import StackApp from './stack-app';
import { ResultsType } from '../locale-card/locale-card';

const Container = styled(Box)`
    
`

export type StackAppsProps = {
  apps: any[];
  name: string;
  result: ResultsType;
  relevance: string;
  isHighRelevance: boolean;
}

const ScrollableArea = styled(Stack)`
padding-bottom: 15px;
  &::-webkit-scrollbar {
    width: 8px; /* Largura da scrollbar */
    height: 8px; /* Altura da scrollbar no eixo X */
    background: #0039A8;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.48);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px; /* Raio da borda da thumb */
    background: #0080FF;
    width: 114px;
  }

  &::-webkit-scrollbar-thumb:hover {
    // background-color: #555; /* Cor da thumb ao passar o mouse por cima */
  }`

const StackApps: React.FC<StackAppsProps> = ({ apps, name, result, isHighRelevance }) => {

  return (
    <Container>
      <Stack gap={2}>
        <Stack direction="row" gap={2} alignItems="center">
          <Icon color="white" name="home" prefix="sr" fontSize='24px' />
          <Typography fontSize="24px" color="white">{name}</Typography>
          <EllipseIcon color={isHighRelevance ? '#14CC33' : '#FF6363'} fontSize="16px" />
          <Typography color="white">{isHighRelevance ? 'Alta relevancia' : 'Baixa relevancia'}</Typography>
        </Stack>
        <ScrollableArea direction="row" gap={2} sx={{ overflowX: 'auto' }}>
          {apps.map(({ icon: AppIcon, name: appName }: any) => (
            <StackApp icon={AppIcon} address={result.address} appName={appName} name={result.name} phone={result.phone} result={result} />
          ))}
        </ScrollableArea>
      </Stack>
    </Container>
  )
}

export default StackApps;