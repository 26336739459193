import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CabifyIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox="0 0 394 500" {...props}>
            <path d="M348.747 340.883L216.881 458.972L86.9832 342.457C53.5247 308.212 35.0241 263.338 35.0241 216.496C35.0241 168.08 53.9183 122.419 88.1641 87.7794C122.41 53.5336 168.071 34.6394 216.881 34.6394C258.605 34.6394 297.968 48.4164 330.246 74.396L340.087 82.6622C347.172 88.9602 351.896 97.2264 352.289 105.886C352.683 114.152 349.927 122.419 344.023 128.717C337.331 135.802 327.884 139.738 318.043 139.738C308.99 139.738 300.33 136.589 293.638 130.685L286.553 125.568C266.478 110.61 242.86 102.344 217.668 102.344C154.294 102.344 103.122 153.515 103.122 216.496C103.122 247.593 114.931 276.328 136.974 297.977L137.761 298.371C159.017 317.265 200.742 354.266 205.859 358.596L217.668 369.618L303.873 291.679C310.171 286.168 318.043 283.019 326.31 283.019C335.757 283.019 344.81 286.956 351.108 294.041C362.917 307.424 362.13 328.68 348.747 340.883ZM371.577 367.65C399.525 342.064 401.493 298.765 375.907 270.817C362.917 256.646 344.417 248.38 325.129 248.38C308.203 248.38 291.67 254.678 279.074 266.093L216.881 322.776L216.487 322.382C197.987 305.85 165.315 276.721 160.985 273.179C146.028 258.221 137.761 238.146 137.761 216.89C137.761 173.197 173.582 137.377 217.274 137.377C234.988 137.377 251.52 142.887 265.297 153.515L271.595 158.239C284.585 168.867 300.724 174.771 317.65 174.771C337.725 174.771 356.619 166.505 369.609 152.335C381.811 138.951 388.109 121.631 386.929 103.525C385.748 85.0239 377.088 68.0979 362.524 55.8954L351.896 47.2356C313.714 16.5325 265.691 6.10352e-05 216.487 6.10352e-05C158.624 6.10352e-05 104.303 22.4369 63.3654 63.3743C22.428 104.312 0.384766 158.633 0.384766 216.496C0.384766 272.785 22.428 326.319 62.5782 367.256L64.1527 368.831L205.466 495.579C208.615 498.335 212.551 499.909 217.274 499.909C221.604 499.909 225.541 498.335 229.083 495.579L371.183 368.437C371.183 368.043 371.577 367.65 371.577 367.65Z" fill="#6F36FF" />
        </SvgIcon>
    )
}

export default CabifyIcon;