import styled from "styled-components";
import { TFormButtonProps } from "./types";

export const StyledFormButton = styled.button<TFormButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: ${(props) =>
    !props.variant || props.variant === "blue"
      ? props.theme.colors.primary
      : props.theme.colors.grey};
  color: ${(props) => props.theme.colors.white};
  padding: 0.5rem 2rem;
  gap: 5px;
  min-width: 10rem;
  max-width: max-content;
  border-radius: 5rem;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
`;
