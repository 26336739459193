import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const YahooIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox="0 0 905 905" {...props}>
            <circle cx="452.385" cy="452.385" r="452.385" fill="white" />
            <path d="M140.7 408.032H177.741L199.309 463.213L221.157 408.032H257.222L202.916 538.662H166.623L181.488 504.047L140.7 408.032Z" fill="#5F01D1" />
            <path d="M294.792 405.835C266.962 405.835 249.368 430.795 249.368 455.651C249.368 483.621 268.657 505.796 294.264 505.796C313.366 505.796 320.568 494.158 320.568 494.158V503.224H352.875V408.033H320.568V416.686C320.568 416.686 312.533 405.835 294.792 405.835ZM301.664 436.426C314.505 436.426 321.132 446.587 321.132 455.755C321.132 465.628 314.032 475.316 301.664 475.316C291.413 475.316 282.15 466.939 282.15 456.175C282.15 445.259 289.601 436.426 301.664 436.426Z" fill="#5F01D1" />
            <path d="M364.002 503.223V366H397.793V417.016C397.793 417.016 405.819 405.847 422.628 405.847C443.19 405.847 455.237 421.167 455.237 443.059V503.223H421.693V451.301C421.693 443.892 418.164 436.735 410.171 436.735C402.033 436.735 397.793 444.001 397.793 451.301V503.223H364.002Z" fill="#5F01D1" />
            <path d="M512.849 405.847C480.978 405.847 462 430.081 462 456.047C462 485.595 484.978 505.796 512.969 505.796C540.1 505.796 563.84 486.512 563.84 456.542C563.84 423.75 538.984 405.847 512.849 405.847ZM513.153 436.706C524.411 436.706 532.2 446.083 532.2 456.082C532.2 464.61 524.942 475.129 513.153 475.129C502.351 475.129 494.244 466.464 494.244 455.989C494.244 445.897 500.984 436.706 513.153 436.706Z" fill="#5F01D1" />
            <path d="M620.134 405.847C588.263 405.847 569.285 430.081 569.285 456.047C569.285 485.595 592.263 505.796 620.254 505.796C647.385 505.796 671.126 486.512 671.126 456.542C671.126 423.75 646.269 405.847 620.134 405.847ZM620.438 436.706C631.696 436.706 639.486 446.083 639.486 456.082C639.486 464.61 632.227 475.129 620.438 475.129C609.636 475.129 601.529 466.464 601.529 455.989C601.529 445.897 608.269 436.706 620.438 436.706Z" fill="#5F01D1" />
            <path d="M697.789 505.244C710.181 505.244 720.227 495.198 720.227 482.806C720.227 470.414 710.181 460.368 697.789 460.368C685.397 460.368 675.351 470.414 675.351 482.806C675.351 495.198 685.397 505.244 697.789 505.244Z" fill="#5F01D1" />
            <path d="M727.601 452.125H687.206L723.057 366H763.301L727.601 452.125Z" fill="#5F01D1" />
        </SvgIcon>
    )
}

export default YahooIcon;