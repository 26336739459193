import styled, { css } from "styled-components";
import { breakpoints, devices } from "../../assets/styles/devices";

import { ReactComponent as EventNotFoundImage } from '../../assets/images/event_not_found.svg';

export const StyledContentWrapper = styled.main`
  width: 100%;
`;

export const StyledContainer = styled.section`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  margin: 0;
  gap: 1rem;

  @media screen and ${devices.tabletLandscape} {
    flex-direction: row-reverse;
    max-height: 100vh;
    padding: 4rem 2rem;
  }
`;

export const StyledSection = styled.div`
  padding: 0 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and ${devices.tabletLandscape} {
    width: 50%;
    align-items: flex-start;
    padding: 0 1rem 0 2rem;
  }

  @media screen and ${devices.desktop} {
    max-width: 40rem;
    align-items: flex-start;
    padding: 0 1rem 0 2rem;
  }
`;

export const StyledLogo = styled.img<{ isOnTop?: boolean; }>`
  width: 190px;

  @media screen and (max-width: ${breakpoints.mobile}) {
    width: 40%;
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    ${props => !props.isOnTop && css`
      display: none;
    `}
  }
  
  @media screen and ${devices.tabletLandscape} {
    ${props => props.isOnTop && css`
      display: none;
    `}
  }
`;

export const StyledImage = styled(EventNotFoundImage)`
  width: 100%;
  height: 100%;

  @media screen and ${devices.tabletLandscape} {
    width: 60%;
    height: 60%;
  }

  @media screen and ${devices.desktop} {
    width: 50%;
    height: 50%;
  }
`;

export const StyledTitle = styled.h2`
  text-align: center;

  @media screen and ${devices.tabletLandscape} {
    text-align: left;
    font-size: 2rem;
  }

  @media screen and ${devices.desktop} {
    font-size: 2.5rem;
  }
`;

export const StyledText = styled.p`
  text-align: center;
  color: ${props => props.theme.colors.blackLight};

  a {
    font-weight: 700;
    color: ${props => props.theme.colors.primary};
  }

  a:hover {
    filter: brightness(0.8);
  }

  span { 
    color: ${props => props.theme.colors.primary};
    font-weight: 700;
  }
  
  @media screen and ${devices.tabletLandscape} {
    text-align: left;
  }
`;