export const filterFields = [
  ["name", "Nome"],
  ["company", "Empresa"],
  ["company_field", "Segmento"],
  ["pdv_quantity", "Qtd de PDVs"],
  ["phone", "Telefone"],
  ["email", "E-mail"],
  ["city", "Cidade"],
  ["state", "Estado"],
];

export const companyFieldOptions = [
  {
    text: "Selecione",
    value: "",
  },
  {
    text: "Não se Aplica",
    value: "NOT_APPLICABLE",
  },
  {
    text: "Academia",
    value: "GYM",
  },
  {
    text: "Alimentação",
    value: "FOOD",
  },
  {
    text: "Automotivo",
    value: "AUTOMOBILE",
  },
  {
    text: "Beleza",
    value: "BEAUTY",
  },
  {
    text: "Home Center",
    value: "CONSTRUCTION",
  },
  {
    text: "Moda",
    value: "FASHION",
  },
  {
    text: "Saúde",
    value: "HEALTH",
  },
  {
    text: "Serviços",
    value: "SERVICES",
  },
  {
    text: "Varejo e Atacado",
    value: "HEALTH",
  },
  {
    text: "Outros",
    value: "OTHER",
  },
];

export const pdvQuantityOptions = [
  {
    text: "Selecione",
    value: "",
  },
  {
    text: "Não se Aplica",
    value: "NOT_APPLICABLE",
  },
  {
    text: "1 Local",
    value: "ONE",
  },
  {
    text: "2 a 5 Locais",
    value: "TWOTOFIVE",
  },
  {
    text: "2 a 10 Locais",
    value: "TWOTOTEN",
  },
  {
    text: "6 a 10 Locais",
    value: "SIXTOTEN",
  },
  {
    text: "Acima de 10 Locais",
    value: "MORETHANTEN",
  },
];
