const Icon = ({ prefix, name, fontSize, color, display = 'flex' }: { name: string, prefix: string, fontSize?: string, color?: string, display?: string }) => {
    const className = `fi fi-${prefix}-${name}`;
    const style = {
        fontSize,
        color,
        display,
        alignItems: 'center',
        justifyContent: 'center',
    }
    return (
        <i className={className} style={style}></i>
    )
}

export default Icon