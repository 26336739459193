import { Accordion, Box } from "@mui/material";
import styled from "styled-components";

export const RelevanteStyled = styled(Box)`
display: flex;
align-items: center;
background: #F1F9F4;
border: 2px solid #D5E5D8;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
border-radius: 8px;
min-width: 180px;

@media only print {
  box-shadow: none;
}
`

export const AccordionStyled = styled(Accordion)`
  border-radius: 8px !important;
  border: 1px;
  border-top: 0;

  &::before {
    background-color: transparent !important;
  }

  @media only print {
    page-break-inside: avoid;
    box-shadow: none;
  }
`;