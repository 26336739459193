import { useCallback } from "react";
import IconButton from "../../icon-button";
import { TWebHookInputProps } from "./types";
import { StyledInput } from "./webhook-input-styles";

const WebhookInput = ({ setValue, value }: TWebHookInputProps) => {
  const handleCleanInput = useCallback(() => {
    setValue('');
  }, [setValue])

  return (
      <StyledInput
        variant="outlined"
        onChange={(e) => setValue(e.target.value)}
        value={value}
        fullWidth
        name="webhook"
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={handleCleanInput}
              iconSize={1.5}
              title="Limpar"
            />
          )
        }}
      />
  );
}

export default WebhookInput;