import { isNil } from "ramda";
import type { TGetDataRowCellValue } from "./types";

export const getDataRowCellValue = ({
  dataRowCell,
  columnRef,
}: TGetDataRowCellValue) => {
  const currentRowDataCell = dataRowCell.find(
    (rowDataCell: any) => rowDataCell && rowDataCell.columnRef === columnRef
  );

  return !isNil(currentRowDataCell) ? currentRowDataCell.value : "";
};

export const formattedPDV: Record<string, string> = {
  NONE: "Nenhum Local",
  ONE: "1 Local",
  TWOTOFIVE: "2 a 5 Locais",
  TWOTOTEN: "2 a 10 Locais",
  SIXTOTEN: "6 a 10 Locais",
  MORETHANTEN: "Acima de 10 Locais",
};

export const formattedCompanyField: Record<string, string> = {
  GYM: "Academia",
  FOOD: "Alimentação",
  AUTOMOBILE: "Automotivo",
  BEAUTY: "Beleza",
  CONSTRUCTION: "Home Center",
  FASHION: "Moda",
  HEALTH: "Saúde",
  SERVICES: "Serviços",
  RETAIL: "Varejo e Atacado",
  OTHER: "Outros",
};

export const formattedPositionInCompany: Record<string, string> = {
  CEO: "Sócio / CEO",
  DIRECTOR: "Diretor",
  MANAGER: "Gerente",
  COORDINATOR: "Coordenador",
  SUPERVISOR: "Supervisor",
  ANALYST: "Analista",
  CLERK: "Atendente",
  OTHER: "Outros cargos",
};
