import styled from "styled-components";

import { ReactComponent as NotFoundImage } from '../../assets/images/404.svg';

export const StyledContentWrapper = styled.main`
  width: 100%;
`;

export const StyledContainer = styled.section`
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const StyledImage = styled(NotFoundImage)`
  width: 45rem;
  height: 30rem;
`;

export const StyledTitle = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  font-weight: bolder;
`;

export const StyledText = styled.p`
  text-align: center;
  font-size: 1rem;
  color: ${props => props.theme.colors.blackLight};
`;
