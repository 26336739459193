import {
  StyledContainer, StyledContentWrapper, StyledImage, StyledText,
  StyledTitle
} from "./404-styles";

import { Helmet } from "react-helmet";
import FormFooter from "../../shared/components/form-footer";

const NotFound = () => {
  return (
    <StyledContentWrapper>
      <Helmet>
        <meta property="og:title" content="Página não encontrada - Check Hublocal" />
        <meta property="og:description" content="Página não encontrada." />
        <title>Página não encontrada</title>
      </Helmet>
      
      <StyledContainer>
        <StyledImage />
        <StyledTitle>Página não encontrada.</StyledTitle>
        <StyledText>
          A página que você tentou acessar está atualmente indisponível..
        </StyledText>
      </StyledContainer>

      <FormFooter />
    </StyledContentWrapper>
  )
}

export default NotFound;