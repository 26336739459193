import {
  applyMiddleware,
  legacy_createStore as createStore,
  Store
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { IAuthenticated } from "./modules/authenticated/types";
import { IEventState } from "./modules/event-check/types"
import combineReducers from "./modules/rootReduce";
import rootSaga from "./modules/rootSaga";
export interface IRootState {
  authenticated: IAuthenticated;
  eventCheck: IEventState;
}

const persistConfig = {
  key: "redux",
  storage,
};

const persistedReducer = persistReducer(persistConfig, combineReducers);

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const store: Store<IRootState> = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };

