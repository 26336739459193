import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

import { IRootState, persistor } from '../../../store-redux'

import { StyledAvatar, StyledProfileDialog } from './avatar-profile-styles';
import ProfileInfo from './profile-info';
import { TAvatarProfileProps } from './types';

const AvatarProfile = ({ handleClickOpen, handleClose, isProfileOpen}: TAvatarProfileProps) => {
  const routerDOMNavigate = useNavigate();
  const { user }  = useSelector( (state: IRootState) => state.authenticated);
  const handleSignOut = () => {
    persistor.purge();
    routerDOMNavigate('/');
  }
  return (
    <>
      <StyledAvatar onClick={handleClickOpen}>{user?.name[0]}</StyledAvatar>
      <StyledProfileDialog
        open={isProfileOpen}
        onClose={handleClose}
      >
        <ProfileInfo 
          signOut={handleSignOut} 
          letraAvatar={user?.name[0] || 'U'}
          user={user?.name}
          email={user?.email}
        />
      </StyledProfileDialog>
      </>
  );
}

export default AvatarProfile;