import { v4 as uuidV4 } from 'uuid';
import IconButton from "../../icon-button";
import { StyledActions, StyledStatus, StyledTableBodyCell, StyledTableBodyRow } from "./table-row-styles";
import { TTableRowData } from "./types";

const TableRow = ({
  data,
  onCopy,
  onDelete,
  setEventToDelete,
  onQrCode,
  onDiagnosis,
  onEdit,
 }: TTableRowData) => {

  function handleOpenDelete(id: string, name: string) {
    onDelete();
    setEventToDelete({ id, name });
  }

  return (
    <StyledTableBodyRow>
      {data.map((columnData, index) => {
        const isGrey = columnData === 'INACTIVE';
        const isOrange = columnData === 'INCOMPLETE';

        let child = <>{ columnData }</>;
        if (index === 0) return null;
        if (index === 1) child = <StyledStatus isgrey={isGrey} isorange={isOrange} />;
        if(index === 5) child = (
          <StyledActions>
            <IconButton iconSize={1.5} onClick={onDiagnosis} iconType="QueryStats" title="Diagnóstico"/>
            <IconButton iconSize={1.5} onClick={() => onEdit(data[0])} iconType="Edit" title="Editar"/>
            <IconButton iconSize={1.5} onClick={onCopy} iconType="ContentCopy" title="Duplicar"/>
            <IconButton iconSize={1.5} onClick={onQrCode} iconType="QrCode2" title="Qrcode"/>
            <IconButton iconSize={1.5} onClick={() => handleOpenDelete(data[0], data[2])} iconType="Delete" title="Remover"/>
          </StyledActions>
        );

        return <StyledTableBodyCell key={uuidV4()}>{child}</StyledTableBodyCell>
      })}
    </StyledTableBodyRow>
  );
};
export default TableRow;
