import { SvgIcon } from '@mui/material';
import React from 'react';

const SuccessIcon: React.FC = () => {
    return (
        <SvgIcon viewBox="0 0 28 28" >
            <rect width="28" height="28" rx="8" fill="#14CC33" />
            <circle cx="14" cy="14" r="8" fill="white" />
            <path d="M12.663 17C12.4266 17 12.2 16.906 12.0329 16.7389L10.2502 14.9562C10.0878 14.7881 9.998 14.563 10 14.3293C10.0021 14.0956 10.0958 13.872 10.2611 13.7068C10.4263 13.5415 10.6499 13.4478 10.8836 13.4457C11.1173 13.4437 11.3424 13.5335 11.5105 13.6959L12.663 14.8484L16.4895 11.0219C16.6576 10.8596 16.8827 10.7697 17.1164 10.7718C17.3501 10.7738 17.5737 10.8675 17.7389 11.0328C17.9042 11.198 17.9979 11.4216 18 11.6553C18.002 11.889 17.9122 12.1142 17.7498 12.2823L13.2932 16.7389C13.1261 16.906 12.8994 17 12.663 17V17Z" fill="#14CC33" />

        </SvgIcon>
    )
}

export default SuccessIcon;