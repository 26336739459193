import axios from "axios";

type TGetAllEvents = {
  accessToken: string | null;
  pageSize?: number;
  page?: number;
  status?: string;
  search?: string;
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  setTokenLikeExpired?: () => void;
}

export default async function getAllEvents({ 
  accessToken,
  pageSize,
  page,
  status,
  search,
  setTokenLikeExpired,
  setIsFetching,
 }: TGetAllEvents): Promise<any[]> {
  try {
    if(setIsFetching) setIsFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_EVENT_API_URL}/get-all`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          pageSize,
          page,
          status,
          search,
        }
      }
    );
    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return err;
  } finally {
    if (setIsFetching) setIsFetching(false)
  }
}