import { Paper } from "@mui/material";
import styled from "styled-components";
import { breakpoints } from "../../assets/styles/devices";

export const StyledWrapper = styled.div<{ background: string; }>`
  @page :left{
    margin-top: 10mm;
  }

  @media print {
    width: 100%;
    height: 100%;
    top: 0px;
    bottom: 0px;
    background: none;
  }
`;

export const StyledContainer = styled.main<{ background: string; }>`
  background-color: ${props => props.background};
  width: 100%;
  min-height: 100vh;
  padding: 4rem 6rem;

  @media screen and (max-width: ${breakpoints.desktop}) {
    padding: 2rem;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    padding: 0;
  }

  @media print {
    padding: 1rem 2rem;
    min-height: auto;
    background: none;
  }
`;

export const StyledContent = styled(Paper)`
  width: 100%;
  min-height: 100vh;
  padding: 0 1rem;

  @media screen and (max-width: ${breakpoints.tablet}) {
    border-radius: 0px !important;
  }

  @media print {
    min-height: auto;
    box-shadow: none !important;
  }
`;

export const StyledUpperContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2rem;
  padding: 2rem;

  @media screen and (max-width: ${breakpoints.desktop}) {
    padding: 2rem 0rem;
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    column-gap: 0.75rem;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    padding: 2rem 0;
  }

  @media print {
    padding: 1rem;
    display: grid;
    place-items: center;
  }
`;

export const StyledContentWrapper = styled.div`
  flex: 1;
`;

export const StyledBottomContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  @media screen and (max-width: 950px) {
    padding: 2rem 0;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    padding: 2rem 1rem;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    padding: 2rem 0rem;
  }

  @media print {
    padding: 0;
  }
`;

export const StyledText = styled.h2`
  width: 100%;
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    font-size: 1rem;
    font-weight: bold;
  }

  @media print {
    font-size: 1rem;
  }
`;

export const StyledTitle = styled.p`
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;

  @media print {
    font-size: 1.2rem;
  }
`;

export const StyledSubTitle = styled.p`
  margin: 1.5rem 0 2.5rem 0;
  text-align: center;
`;