import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const DuckDuckGoIcon: React.FC<SvgIconProps> = (props) => {
    return <SvgIcon viewBox="0 0 500 500" {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M250 500C388.075 500 500 388.075 500 250C500 111.925 388.075 0 250 0C111.925 0 0 111.925 0 250C0 388.075 111.925 500 250 500Z" fill="#DE5833" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M285.158 436.527C285.158 434.574 285.63 434.121 279.422 421.753C262.922 388.706 246.338 342.131 253.885 312.083C255.262 306.621 238.338 109.961 226.385 103.621C213.083 96.5458 196.734 85.3005 181.781 82.8005C174.187 81.5929 164.234 82.1684 156.46 83.2156C155.073 83.4042 155.017 85.8854 156.338 86.3288C161.451 88.0646 167.658 91.0646 171.309 95.6118C171.998 96.4608 171.064 97.8193 169.97 97.8665C166.517 97.9891 160.272 99.4325 152.026 106.433C151.073 107.244 151.866 108.753 153.092 108.508C170.809 104.999 188.913 106.725 199.573 116.433C200.262 117.055 199.904 118.178 199.007 118.423C106.451 143.583 124.762 224.083 149.413 322.895C170.29 406.621 178.781 436.367 181.8 446.508C182.045 447.358 182.485 448.14 183.084 448.792C183.683 449.443 184.426 449.947 185.253 450.263C217.687 462.433 285.158 462.678 285.158 441.404V436.527Z" fill="#DDDDDD" />
        <path d="M297.846 455.076C286.62 459.472 264.639 461.425 251.95 461.425C233.337 461.425 206.535 458.501 196.771 454.104C190.743 435.538 172.696 378.01 154.865 304.944C154.29 302.548 153.705 300.17 153.12 297.803L153.111 297.746C131.922 211.199 114.62 140.51 209.469 118.303C210.328 118.095 210.752 117.067 210.186 116.378C199.299 103.472 178.913 99.2365 153.129 108.133C152.073 108.501 151.158 107.435 151.809 106.529C156.865 99.5573 166.752 94.1988 171.62 91.8497C172.629 91.3686 172.573 89.8874 171.507 89.5573C166.682 88.0467 161.758 86.8741 156.771 86.0478C155.328 85.812 155.196 83.3308 156.648 83.1327C193.196 78.2271 231.365 89.1893 250.516 113.321C250.686 113.548 250.95 113.699 251.233 113.765C321.365 128.821 326.384 239.68 318.309 244.727C316.705 245.727 311.611 245.152 304.875 244.397C277.582 241.35 223.554 235.303 268.148 318.416C268.592 319.237 268.007 320.321 267.082 320.463C241.941 324.378 274.158 403.161 297.846 455.076Z" fill="white" />
        <path d="M329.22 354.29C323.88 351.809 303.352 366.535 289.729 377.837C286.88 373.809 281.522 370.875 269.409 372.978C258.805 374.828 252.956 377.384 250.343 381.79C233.616 375.45 205.465 365.658 198.663 375.111C191.239 385.441 200.522 434.328 210.399 440.677C215.55 443.988 240.211 428.148 253.079 417.214C255.154 420.139 258.503 421.818 265.371 421.658C275.767 421.422 292.635 418.997 295.248 414.158C295.422 413.827 295.562 413.479 295.663 413.12C308.899 418.063 332.182 423.309 337.39 422.516C350.946 420.478 335.503 357.195 329.22 354.29Z" fill="#3CA82B" />
        <path d="M290.984 379.284C291.551 380.284 291.994 381.341 292.381 382.416C294.267 387.699 297.334 404.473 295.022 408.624C292.692 412.765 277.598 414.765 268.277 414.926C258.956 415.086 256.871 411.68 254.984 406.407C253.475 402.19 252.739 392.256 252.749 386.586C252.371 378.152 255.447 375.19 269.683 372.888C280.211 371.19 285.786 373.171 288.994 376.567C303.947 365.397 328.9 349.652 331.334 352.529C343.466 366.878 344.994 401.048 342.371 414.793C341.513 419.284 301.334 410.341 301.334 405.492C301.334 385.369 296.117 379.85 290.984 379.284ZM202.975 373.001C206.267 367.793 232.947 374.275 247.598 380.784C247.598 380.784 244.579 394.426 249.381 410.492C250.777 415.19 215.683 436.105 211.098 432.51C205.805 428.35 196.06 383.944 202.975 373.001Z" fill="#4CBA3C" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M215.899 267.218C218.05 257.831 228.116 240.142 264.022 240.567C282.182 240.491 304.729 240.557 319.682 238.859C336.704 236.972 353.413 232.907 369.399 226.765C384.946 220.84 390.465 222.161 392.399 225.708C394.522 229.604 392.022 236.34 386.588 242.538C376.211 254.378 357.56 263.557 324.607 266.274C291.654 268.991 269.843 260.161 260.456 274.548C256.399 280.755 259.531 295.378 291.39 299.982C334.437 306.199 369.795 292.501 374.163 300.774C378.531 309.048 353.371 325.878 310.248 326.227C267.135 326.585 240.192 311.133 230.645 303.453C218.522 293.708 213.097 279.501 215.899 267.218Z" fill="#FFCC33" />
        <g opacity="0.8">
            <path d="M270.814 164.558C273.22 160.624 278.55 157.577 287.276 157.577C296.003 157.577 300.107 161.058 302.956 164.935C303.531 165.718 302.654 166.643 301.758 166.256L301.097 165.973C297.908 164.577 293.993 162.86 287.276 162.765C280.097 162.671 275.569 164.463 272.71 166.011C271.758 166.539 270.239 165.492 270.814 164.558ZM172.55 169.596C181.022 166.058 187.682 166.511 192.39 167.633C193.38 167.86 194.069 166.794 193.276 166.162C189.625 163.209 181.446 159.558 170.776 163.52C161.248 167.077 156.767 174.454 156.748 179.294C156.739 180.445 159.088 180.539 159.691 179.577C161.333 176.954 164.069 173.143 172.541 169.596H172.55Z" fill="#14307E" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M294.694 218.435C287.203 218.435 281.128 212.378 281.128 204.916C281.128 197.454 287.203 191.397 294.694 191.397C302.184 191.397 308.26 197.454 308.26 204.916C308.26 212.378 302.184 218.435 294.694 218.435ZM304.25 200.435C304.25 199.503 303.88 198.609 303.221 197.95C302.562 197.291 301.668 196.921 300.736 196.921C299.804 196.921 298.91 197.291 298.251 197.95C297.592 198.609 297.222 199.503 297.222 200.435C297.222 201.367 297.592 202.261 298.251 202.92C298.91 203.579 299.804 203.949 300.736 203.949C301.668 203.949 302.562 203.579 303.221 202.92C303.88 202.261 304.25 201.367 304.25 200.435ZM204.779 214.274C204.779 222.982 197.703 230.038 188.967 230.038C184.781 230.044 180.764 228.387 177.798 225.434C174.832 222.48 173.159 218.47 173.146 214.284C173.146 205.567 180.241 198.51 188.967 198.51C197.684 198.51 204.779 205.567 204.779 214.274ZM200.118 209.048C200.143 208.494 200.056 207.941 199.861 207.422C199.667 206.904 199.369 206.43 198.985 206.029C198.602 205.629 198.141 205.311 197.631 205.094C197.122 204.877 196.573 204.765 196.019 204.767C195.464 204.768 194.916 204.882 194.407 205.101C193.898 205.321 193.439 205.641 193.058 206.043C192.676 206.445 192.381 206.92 192.188 207.44C191.996 207.96 191.911 208.513 191.939 209.067C191.992 210.117 192.447 211.107 193.21 211.831C193.973 212.555 194.986 212.958 196.038 212.955C197.089 212.953 198.1 212.546 198.86 211.818C199.62 211.091 200.07 210.099 200.118 209.048Z" fill="#14307E" />
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M249.998 459.961C365.961 459.961 459.961 365.961 459.961 249.998C459.961 134.036 365.961 40.0361 249.998 40.0361C134.036 40.0361 40.0361 134.036 40.0361 249.998C40.0361 365.961 134.036 459.961 249.998 459.961ZM249.998 479.489C376.744 479.489 479.489 376.744 479.489 249.998C479.489 123.253 376.744 20.5078 249.998 20.5078C123.253 20.5078 20.5078 123.253 20.5078 249.998C20.5078 376.744 123.253 479.489 249.998 479.489Z" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M249.972 456.963C364.293 456.963 456.962 364.293 456.962 249.982C456.962 135.671 364.293 42.9725 249.981 42.9725C135.67 42.9725 42.9719 135.661 42.9719 249.973C42.9719 364.284 135.661 456.963 249.972 456.963ZM479.142 249.982C479.142 376.539 376.538 479.133 249.972 479.133C123.406 479.133 20.8115 376.548 20.8115 249.991C20.8115 123.435 123.396 20.8027 249.972 20.8027C376.547 20.8027 479.151 123.397 479.151 249.973L479.142 249.982Z" fill="white" />
    </SvgIcon>
}

export default DuckDuckGoIcon;