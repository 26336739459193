import axios from "axios";
import { Dispatch, SetStateAction } from "react";

type TCreateFooterFormProps = {
    accessToken: string | null;
    name?: string;
    logo: string;
    footer: string;
    isFetching?: Dispatch<SetStateAction<boolean>>;
}

export const createSponsorFooterForm = async({ 
    accessToken,
    name, 
    logo, 
    footer,
    isFetching,
}: TCreateFooterFormProps) => {
    
    try {
        if (isFetching) isFetching(true);
        const body = {
            name,
            logo,
            footer,
        };
        const { data } = await axios.post(
            `${process.env.REACT_APP_SPONSOR_API_URL}/create`,
            body,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,    
                }
            },
        );
        return data;
    } catch (err) {
        return console.log(err);
    } finally {
        if (isFetching) isFetching(false);
    }
}