import { Alert } from "@mui/material";
// import MaintenancePage from "../../../pages/maintenance/maintenance";
import { StyledMaintenanceContainer } from "./maintenance-container.styles";

const MAINTENANCE = true;

export default function MaintenanceContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  if (!MAINTENANCE) return <>{children}</>;

  // if (MAINTENANCE) return <MaintenancePage />;

  return (
    <StyledMaintenanceContainer>
      <Alert severity="warning">
        Aviso: nossa plataforma estará em manutenção das 00:00 do dia 23/11 até
        as 23:59 do dia 24/11 para melhorias no sistema. Durante esse período,
        os serviços estarão temporariamente indisponíveis. Sugerimos que
        alterações sejam feitas até sexta-feira. Um time de plantão estará
        disponível para dúvidas urgentes.
      </Alert>
      {children}
    </StyledMaintenanceContainer>
  );
}
