type imageResolutionProps = { 
  maxWidth: number;
  maxHeight: number;
  minWidth: number;
  minHeight: number;
}

export const imageResolution: Record<string, imageResolutionProps> = {
  'primary_logo': {
    maxWidth: 2000,
    maxHeight: 700,
    minWidth: 140,
    minHeight: 100,
  },

  'secondary_logo': {
    maxWidth: 2000,
    maxHeight: 700,    
    minWidth: 140,
    minHeight: 100,
  },
}