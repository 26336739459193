import axios from "axios";
import { TCreateFormProps } from "./types";

export const createForm = async ({
  accessToken,
  background_image,
  first_logo,
  js_code,
  left_first_layer,
  left_second_layer,
  mobile_first_layer,
  mobile_second_layer,
  second_logo,
  isFetching,
}: TCreateFormProps) => {
  try {
    const body = {
      background_image,
      first_logo,
      js_code,
      left_first_layer,
      left_second_layer,
      mobile_first_layer,
      mobile_second_layer,
      second_logo,
      email: '',
    };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }

    const { data } = await axios.post(
      `${process.env.REACT_APP_FORM_API_URL}/create`,
      body,
      { headers },
    );
    return data;
  } catch (error) {
    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
