import {
  StyledContainer, StyledContentWrapper, StyledImage,
  StyledLogo,
  StyledSection,
  StyledText,
  StyledTitle
} from "./event-not-found-styles";

import { Helmet } from "react-helmet";
import logo from '../../assets/images/hublocal-logo.png';
import FormFooter from "../../shared/components/form-footer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const [second, setSecond] = useState(10);
  const [activeCycle, setActiveCycle] = useState(false);
    
  useEffect(() => {
    setActiveCycle(true);

    if(activeCycle) {
      setTimeout(() => {
        setSecond(state => state -1)
      }, 1000)
    }

    if(second < 1 ) {    
      setActiveCycle(false)
      navigate('/')
    } 

  },[activeCycle, navigate, second])

  return (
    <StyledContentWrapper>
      <Helmet>
        <meta property="og:title" content="Evento não encontrado - Check Hublocal" />
        <meta property="og:description" content="Evento não encontrado." />
        <title>Evento não encontrado</title>
      </Helmet>
      <StyledContainer>
        <StyledLogo isOnTop src={logo} alt="Hublocal" />
        <StyledImage />
        <StyledSection>
          <StyledLogo src={logo} alt="Hublocal" />
          <StyledTitle>
            Ops, parece que a página que você está tentando acessar encontra-se fora do ar.
          </StyledTitle>
          <StyledText>
          Este evento foi encerrado. Caso esteja tentando utilizar o nosso 
          serviço de Verificação de Presença Online, 
          <a href="/"> clique aqui </a> ou <span> aguarde {second}s</span> para ser redirecionado.
          </StyledText>
        </StyledSection>
      </StyledContainer>

      <FormFooter />
    </StyledContentWrapper>
  )
}

export default NotFound;