import { Paper, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import styled from "styled-components";

export const StyledFormFilter = styled(Paper)`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 2rem;
  margin-top: 1rem;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
`;

export const StyledInputBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 50%;

  label {
    margin-bottom: 0.5rem;
  }
`;

export const StyledInput = styled(TextField)`
  fieldset {
    border-radius: 0;
    border: 0.15rem solid gray;
  }
  & label.Mui-focused {
    color: gray;
  }
  & .MuiInput-underline:after {
    border-bottom-color: gray;
  }
  & .MuiOutlinedInput-root {
    height: 2.5rem;
    &:hover fieldset {
      border: 0.15rem solid gray;
    }
    &.Mui-focused fieldset {
      border: 0.15rem solid gray;
    }
  }
`;

export const StyledSelect = styled(Select)`
  fieldset {
    border-radius: 0;
    border: 0.15rem solid gray;
  }
  height: 2.5rem;
`;
