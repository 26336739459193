import { Reducer } from "redux";
import { IAuthenticated } from "./types";

const INITIAL_STATE: IAuthenticated = {
  isAuthenticated: false,
  saasUserToken: null,
  user: null,
}

const authenticated: Reducer<IAuthenticated> = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case 'ADD_USER_AUTHENTICATED': {
        return  action.payload
    }
    case 'AUTHENTICATION_EXPIRED': {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}

export default authenticated;