import styled from "styled-components";
import MUIDataTable from "mui-datatables";
import { lighten } from "polished";

export const StyledTable = styled(MUIDataTable)`
  .MuiTypography-root {
    font-weight: bold;
    font-family: "Poppins";
    color: ${(props) => lighten(0.2, props.theme.colors.black)};
  }

  .MuiTableCell-head {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    font-weight: bold;

    &:first-child {
      border-top-left-radius: 5px;
      text-align: center;
    }

    &:last-child {
      border-top-right-radius: 5px;
    }
  }

  box-shadow: 0;

  & .MuiTableCell-body {
    color: ${(props) => lighten(0.3, props.theme.colors.black)};
    border: none;
  }
`;
