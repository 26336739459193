import { Dialog } from "@mui/material";
import { darken } from "polished";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
  padding: 1rem 2rem;

  & .MuiPaper-root {
    width: 80%;
    max-width: 550px;
    border-radius: 12px;
  }
`;

export const StyledAlertDialogHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 2rem;
  font-weight: 600;
  background: ${(props) => props.theme.page.body};
`;

export const StyledDialogContent = styled.div`
  text-align: center;
  font-size: 0.8rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledText = styled.p`
  font-family: 'Poppins';
  font-weight: bold;
  margin-bottom: 2rem;
`;

export const StyledButton = styled.button`
  padding: 0.5rem 1.5rem;
  background-color: ${props => props.theme.colors.primary};
  border: none;
  border-radius: 87px;
  color: ${props => props.theme.colors.white};
  transition: background 0.25s;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  cursor: pointer;

  &:hover {
    background-color: ${props => darken(0.1, props.theme.colors.primary)};
  }

  &:active {
    background-color: ${props => darken(0.15, props.theme.colors.primary)};
  }
`;

export const StyledCounterText = styled.p`
  font-size: 0.65rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 0.5rem;
`;