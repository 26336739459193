import styled, { css } from 'styled-components';
import { rgba } from 'polished';

export type TOverlayLoadingBaseProps = {
  textToLoading?: string;
  fullScreen?: boolean;
};

export const StyledOverlayLoading = styled.div<Pick<TOverlayLoadingBaseProps, 'fullScreen'>>`
    z-index: 1200;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: ${props => rgba(props.theme.colors.black, 0.80)};
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.fullScreen && css`
      position: fixed;
    `};

    .text-disclaimer {
        font-size: 1.4rem;
        color: ${props => props.theme.colors.white};
        margin-left: 0.5rem;
    }
`;
