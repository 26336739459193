import React, { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { StyledContainer, StyledPage, StyledResult, StyledStack } from './swiper-styles';



export type CarouselType = {
    stack: string;
    description: string;
    img: any;
    percentage: string;
}

const Carousel: React.FC<{ data: CarouselType[] }> = ({ data = [] }) => {
    const moveGoogleToMiddle = (array: any) => {
        const arrayValue = array;
        const indexGoogle = arrayValue.findIndex((value: any) => value.stack === 'google');

        const google = arrayValue.splice(indexGoogle, 1)[0];

        const middle = Math.floor(arrayValue.length / 2);
        arrayValue.splice(middle, 0, google);
        return arrayValue
    }

    const sorted: CarouselType[] = useMemo(() => data.length ? moveGoogleToMiddle(data) : [], [data])
    return (
        <StyledPage maxWidth={["200px", '1000px']} mx="auto" gap={2}>
            {sorted.map(({ stack, description, img: Img, percentage }, index) => (
                <StyledStack key={index} p={4} direction="column" pt={8} maxWidth="240px">
                    <StyledContainer>
                        <Img sx={{ fontSize: '140px' }} />
                    </StyledContainer>
                    <Typography fontSize="10px" fontWeight={700} color="#48F9AE">Pack</Typography>
                    <Typography mb={2} fontWeight={600} color="#374957" textTransform="capitalize">{stack}</Typography>
                    <Typography textAlign="center" fontWeight={400} fontSize="16px" color="#374957">{description}</Typography>
                    <StyledResult>
                        <Typography fontSize="20px" color="white" fontWeight={900}>{percentage}</Typography>
                    </StyledResult>
                </StyledStack>
            ))}
        </StyledPage>
    );
};

export default Carousel;
