/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';
import { TLoadingBaseProps } from './types';

type StyledLoadingProps = Pick<TLoadingBaseProps, 'className' | 'large'>;
type StyledSpinnerProps = Pick<TLoadingBaseProps, 'primary' | 'large' | 'className'>;

export const StyledLoading = styled.div<StyledLoadingProps>`
  position: relative;
  width: 2rem;
  height: 2rem;

  ${props => props.large && css`
    width: 35px;
    height: 35px;
  `}
`;

export const StyledSpinner = styled.span<StyledSpinnerProps>`
    position: absolute;
    top: 37%;
    left: 44.5%;
    width: 10%;
    height: 25%;
    background-color: #8e8e93;
    border-radius: 50%/20%;
    -webkit-animation: iSpinnerBlade 1s linear infinite;
    animation: iSpinnerBlade 1s linear infinite;
    will-change: opacity;

    ${props => props.primary && css`
      background-color: ${props => props.theme.colors.primary};
    `}

  &.spinner-1 {
    transform: rotate(30deg) translate(0, -150%);
    animation-delay: -1.6666666667s;
  }

  &.spinner-2 {
    transform: rotate(60deg) translate(0, -150%);
    animation-delay: -1.5833333333s;
  }

  &.spinner-3 {
    transform: rotate(90deg) translate(0, -150%);
    animation-delay: -1.5s;
  }

  &.spinner-4 {
    transform: rotate(120deg) translate(0, -150%);
    animation-delay: -1.4166666667s;
  }

  &.spinner-5 {
    transform: rotate(150deg) translate(0, -150%);
    animation-delay: -1.3333333333s;
  }

  &.spinner-6 {
    transform: rotate(180deg) translate(0, -150%);
    animation-delay: -1.25s;
  }

  &.spinner-7 {
    transform: rotate(210deg) translate(0, -150%);
    animation-delay: -1.1666666667s;
  }

  &.spinner-8 {
    transform: rotate(240deg) translate(0, -150%);
    animation-delay: -1.0833333333s;
  }

  &.spinner-9 {
    transform: rotate(270deg) translate(0, -150%);
    animation-delay: -1s;
  }

  &.spinner-10 {
     transform: rotate(300deg) translate(0, -150%);
     animation-delay: -0.9166666667s;
  }

  &.spinner-11 {
     transform: rotate(330deg) translate(0, -150%);
     animation-delay: -0.8333333333s;
  }

  &.spinner-12 {
    transform: rotate(360deg) translate(0, -150%);
    animation-delay: -0.75s;
  }

  ${props => props.large && css`
    width: 8.5714285714%;
    height: 25.7142857143%;
    border-radius: 50%/16.67%;
  `};

  @keyframes iSpinnerBlade {
    0% {
      opacity: 0.85;
    }

    50% {
      opacity: 0.25;
    }
    
    100% {
      opacity: 0.25;
    }
  }
`;
