import { useSnackbar } from "notistack";
import { isNil } from "ramda";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import duplicateEvent from "../../services/events/duplicate-event";
import { IRootState } from "../../store-redux";
import IconButton from "../icon-button";
import SlugInput from "../slug-input";
import {
  StyledButton,
  StyledButtonsContainer,
  StyledButtonsWrapper,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledDialogTitleWrapper,
  StyledInput,
  StyledLabel,
} from "./duplicate-event-dialog-styles";
import { TDuplicateEventDialogProps } from "./types";

const DuplicateEventDialog = ({
  eventName,
  isOpen,
  onClose,
  currentEvent,
  getAllEventData,
}: TDuplicateEventDialogProps) => {
  const [duplicateName, setDuplicateName] = useState('');
  const { saasUserToken } = useSelector((state: IRootState) => state.authenticated);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [slug, setSlug] = useState('');
  const [isValidSlug, setIsValidSlug] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  async function handleDuplicateEvent() {
    const errorResponse = await duplicateEvent({
      id: currentEvent.id,
      accessToken: saasUserToken,
      setIsFetching: setIsDuplicating,
      name: duplicateName,
      slug,
    });

    if (isNil(errorResponse)) {
      enqueueSnackbar('Evento duplicado com sucesso!.', {
        variant: `success`,
        autoHideDuration: 1500 
      });
      getAllEventData();
      return onClose();
    }

    if (errorResponse.response.data.statusCode === 404)
      return enqueueSnackbar('Evento não encontrado.', {
        variant: `error`,
        autoHideDuration: 1500 
      });

    if (errorResponse.response.data.statusCode === 400)
      return enqueueSnackbar('Formato de ID inválido.', {
        variant: `error`,
        autoHideDuration: 1500 
      });

    return enqueueSnackbar('Erro interno do servidor.', {
      variant: `error`,
      autoHideDuration: 1500 
    });
  }

  useEffect(() => {
    setDuplicateName(`Cópia - ${eventName}`);
  }, [eventName])

  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
    >
      <StyledDialogTitleWrapper>
        <StyledDialogTitle>Duplicar Evento</StyledDialogTitle>
        <IconButton
        onClick={onClose}
        isWhite
        iconSize={1.5}
      />
      </StyledDialogTitleWrapper>
      <StyledDialogContent>
        <StyledLabel>Você está prestes a duplicar o evento:</StyledLabel>
        <StyledInput
          value={duplicateName}
          onChange={({ target }) => setDuplicateName(target.value)}
        />
        <SlugInput
          setSlug={setSlug}
          isValidSlug={isValidSlug}
          setIsValidSlug={setIsValidSlug}
        />
        <StyledButtonsWrapper>
          <StyledLabel>Você está prestes a duplicar o evento:</StyledLabel>
          <StyledButtonsContainer>
            <StyledButton onClick={onClose} isgrey>Cancelar</StyledButton>
            <StyledButton
              onClick={handleDuplicateEvent}
              disabled={isDuplicating || !isValidSlug}
              disabledStyle={isDuplicating || !isValidSlug}
            >
              Duplicar Evento
            </StyledButton>
          </StyledButtonsContainer>
        </StyledButtonsWrapper>
      </StyledDialogContent>
    </StyledDialog>
  )
}

export default DuplicateEventDialog;