import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const GoogleArIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox="0 0 475 550" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M243.044 168.757C203.658 168.757 169.459 190.202 150.957 221.878L223.421 263.647C235.616 270.655 250.473 270.655 262.667 263.647L334.991 221.878C316.77 190.061 282.43 168.757 243.044 168.757Z" fill="#E1BEE7" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M223.419 263.647L150.955 221.878C141.844 237.577 136.658 255.658 136.658 275C136.658 333.728 184.173 381.244 242.902 381.244C301.63 381.244 349.146 333.728 349.146 275C349.146 255.658 343.959 237.436 334.849 221.878L262.525 263.647C250.611 270.655 235.613 270.655 223.419 263.647Z" fill="#FAFAFA" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M467.723 404.791L335.128 328.122C316.767 359.939 282.427 381.244 243.041 381.244V549.86C249.348 549.86 255.516 548.178 261.122 544.954L467.863 425.535C467.863 425.535 468.003 425.535 468.003 425.395C475.992 420.91 475.712 409.276 467.723 404.791Z" fill="#7B1FA2" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M243.042 381.244C203.656 381.244 169.316 359.939 150.954 328.122L4.9043 412.5C7.98789 417.966 12.6133 422.452 18.0796 425.675L224.82 545.094C230.427 548.318 236.594 550 242.901 550V381.244H243.042Z" fill="#4A148C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M243.044 168.756V0.140054C236.737 0.140054 230.569 1.82201 224.963 5.04576L18.2222 124.465C12.6157 127.688 8.13046 132.314 5.04688 137.64L151.097 222.018C169.458 190.061 203.798 168.756 243.044 168.756Z" fill="#7B1FA2" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M468.003 124.465C468.003 124.465 467.863 124.465 467.863 124.325L261.122 4.90571C255.516 1.68196 249.348 0 243.041 0V168.616C282.427 168.616 316.767 189.921 335.128 221.738L467.723 145.209C475.712 140.724 475.992 129.09 468.003 124.465Z" fill="#4A148C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M136.799 275C136.799 255.658 141.985 237.436 150.956 221.878L4.90571 137.5C1.82212 142.966 0 149.134 0 155.581V394.279C0 400.726 1.68196 406.894 4.90571 412.36L150.956 327.982C141.985 312.564 136.799 294.343 136.799 275Z" fill="#BA68C8" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M335.128 328.122C316.766 359.939 282.426 381.244 243.181 381.244C227.482 381.244 212.485 377.74 199.029 371.713L380.26 476.414L468.002 425.815C476.132 421.19 476.132 409.416 468.002 404.791L335.128 328.122Z" fill="url(#paint0_linear_1289_25396)" />
            <defs>
                <linearGradient id="paint0_linear_1289_25396" x1="75193.2" y1="57711.6" x2="56898.1" y2="-15000.9" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1A237E" stop-opacity="0.01" />
                    <stop offset="1" stop-color="#1A237E" stop-opacity="0.3" />
                </linearGradient>
            </defs>
        </SvgIcon>
    )
}

export default GoogleArIcon;