import { Select, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import styled from 'styled-components';
import { devices } from '../../../assets/styles/devices';

export const StyledFilter = styled.div`
  display: grid;
  border: 1px solid ${props => props.theme.colors.grey};
  background-color: ${props => props.theme.colors.white};

  @media ${devices.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(5, 1fr);
  }

  @media ${devices.fullhd} {
    grid-template-columns: repeat(10, 1fr);
  }
`;

export const StyledGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.625rem;
`;

export const StyledLabel = styled.p`
  font-size: 1rem;
`;

export const StyledInput = styled(TextField)`
  & .MuiOutlinedInput-input {
    padding: 0.4rem; 
  }
`;

export const StyledSelect = styled(Select)`
  & .MuiOutlinedInput-input {
    padding: 0.4rem; 
  }
`;

export const StyledDate = styled(DatePicker)``;
