import { Box, Stack } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 237px;
  height: 237px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 2px 4px 0px #00000029 inset, 0px -2px 4px 0px #00000029 inset;
`;

export const InnerCircle = styled.div`
  width: 160px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #0080FF;
`;

export const PercentageContainer = styled(Stack)`
@media only print {
  flex-direction: row !important;
}
`;