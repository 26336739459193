import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./assets/styles/global";
import { light } from "./assets/styles/themes";
import './assets/styles/styles.css'
import AppRoutes from "./shared/routes";
import { store, persistor } from "./shared/store-redux";
import { PersistGate } from "redux-persist/integration/react";
import { IntlProvider } from "react-intl";
import 'react-datepicker/dist/react-datepicker.css';
import MaintenanceContainer from "./shared/containers/maintenance-container/maintenance-container";

function App() {
  return (
    <IntlProvider locale="pt">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={light}>
            <GlobalStyle />
            <BrowserRouter>
              <SnackbarProvider
                maxSnack={3}
                preventDuplicate
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MaintenanceContainer>
                  <AppRoutes />
                </MaintenanceContainer>
              </SnackbarProvider>
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </Provider >
    </IntlProvider >
  );
}

export default App;
