import { useCallback, useState } from "react";
import {
  StyledContainer,
  StyledHeader,
  StyledTitle,
  StyledFooter,
  StyledContent,
  StyledGroup,
  StyledButton,
  ButtonJS,
  StyledSwitchOption,
  StyledContentOption,
  StyledGroup2,
} from "./diagnosis-edit-form-styled";

import JavascriptIcon from "../../../../assets/images/javascript.svg";
import HTMLIcon from "../../../../assets/images/html.svg";
import { FormControlLabel, Switch } from "@mui/material";
import { TDiagnosisEditFormEntity } from "../../../../pages/create-event/types";
import FormInput from "../form-input";
import CodeDialog from "../../code-dialog";

interface DiagnosisEditFormProps {
  diagnosisFormData: TDiagnosisEditFormEntity;
  setDiagnosisFormData: any;
}

const DiagnosisEditForm = ({
  diagnosisFormData,
  setDiagnosisFormData,
}: DiagnosisEditFormProps) => {
  const [isModal, setIsModal] = useState<{
    isOpen: boolean;
    type: "html" | "js";
  }>({ isOpen: false, type: "js" });
  const { has_additional_button: isButtonOption } = diagnosisFormData;

  const handleChangeDiagnosis = useCallback(
    (name: string, value: string) => {
      setDiagnosisFormData((state: TDiagnosisEditFormEntity) => ({
        ...state,
        [name]: value,
      }));
    },
    [setDiagnosisFormData]
  );

  const handleChangeJSCode = useCallback(
    (js_code: string) => {
      setDiagnosisFormData((prevState: TDiagnosisEditFormEntity) => ({
        ...prevState,
        js_code,
      }));
    },
    [setDiagnosisFormData]
  );

  const handleChangeHTMLCode = useCallback(
    (html_code: string) => {
      setDiagnosisFormData((prevState: TDiagnosisEditFormEntity) => ({
        ...prevState,
        html_code,
      }));
    },
    [setDiagnosisFormData]
  );

  const handleButtonOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiagnosisFormData((state: TDiagnosisEditFormEntity) => ({
      ...state,
      has_additional_button: event.target.checked,
    }));
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledTitle>3. Segunda página: Diagnóstico</StyledTitle>
      </StyledHeader>

      <StyledContent>
        <FormInput
          name="title"
          onChange={handleChangeDiagnosis}
          text="Título:"
          defaultValue="Seu relatório está pronto!"
          value={diagnosisFormData}
          multiline
          rows={2}
        />
        <FormInput
          name="description"
          onChange={handleChangeDiagnosis}
          text="Descrição:"
          defaultValue="Confira nos gráficos abaixo como está atualmente a qualidade da sua presença digital"
          value={diagnosisFormData}
          multiline
          rows={2}
        />
      </StyledContent>

      <StyledFooter>
        <StyledGroup>
          <FormInput
            name="background_color"
            onChange={handleChangeDiagnosis}
            text="Cor de fundo*:"
            type="color"
            value={diagnosisFormData || "#000000"}
            isInputProps
          />
          <FormInput
            name="text_color"
            onChange={handleChangeDiagnosis}
            text="Cor do texto*:"
            type="color"
            value={diagnosisFormData || "#000000"}
            isInputProps
          />
        </StyledGroup>
      </StyledFooter>
      <StyledFooter>
        <StyledGroup2>
          <StyledButton>
            <ButtonJS
              type="button"
              onClick={() => setIsModal({ isOpen: true, type: "html" })}
            >
              <img src={HTMLIcon} alt="logotipo html" />
              ADICIONAR CÓDIGO HTML
            </ButtonJS>
            <ButtonJS
              type="button"
              onClick={() => setIsModal({ isOpen: true, type: "js" })}
            >
              <img src={JavascriptIcon} alt="logotipo javascript " />
              ADICIONAR CÓDIGO JAVASCRIPT
            </ButtonJS>
          </StyledButton>
        </StyledGroup2>
      </StyledFooter>

      <StyledSwitchOption>
        <p>Botão adicional</p>
        <FormControlLabel
          control={
            <Switch checked={isButtonOption} onChange={handleButtonOption} />
          }
          label={isButtonOption ? "Desabilitar" : "Habilitar"}
        />
      </StyledSwitchOption>

      <StyledContentOption>
        {isButtonOption && (
          <>
            <FormInput
              name="button_text"
              onChange={handleChangeDiagnosis}
              text="Texto do botão*:"
              type="text"
              value={diagnosisFormData}
              required={isButtonOption}
            />

            <FormInput
              name="redirect_link"
              onChange={handleChangeDiagnosis}
              text="Link para redirecionamento*:"
              type="text"
              value={diagnosisFormData}
              required={isButtonOption}
            />

            <FormInput
              name="button_background_color"
              onChange={handleChangeDiagnosis}
              text="Cor botão*:"
              type="color"
              value={diagnosisFormData}
              isInputProps
              required={isButtonOption}
            />

            <FormInput
              name="button_text_color"
              onChange={handleChangeDiagnosis}
              text="Cor do texto*:"
              type="color"
              value={diagnosisFormData}
              isInputProps
              required={isButtonOption}
            />
          </>
        )}
      </StyledContentOption>
      {isModal.isOpen && (
        <CodeDialog
          isOpen={isModal.isOpen}
          onClose={() => setIsModal({ isOpen: false, type: "js" })}
          handleCode={
            isModal.type === "js" ? handleChangeJSCode : handleChangeHTMLCode
          }
          code={
            isModal.type === "js"
              ? diagnosisFormData.js_code
              : diagnosisFormData.html_code
          }
          type={isModal.type}
        />
      )}
    </StyledContainer>
  );
};

export default DiagnosisEditForm;
