import styled from 'styled-components';

export const StyledHeader = styled.header`
  background-color: ${props => props.theme.colors.white};
  height: 95px;
`;

export const StyledConteiner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLogo = styled.div`
  padding: 1rem 3rem;
  display: flex;
  text-align: center;
  align-items: center;
`;

export const StyledTitle = styled.div`
  padding: 1rem 3rem;
  font-size: 1.5rem;
  font-weight: 600;
  
`;