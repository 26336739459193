import { Breadcrumbs as MuiBreadcrumb, Link, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

interface BreadcrumbItem {
    label: string;
    href?: string;
    current?: boolean;
}

function ArrowSeparator() {
    return (
        <Typography variant="body1" color="text.secondary" aria-hidden="true">
            {'>'}
        </Typography>
    );
}

function ArrowSeparatorLink(props: any) {
    return <Link underline="none" {...props} component={ArrowSeparator} />;
}

interface ArrowSeparatorBreadcrumbsProps {
    items: BreadcrumbItem[];
}

export default function Breadcrumbs(props: ArrowSeparatorBreadcrumbsProps) {
    const { items } = props;
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <MuiBreadcrumb separator={<ArrowSeparatorLink />} >
            {items.map((item, index) => (
                <Link
                    key={item.href || index}
                    color={location.pathname === item.href || item.current ? 'blue' : 'inherit'}
                    onClick={() => navigate(item.href || '#')}
                    underline='hover'
                    sx={{ cursor: 'pointer' }}
                >
                    {item.label}
                </Link>
            ))}
        </MuiBreadcrumb>
    );
}
