import { Box, Stack } from "@mui/material"
import styled from "styled-components"
import { breakpoints } from "../../../assets/styles/devices"

export const StyledStack = styled(Stack)`
background: #FFFFFF;
box-shadow: -8px 0px 16px rgba(0, 0, 0, 0.16), 8px 0px 16px rgba(0, 0, 0, 0.16);
border-radius: 40px;
align-items: center;
overflow: visible;
position: relative;
min-width: 200px;

@media screen and (max-width: ${breakpoints.tabletLandscape}) {
    margin-top: 100px;
}

@media only print {
    box-shadow: none;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}
`

export const StyledPage = styled(Box)`
display: flex;
justify-content: center;
@media screen and (max-width: ${breakpoints.tabletLandscape}) {
    flex-direction: column;
  }
@media only print {
    flex-direction: row;
}
`


export const StyledContainer = styled.div`
position: absolute;
top: -70px;
`

export const StyledResult = styled.div`
    position: absolute;
    width: 80px;
    height: 80px;
    background: linear-gradient(180deg, #0080FF 0%, #0039A8 100%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border-radius: 50%;
    bottom: -40px;
    border: 6px solid white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`