import { Select, TextField } from "@mui/material";
import styled from "styled-components";
import { breakpoints } from "../../../assets/styles/devices";
import ReactInputMask from "react-input-mask";
import { darken } from "polished";

export const StyledInput = styled(TextField)<{
  issmall?: number;
  isbig?: number;
}>`
  width: ${(props) => (props.issmall ? "20%" : props.isbig ? "60%" : "40%")};

  &
    .css-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:not(
      .Mui-disabled
    ):before {
    border-bottom: 2px solid ${(props) => props.theme.colors.white};
  }

  & .MuiInput-underline::before {
    border-bottom: 1px solid ${(props) => props.theme.colors.white};
  }

  & .MuiInput-input {
    color: ${(props) => props.theme.colors.white};
    font-size: 1rem;
  }

  & .MuiInput-input.Mui-disabled {
    color: ${(props) => props.theme.colors.white};
    -webkit-text-fill-color: ${(props) => props.theme.colors.white};
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: 70%;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    width: 90%;
  }
`;

export const StyledSelect = styled(Select)`
  width: 40%;

  & .MuiSvgIcon-root {
    color: white;
  }

  &::before {
    border-bottom: 2px solid ${(props) => props.theme.colors.white} !important;
  }

  & .MuiInput-underline::before {
    border-bottom: 1px solid ${(props) => props.theme.colors.white};
  }

  & .MuiSelect-select {
    color: ${(props) => props.theme.colors.white};
    font-size: 1rem;
  }

  & .MuiSelect-select.Mui-disabled {
    color: ${(props) => props.theme.colors.white};
    -webkit-text-fill-color: ${(props) => props.theme.colors.white};
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: 70%;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    width: 90%;
  }
`;

export const StyledInputWrapper = styled.div`
  position: relative;
  width: 40%;
  height: fit-content;

  &::after {
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.primary};
    bottom: 0;
    transform: scaleX(0);
    transition: transform 0.2s;
  }

  &:focus-within::after {
    transform: scaleX(100%);
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: 70%;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    width: 90%;
  }
`;

export const StyledInputMask = styled(ReactInputMask)`
  width: 100%;
  background: none;
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};

  &::placeholder {
    color: ${(props) => darken(0.4, props.theme.colors.white)};
  }
`;

export const StyledLabel = styled.label<{ textColor: string }>`
  color: ${(props) => props.textColor};
  margin-bottom: 1rem;
  width: 40%;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: 70%;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    width: 90%;
    font-size: 0.9rem;
  }
`;

export const StyledSelectPlaceholder = styled.span`
  opacity: 0.5;
  font-family: Poppins, sans-serif;
`;
