import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const NineNineIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox="0 0 500 500" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M500 397.462C500 453.859 453.859 500 397.461 500H102.539C46.1408 500 0 453.859 0 397.462V102.539C0 46.1408 46.1408 0 102.539 0H397.461C453.859 0 500 46.1408 500 102.539V397.462Z" fill="url(#paint0_linear_1278_24983)" />
            <path d="M227.723 166.989C237.109 179.618 242.852 195.114 243.513 211.912V214.809C243.513 231.176 238.663 246.441 230.243 259.123L163.168 361.462L161.363 364.223C160.628 365.336 159.389 366.008 158.056 366.008H103.411C100.776 366.008 99.2011 363.09 100.639 360.885L145.132 293.002C109.521 284.792 82.96 252.908 82.96 214.799L83.0335 211.901C83.6214 195.146 89.343 179.692 98.6867 167.073C113.311 147.315 136.775 134.506 163.242 134.506C189.656 134.527 213.089 147.294 227.723 166.989ZM186.716 229.549L188.953 226.127C191.357 221.77 192.616 216.877 192.595 211.901C192.564 195.702 179.43 182.589 163.231 182.589C147.032 182.589 133.899 195.712 133.867 211.954C133.867 228.174 147.011 241.328 163.231 241.328C172.848 241.339 181.352 236.698 186.716 229.549ZM400.948 166.989C410.334 179.618 416.076 195.114 416.748 211.912V214.809C416.748 231.176 411.898 246.441 403.478 259.123L336.404 361.462L334.598 364.223C333.863 365.336 332.624 366.008 331.291 366.008H276.646C274.011 366.008 272.436 363.09 273.875 360.885L318.367 293.002C282.756 284.792 256.195 252.908 256.195 214.799L256.269 211.901C256.867 195.146 262.578 179.692 271.922 167.073C286.546 147.315 310.01 134.506 336.477 134.506C362.891 134.527 386.324 147.294 400.948 166.989ZM359.941 229.549L362.177 226.127C364.581 221.77 365.841 216.877 365.82 211.901C365.789 195.702 352.655 182.589 336.456 182.589C320.257 182.589 307.113 195.712 307.081 211.954C307.081 228.174 320.236 241.328 336.456 241.328C346.083 241.339 354.587 236.698 359.941 229.549Z" fill="black" />
            <defs>
                <linearGradient id="paint0_linear_1278_24983" x1="149.493" y1="138.495" x2="458.242" y2="462.494" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFE700" />
                    <stop offset="1" stop-color="#FF9A06" />
                </linearGradient>
            </defs>
        </SvgIcon>
    )
}

export default NineNineIcon;