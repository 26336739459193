import { Dialog } from "@mui/material";
import styled from "styled-components";

export const StyledModalLeadContainer = styled(Dialog)``;

export const StyledModalLeadHeader = styled.header`
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  padding: 2.5rem;
`;

export const StyledModalContent = styled.div`
  display: flex;
`;

export const StyledLeftContent = styled.div`
  width: 35%;
  padding: 2rem 0 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyleRightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  width: 65%;
`;

export const StyledLeftTitle = styled.span`
  font-size: 1.5rem;
`;

export const StyledStats = styled.div`
  display: flex;
  justify-content: space-between;
`;
