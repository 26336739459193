import { isNil } from "ramda";
import { StyledButtonContainer } from "./button-styles";

type TProps = {
  outlined?: boolean;
  text: string;
  action: string | undefined;
};

const Button = ({ outlined, text, action }: TProps) => {
  return (
    <StyledButtonContainer
      outlined={outlined}
      href={isNil(action) ? "" : action}
      target="_blank"
    >
      {text}
    </StyledButtonContainer>
  );
};

export default Button;
