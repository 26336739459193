import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import AppleMapsIcon from '../icons/apple-maps';
import UberIcon from '../icons/uber';
import InDriveIcon from '../icons/indrive';
import BingIcon from '../icons/bing';

import Icon from '../Icon/Icon';
import EllipseIcon from '../icons/elipse';
import TomtomIcon from '../icons/tomtom';
import AmazonIcon from '../icons/amazon';
import WazeIcon from '../icons/waze';
import NineNineIcon from '../icons/99';
import GoogleArIcon from '../icons/google-ar';
import GoogleStreetIcon from '../icons/google-street';
import TrivagoIcon from '../icons/trivago';
import CabifyIcon from '../icons/cabify';
import GoogleErIcon from '../icons/google-er';
import YahooIcon from '../icons/yahoo';
import AlexaIcon from '../icons/alexa';
import DuckDuckGoIcon from '../icons/duckduckgo';
import TwitterIcon from '../icons/twitter';
import { PlatformTypes, ResultsType, getRelevanceColor, getResultLabel } from './locale-card';
import GoogleIcon from '../icons/google-location';
import WhatsappIcon from '../icons/whatsapp';


const GoogleApps = [
    {
        name: 'Google',
        icon: GoogleIcon,
    },
    {
        icon: WazeIcon,
        name: 'Waze',
    },
    {
        icon: NineNineIcon,
        name: '99',
    },
    {
        icon: UberIcon,
        name: 'Uber',
    },
    {
        icon: GoogleArIcon,
        name: 'Google Ar',
    },
    {
        icon: GoogleStreetIcon,
        name: 'Google Street',
    },
    {
        icon: GoogleErIcon,
        name: 'Google Earth',
    },
    {
        icon: TrivagoIcon,
        name: 'Trivago',
    },
    {
        icon: CabifyIcon,
        name: 'Cabify',
    },
    {
        icon: InDriveIcon,
        name: 'In Drive',
    },
]
const YelpApps = [
    {
        name: 'TomTom',
        icon: TomtomIcon
    },
    {
        name: 'Yahoo',
        icon: YahooIcon,
    }
]

const FoursquareApps = [
    {
        name: 'Apple Maps',
        icon: AppleMapsIcon,
    },
    {
        name: 'WhatsApp Locations',
        icon: WhatsappIcon,
    },
    {
        name: 'Alexa',
        icon: AlexaIcon,
    },
    {
        name: 'Duck Duck Go',
        icon: DuckDuckGoIcon,
    },
    {
        name: 'Twitter',
        icon: TwitterIcon,
    },
    {
        name: 'Bing',
        icon: BingIcon
    },
];
export type StacksProps = {
    name: string;
    platform: PlatformTypes;
    values: ResultsType
}

export const AppsByPlatform = {
    Google: GoogleApps,
    Yelp: YelpApps,
    Foursquare: FoursquareApps,
}

const Stacks: React.FC<StacksProps> = React.forwardRef(({ name, values, platform }, ref) => {
    return (
        <Box
            ref={ref}
            bgcolor="white"
            p={1}
            key={platform}
        >
            <Typography fontSize="12px" fontWeight={700} mb={1} color="#374957">{name}</Typography>
            <Stack direction="row" gap={1} mb={1}>
                {AppsByPlatform[platform].map(({ icon: Icon }) => <Icon sx={{
                    boxShadow: '0px 2px 4px 0px #00000029',
                    borderRadius: '50%',
                    border: '5px solid white',
                    fontSize: '25px'
                }} />)}
            </Stack>
            <Stack gap={2} direction="row" alignItems="center" color="#374957">
                <Icon color="#415D78" name="map-marker" prefix='sr' />
                <Typography noWrap>{getResultLabel(values.name_status, values.name)}</Typography>
                <EllipseIcon color={getRelevanceColor(values.name_status)} fontSize="16px" />
            </Stack>
            <Stack gap={2} direction="row" alignItems="center" color="#374957">
                <Icon color="#415D78" name="home" prefix='sr' />
                <Typography noWrap>{getResultLabel(values.address_status, values.address)}</Typography>
                <EllipseIcon color={getRelevanceColor(values.address_status)} fontSize="16px" />
            </Stack>
            <Stack gap={2} direction="row" alignItems="center" color="#374957">
                <Icon color="#415D78" name="phone-call" prefix='sr' />
                <Typography noWrap>{getResultLabel(values.phone_status, values.phone)}</Typography>
                <EllipseIcon color={getRelevanceColor(values.phone_status)} fontSize="16px" />
            </Stack>
        </Box>

    )
})

export default Stacks;