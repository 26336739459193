import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import * as Pages from "../../pages";

import { IRootState } from "../store-redux";

import { PrivateRouter } from "../components";

function AppRoutes() {
  const { isAuthenticated, saasUserToken } = useSelector(
    (state: IRootState) => state.authenticated
  );

  return (
    <Routes>
      <Route path="/" element={<Pages.CheckForm />} />
      <Route
        path="/login"
        element={
          !isAuthenticated ? (
            <Pages.Login />
          ) : (
            <Navigate to="/dashboard/event/list" />
          )
        }
      />
      <Route path="/eventNotFound" element={<Pages.EventNotFound />} />
      <Route path="/:event" element={<Pages.CheckForm />} />
      <Route path="/diagnosis/:diagnosisId" element={<Pages.Diagnosis />} />
      {/* <Route path="/diagnosis/batch/:id" element={<Pages.DiagnosisBatch />} /> */}
      <Route path="/diagnosis/batch/:id/details" element={<Pages.DiagnosisBatchDetails />} />
      <Route path="/404" element={<Pages.Erro404 />} />

      <Route
        element={
          <PrivateRouter
            accessToken={saasUserToken}
            isAuthenticated={isAuthenticated}
            redirectPath="/login"
          />
        }
      >
        <Route path="/dashboard/event/list" element={<Pages.Dashboard />} />

        {/* Mudar quando create event estiver finalizada */}
        <Route path="/dashboard/event/create" element={<Pages.CreateEvent />} />

        {/* Mudar quando edit event estiver finalizado*/}
        <Route path="/dashboard/event/edit/:eventId" element={<Pages.CreateEvent />} />

        <Route path="/dashboard/batch/list" element={<Pages.BatchList />} />
        <Route path="/dashboard/batch/list/:id" element={<Pages.BatchSingle />} />

        <Route
          path="/dashboard/event/:eventId"
          element={<Pages.ResultEvent />}
        />
      </Route>
      <Route path="*" element={<Pages.Erro404 />} />
    </Routes>
  );
}

export default AppRoutes;
