/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { StyledAlertDialogHeader, StyledButton, StyledCounterText, StyledDialog, StyledDialogContent, StyledText } from "./alert-dialog-styles";
import { TAlertDialogProps } from "./types";

const AlertDialog = ({ isOpen, onClose }: TAlertDialogProps) => {
  const [counterToReload, setCounterToReload] = useState(15);

  useEffect(() => {
    const interval = setInterval(() => 
      setCounterToReload(counter => {
        if (counter <= 0) {
          onClose();
          return counter;
        }

        return counter - 1;
      }),
      1000,
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <StyledDialog open={isOpen}>
      <StyledAlertDialogHeader>
        <h3>Atenção</h3>
      </StyledAlertDialogHeader>
      <StyledDialogContent>
        <StyledText>
          Agradecemos o seu interesse. Infelizmente não conseguimos atender você
          no momento. Nossa solução é válida para empresas com pontos físicos.
        </StyledText>
        <StyledButton onClick={onClose}>Finalizar</StyledButton>
        <StyledCounterText>O formulário será encerrado em {counterToReload} segundos</StyledCounterText>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default AlertDialog;