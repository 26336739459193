import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import { ptBR } from "date-fns/locale";

import { IRootState } from "../../store-redux";
import DiagnosisTable from "../../components/diagnosis-table/diagnosis-table";
import IconButton from "../icon-button";
import InformationTable from "./information-table";
import StatisticsBall from "./statistics-ball";
import RelevanceBar from "./relevance-bar";

import { getDiagnosisById } from "../../services/diagnosis/getDiagnosisById";

import {
  StyledLeftContent,
  StyledLeftTitle,
  StyledModalContent,
  StyledModalLeadContainer,
  StyledModalLeadHeader,
  StyledStats,
  StyleRightContent,
} from "./model-lead-styles";

import { TModalLeadProps } from "./types";
import { TTableInformation } from "./information-table/types";
import { TDiagnosisCompanyData } from "../diagnosis-table/types";

import { isEmpty, isNil } from "ramda";

import { platformsOrder } from "../diagnosis-table/helpers";
import getOneLead from "../../services/leads/getOneLead";
import getDiagnosisStatusPercentage from "../../utils/get-diagnosis-status-percentage";
import { formattedTableInformation } from "./helpers";

const ModalLead = ({
  isOpen,
  onClose,
  currentOpenLeadId = "",
}: TModalLeadProps) => {
  const { saasUserToken } = useSelector(
    (state: IRootState) => state.authenticated
  );

  const [diagnosisData, setDiagnosisData] = useState<TDiagnosisCompanyData[]>([
    {
      address: "",
      company: "",
      directory: "",
      name_status: "",
      phone: "",
      address_status: "",
      phone_number_status: "",
      not_available: true,
    },
  ]);

  const [correctPercentage, setCorrectPercentage] = useState(0);
  const [incorrectPercentage, setIncorrectPercentage] = useState(0);
  const [inexistentPercentage, setInexistentPercentage] = useState(0);
  const [relevance, setRelevance] = useState("low");
  const [company, setCompany] = useState("");
  const [createdAtDiagnosis, setCreatedAtDiagnosis] = useState("");

  const [information, setInformation] = useState<TTableInformation[]>([
    {
      label: "",
      value: "",
    },
  ]);

  const getCurrentLead = useCallback(async () => {
    if (isNil(currentOpenLeadId)) return;
    
    const getCurrentLeadResponse = await getOneLead({
      leadId: currentOpenLeadId,
      accessToken: saasUserToken,
    })

    const company = getCurrentLeadResponse.company_name;
    setCompany(company);

    const createdAtDiagnosis = getCurrentLeadResponse.created_at
    const formatCreatedAt = format(new Date(createdAtDiagnosis), "dd/MM/yyyy", {
      locale: ptBR,
    })
    
    setCreatedAtDiagnosis(formatCreatedAt)

  }, [currentOpenLeadId, saasUserToken]);

  useEffect(() => {
    getCurrentLead()
  }, [getCurrentLead]);

  const getDiagnosis = useCallback(async () => {
    if (isEmpty(currentOpenLeadId)) return;

    const diagnosisDataResponse = await getDiagnosisById({
      leadId: currentOpenLeadId,
      accessToken: saasUserToken,
    });

    if (isNil(diagnosisDataResponse)) return;
    
    const diagnosisReturn = diagnosisDataResponse.map((event) => ({
      address: event?.address,
      company: event?.name === "Error" ? null : event?.name,
      directory: event?.platform,
      name_status: event?.name_status,
      phone: event?.phone,
      address_status: event?.address_status,
      phone_number_status: event?.phone_status,
      not_available: event?.not_available,
      order: platformsOrder[event?.platform],
    }));
       
    diagnosisReturn.sort(
      (a: { order: number }, b: { order: number }) => a.order - b.order
    );

    const { correct, incorrect, nonexistent } = getDiagnosisStatusPercentage(diagnosisDataResponse);

    if (correct >= 70) {
      setRelevance("high");
    }

    setCorrectPercentage(correct);
    setIncorrectPercentage(incorrect);
    setInexistentPercentage(nonexistent);

    const [{ lead }] = diagnosisDataResponse;
    
    const tableInfo = formattedTableInformation(lead);

    setInformation(tableInfo);

    setDiagnosisData(diagnosisReturn);
  }, [saasUserToken, currentOpenLeadId]);

  useEffect(() => {
    getDiagnosis();
  }, [getDiagnosis]);

  return (
    <StyledModalLeadContainer open={isOpen} maxWidth="xl">
      <StyledModalLeadHeader>
        <span>
          <strong>{company} </strong> | {createdAtDiagnosis}
        </span>
        <IconButton onClick={onClose} isWhite iconSize={1.5} />
      </StyledModalLeadHeader>
      <StyledModalContent>
        <StyledLeftContent>
          <StyledLeftTitle>
            Confira aqui o{" "}
            <strong>nível de otimização da Empresa/Local do cliente </strong>{" "}
            relacionados a sua localização online nos principais mapas e listas
            da internet:
          </StyledLeftTitle>

          <StyledStats>
            <StatisticsBall
              percentage={correctPercentage}
              name="Correto"
              color="#00DA91"
            />
            <StatisticsBall
              percentage={inexistentPercentage}
              name="Inexistente"
              color="#FF5C5C"
            />
            <StatisticsBall
              percentage={incorrectPercentage}
              name="Incorreto"
              color="#FF9920"
            />
          </StyledStats>

          <InformationTable tableInformation={information} />
        </StyledLeftContent>
        <StyleRightContent>
          <RelevanceBar relevance={relevance} />
          <DiagnosisTable check={diagnosisData} />
        </StyleRightContent>
      </StyledModalContent>
    </StyledModalLeadContainer>
  );
};

export default ModalLead;
