import { FormControlLabel, Switch, Tooltip } from "@mui/material";
import { switchOptions } from "./helpers";
import { StyledSwitchContainer, StyledSwitchOption } from "./switch-options-styles";
import { TSwitchOptionsProps } from "./types";

const SwitchOptions = ({ switchesOptions, setSwitchesValue }: TSwitchOptionsProps) => {
  const handleChangeSwitch = (value: boolean, field: string ) => {
    setSwitchesValue(prev => ({ ...prev, [field]: value }));
  };

  return (
    <StyledSwitchContainer>
      {
        switchOptions.map(option => (
          <StyledSwitchOption key={option.name}>
            <p>{option.label}</p>
            <FormControlLabel
              control={
                <Tooltip title={option.tooltip}>
                  <Switch 
                    checked={switchesOptions[option.name]}
                    onChange={({ target }) => handleChangeSwitch(target.checked, option.name)}
                  />
                </Tooltip>
              } 
              label={ switchesOptions[option.name] ? "Habilitado" : "Desabilitado" } 
            />
          </StyledSwitchOption>
        ))
      }

    </StyledSwitchContainer>
  );
};

export default SwitchOptions;