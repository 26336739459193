import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
`;

export const StyledLogin = styled.div`
  max-width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: auto;

  > img {
    max-width: 25rem;
    display: block;
    margin-bottom: 1.5rem;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyleInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const StyleMensage = styled.span`
  text-align: center;
  color: ${props => props.theme.colors.blackLight};
  margin-bottom: 1.5rem;
`;

export const StyledButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .buttons-group-button {
    width: 100%;
  }
`;