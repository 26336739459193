import axios from "axios";

type TDeleteEventProps = {
  id: string;
  accessToken: string | null;
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  setTokenLikeExpired?: () => void;
};

export default async function deleteEvent({
  id,
  accessToken,
  setTokenLikeExpired,
  setIsFetching,
}: TDeleteEventProps) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (setIsFetching) setIsFetching(true);

    await axios.delete(
      `${process.env.REACT_APP_EVENT_API_URL}/delete/${id}`,
      requestOptions
    );
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired)
      return setTokenLikeExpired();

    return err;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
