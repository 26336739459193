import { isEmpty } from "ramda";
import addImageProcess from "../../shared/utils/add-image-process";
import {
  TDiagnosisEditFormEntity,
  TEventInfoEditFormEntity,
  TFooterEditFormEntity,
  TIsValidImageFile,
  TMainEditFormEntity,
} from "./types";

type TPutEventInfoProps = {
  event: any;
  setEventInfo: React.Dispatch<React.SetStateAction<TEventInfoEditFormEntity>>;
};

type TPutDiagnosisInfoProps = {
  event: any;
  setEventInfo: React.Dispatch<React.SetStateAction<TDiagnosisEditFormEntity>>;
};

type TPutFooterInfoProps = {
  event: any;
  setEventInfo: React.Dispatch<React.SetStateAction<TFooterEditFormEntity>>;
};

type TPutFormInfoProps = {
  event: any;
  setEventInfo: React.Dispatch<React.SetStateAction<TMainEditFormEntity>>;
};

export const isValidImageFile = async ({
  imageFileValidation,
  imageFile,
  callbackValidationMessage,
}: TIsValidImageFile) => {
  const { imageWidth, imageHeight } = await addImageProcess(
    imageFile.sourceUrl
  );

  if (imageFile.size < imageFileValidation.imageSize) {
    callbackValidationMessage(
      imageFileValidation.imageSizeMessage(imageFile.name),
      { variant: "warning" }
    );

    return false;
  }

  const imageTooSmall =
    imageWidth < imageFileValidation.minImageWidth ||
    imageHeight < imageFileValidation.minImageHeight;

  const imageTooLarge =
    imageWidth > imageFileValidation.maxImageWidth ||
    imageHeight > imageFileValidation.maxImageHeight;

  if (imageTooSmall) {
    callbackValidationMessage(
      imageFileValidation.tooSmallImageMessage(imageFile.name),
      { variant: "warning" }
    );

    return false;
  }

  if (imageTooLarge) {
    callbackValidationMessage(
      imageFileValidation.tooLargeImageMessage(imageFile.name),
      { variant: "warning" }
    );

    return false;
  }

  return true;
};

export const putEventInfo = ({ event, setEventInfo }: TPutEventInfoProps) => {
  const { name, slug, status } = event;

  setEventInfo({
    event_name: name,
    slug,
    status_inicial: status,
  });
};

export const putDiagnosisInfo = ({
  event,
  setEventInfo,
}: TPutDiagnosisInfoProps) => {
  const { diagnosis } = event;

  setEventInfo(diagnosis);
};

export const putFormInfo = ({ event, setEventInfo }: TPutFormInfoProps) => {
  const { form } = event;
  const {
    background_image,
    first_logo,
    left_first_layer,
    left_second_layer,
    mobile_first_layer,
    mobile_second_layer,
    second_logo,
    js_code,
    html_code,
  } = form;

  setEventInfo({
    background_image,
    first_logo,
    left_first_layer,
    left_second_layer,
    mobile_first_layer,
    mobile_second_layer,
    second_logo,
    js_code,
    html_code,
  });
};

export const putFooterInfo = ({ event, setEventInfo }: TPutFooterInfoProps) => {
  const { footer } = event;
  const { text_color, background_color, main_logo, sponsor } = footer;
  let logo: any;
  let sponsorId: string;

  if (!isEmpty(sponsor)) {
    logo = sponsor[0].logo;
    sponsorId = sponsor[0].id;
  }

  setEventInfo((prev) => ({
    primary_logo: main_logo,
    secondary_logo: logo,
    text_color,
    background_color,
    sponsorId: sponsorId,
  }));
};

const RequireMainFilter = [
  "first_logo",
  "left_first_layer",
  "left_second_layer",
  "background_image",
  "mobile_first_layer",
  "mobile_second_layer",
];

export function ValidateImageInput(keys: string[]) {
  const RequireFilter = keys.reduce((count, currentValue) => {
    if (RequireMainFilter.includes(currentValue)) {
      return count + 1;
    }
    return count;
  }, 0);
  return RequireFilter === RequireMainFilter.length;
}
