import { LeadCompanyFieldEnum } from "./enum/lead-company-field.enum";
import { LeadPdvQuantityEnum } from "./enum/lead-pdv-quantity.enum";
import { TTableCellData } from "./types";

export const columns = [
  {
    name: "id",
    label: "Id",
    options: {
      sort: false,
      display: false,
    },
  },
  {
    name: "client_name",
    label: "Nome",
    options: {
      sort: false,
    },
  },
  {
    name: "company_name",
    label: "Empresa",
    options: {
      sort: false,
    },
  },
  {
    name: "company_field",
    label: "Segmento",
    options: {
      sort: false,
    },
  },
  {
    name: "pdv_quantity",
    label: "Qtd de PDVs",
    options: {
      sort: false,
    },
  },
  {
    name: "client_phone_number",
    label: "Telefone",
    options: {
      sort: false,
    },
  },
  {
    name: "client_email",
    label: "E-mail",
    options: {
      sort: false,
    },
  },
  {
    name: "city",
    label: "Cidade",
    options: {
      sort: false,
    },
  },
  {
    name: "state",
    label: "Estado",
    options: {
      sort: false,
    },
  },
  {
    name: "created_at",
    label: "Data",
    options: {
      sort: false,
    },
  },
  {
    name: "results",
    label: "Resultados",
    options: {
      sort: false,
    },
  },
];

type TColor = {
  [value: string]: color;
  ACTIVE: color;
  INCOMPLETE: color;
  INACTIVE: color;
};

type TFormatCsvTableData = {
  data: any[];
  columns: any;
  buildHead: any;
};

type color = "success" | "warning" | "default" | undefined;

export const colors: TColor = {
  ACTIVE: "success",
  INCOMPLETE: "warning",
  INACTIVE: "default",
};

export const statusDictionary: Record<string, string> = {
  ACTIVE: "ativa",
  INCOMPLETE: "incompleto",
  INACTIVE: "inativo",
};

function translateLeadCompanyField(line: string): string {
  const keys = Object.keys(LeadCompanyFieldEnum);
  const values = Object.values(LeadCompanyFieldEnum);

  const keyIndex = keys.findIndex((key) => key === line);

  if (keyIndex === -1) return line;

  return values[keyIndex];
}

function translateLeadPdvQuantity(line: string): string {
  const keys = Object.keys(LeadPdvQuantityEnum);
  const values = Object.values(LeadPdvQuantityEnum);

  const keyIndex = keys.findIndex((key) => key === line);

  if (keyIndex === -1) return line;

  return values[keyIndex];
}

export function formatCsvTableData({
  data,
  buildHead,
  columns,
}: TFormatCsvTableData) {
  let body = "";
  data.forEach((tableRow: any) => {
    const line = tableRow?.data
      .map((row: TTableCellData) => {
        let rowValue = translateLeadCompanyField(row.value);
        rowValue = translateLeadPdvQuantity(rowValue);

        return `"${rowValue}"`;
      })
      .join(",");
    body += `${line}\n`;
  });

  return buildHead(columns) + body;
}
