import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';



const BingIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox="0 0 501 500" {...props}>
            <path d="M198.522 129.615L249.485 240.035L324.659 273.943L53.324 416.667L164.547 317.683V35.1124L50.0469 0V420.278L163.878 500L449.993 329.454V206.594L198.522 129.615Z" fill="#00897B" />
        </SvgIcon>
    )
}

export default BingIcon;