import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import WebhookIcon from "@mui/icons-material/Webhook";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import FormButton from "../../shared/components/form-button";
import Header from "../../shared/components/header";
import {
  EventInfoEditForm,
  MainEditForm,
  DiagnosisEditForm,
  FooterEditForm,
} from "../../shared/components/create-event";
import WebhookDialog from "../../shared/components/webhook-dialog";

import {
  putDiagnosisInfo,
  putEventInfo,
  putFooterInfo,
  putFormInfo,
  ValidateImageInput,
} from "./helpers";

import { createFooterForm } from "../../shared/services/footer/createFooterForm";
import putObject from "../../shared/services/aws/putObject";
import { IRootState } from "../../shared/store-redux";
import { createDiagnosis } from "../../shared/services/diagnosis/createDiagnosis";
import { createSponsorFooterForm } from "../../shared/services/sponsor/createSponsorFooterForm";
import { createNewEvents } from "../../shared/services/events/createEvents";
import { createForm } from "../../shared/services/check-form/create-form";
import getOneEvent from "../../shared/services/events/getOneEvent";
import updateEvent from "../../shared/services/events/update-event";
import updateForm from "../../shared/services/check-form/update-form";
import updateDiagnosis from "../../shared/services/diagnosis/update-diagnosis";
import updateFooter from "../../shared/services/footer/update-footer";
import { updateSponsorFooterForm } from "../../shared/services/sponsor/updateSponsorFooterForm";
import validateToken from "../../shared/services/auth/validateToken";

import isNilOrEmpty from "../../shared/utils/isNilOrEmpty";
import { userSetTokenLikeExpired } from "../../shared/utils/setUsetLikeExpired";

import {
  StyledContainer,
  StyledButton,
  GroupButtonRight,
  StyledTables,
} from "./create-event-styles";

import {
  TDiagnosisEditFormEntity,
  TEventInfoEditFormEntity,
  TFooterEditFormEntity,
  TMainEditFormEntity,
  TWebHooksState,
} from "./types";
import { isNil } from "ramda";
import { deleteSponsorFooterForm } from "../../shared/services/sponsor/deleteSponsorFooterForm";
import SwitchOptions from "./switch-options";

const CreateEvent = () => {
  const navigate = useNavigate();
  const { saasUserToken } = useSelector(
    (state: IRootState) => state.authenticated
  );
  const { enqueueSnackbar } = useSnackbar();
  const { eventId } = useParams();

  const [eventInfoData, setEventInfoData] =
    useState<TEventInfoEditFormEntity>(Object);
  const [mainFormData, setMainFormData] = useState<TMainEditFormEntity>(Object);
  const [diagnosisFormData, setDiagnosisFormData] =
    useState<TDiagnosisEditFormEntity>(Object);
  const [footerFormData, setFooterFormData] =
    useState<TFooterEditFormEntity>(Object);
  const [isWebhookDialogOpen, setIsWebHookDialogOpen] = useState(false);
  const [eventWebhooks, setEventWebhooks] = useState<TWebHooksState>({
    main: "",
    webhooks: [],
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [isValidSlug, setIsValidSlug] = useState(eventId ? true : false);

  const [switchesOptions, setSwitchesOptions] = useState({
    request_information: true,
    request_pdv_quantity: true,
    request_company_field: true,
    request_position_in_company: true,
    request_terms_of_service: false,
    show_landing_page: false,
  });

  const handleCreateFooterForm = useCallback(async () => {
    try {
      const { primary_logo, secondary_logo, text_color, background_color } =
        footerFormData;

      if (!primary_logo) {
        enqueueSnackbar("Carregue todas as imagens obrigatórias!", {
          variant: `warning`,
          autoHideDuration: 1500,
        });
        return null;
      }

      const primaryLogoFile = await putObject({
        accessToken: saasUserToken,
        bucketName: "check-hublocal-media",
        eventCheck: eventInfoData.slug,
        file: primary_logo,
        batchFile: true,
      });
      const primaryLogoFileUrl = primaryLogoFile.fileUrl;

      const secondaryLogoFile = await putObject({
        accessToken: saasUserToken,
        bucketName: "check-hublocal-media",
        eventCheck: eventInfoData.slug,
        file: secondary_logo,
        batchFile: true,
      });
      const secondaryLogoFileUrl = secondaryLogoFile.fileUrl;

      const createNewFooter = await createFooterForm({
        accessToken: saasUserToken,
        main_logo: primaryLogoFileUrl,
        text_color,
        background_color,
      });
      if (createNewFooter.id && !isNil(secondaryLogoFileUrl)) {
        await createSponsorFooterForm({
          accessToken: saasUserToken,
          name: secondary_logo.name,
          logo: secondaryLogoFileUrl,
          footer: createNewFooter.id,
        });
      }
      return await createFooterForm({
        ...createNewFooter,
        accessToken: saasUserToken,
      });
    } catch (err) {
      return err;
    }
  }, [footerFormData, saasUserToken, eventInfoData.slug, enqueueSnackbar]);

  const handleCreateDiagnosisForm = useCallback(async () => {
    try {
      await createDiagnosis({
        ...diagnosisFormData,
        accessToken: saasUserToken,
      });
      return await createDiagnosis({
        ...diagnosisFormData,
        accessToken: saasUserToken,
      });
    } catch (err) {
      return err;
    }
  }, [diagnosisFormData, saasUserToken]);

  const handleCreateEventForm = useCallback(async () => {
    try {
      const inputFiles = Object.keys(mainFormData);
      const hasAllRequiredFile = ValidateImageInput(inputFiles);
      if (!hasAllRequiredFile) {
        enqueueSnackbar("Carregue todas as imagens obrigatórias!", {
          variant: `warning`,
          autoHideDuration: 1500,
        });
        return null;
      }

      const imagesPromises = Object.keys(mainFormData).map(async (key) => {
        if (key !== "js_code") {
          const { fileUrl } = await putObject({
            accessToken: saasUserToken,
            bucketName: "check-hublocal-media",
            eventCheck: eventInfoData.slug,
            file: mainFormData[key] as File,
            batchFile: true,
          });
          return { key, fileUrl };
        }
        return { key, fileUrl: null };
      });

      const images = await Promise.all(imagesPromises);

      const formsData: any = {};

      images.forEach(
        (image) =>
          (formsData[image.key] =
            image.key === "js_code" ? mainFormData.js_code : image.fileUrl)
      );

      return await createForm({ ...formsData, accessToken: saasUserToken });
    } catch (err) {
      return err;
    }
  }, [mainFormData, enqueueSnackbar, saasUserToken, eventInfoData.slug]);

  const handleCreateNewEvent = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (isNil(saasUserToken)) return;

      const validToken = await validateToken({
        accessToken: saasUserToken,
        setTokenLikeExpired: userSetTokenLikeExpired,
      });

      if (validToken instanceof Error) {
        return;
      }

      if (!isValidSlug) {
        return enqueueSnackbar("O slug não é válido!", {
          variant: "warning",
          autoHideDuration: 1500,
        });
      }

      setIsCreated(true);

      try {
        const { event_name, status_inicial, slug } = eventInfoData;

        const newWebhooks = [eventWebhooks.main, ...eventWebhooks.webhooks];
        const footer = await handleCreateFooterForm();
        const forms = await handleCreateEventForm();
        const diagnosis = await handleCreateDiagnosisForm();

        if (isNil(footer) || isNil(forms)) {
          setIsCreated(false);
          return;
        }

        const NewEvent = await createNewEvents({
          accessToken: saasUserToken,
          name: event_name,
          slug,
          status: status_inicial,
          footer: footer.id,
          diagnosis: diagnosis.id,
          form: forms.id,
          webhooks: newWebhooks,
          ...switchesOptions,
        });

        if (NewEvent) {
          enqueueSnackbar("Evento criado com sucesso!", {
            variant: "success",
            autoHideDuration: 2000,
          });
          setIsCreated(false);
          navigate("/dashboard/event/list");
        } else {
          enqueueSnackbar("Ocorreu um erro ao criar Evento!", {
            variant: `error`,
            autoHideDuration: 1500,
          });
          setIsCreated(false);
          return;
        }
      } catch (err) {
        enqueueSnackbar("Preencha todos os campos obrigatórios!", {
          variant: `error`,
          autoHideDuration: 1500,
        });
        setIsCreated(false);
        return;
      }
    },
    [
      eventInfoData,
      eventWebhooks,
      handleCreateFooterForm,
      handleCreateEventForm,
      handleCreateDiagnosisForm,
      saasUserToken,
      enqueueSnackbar,
      navigate,
      isValidSlug,
      switchesOptions,
    ]
  );

  const handleUpdateForm = useCallback(
    async (formId: string) => {
      try {
        const imagesPromises = Object.keys(mainFormData).map(async (key) => {
          if (typeof mainFormData[key] === "string" || key === "js_code") {
            return { key, fileUrl: mainFormData[key] };
          } else {
            const { fileUrl } = await putObject({
              accessToken: saasUserToken,
              bucketName: "check-hublocal-media",
              eventCheck: eventInfoData.slug,
              file: mainFormData[key] as File,
              batchFile: true,
            });
            return { key, fileUrl };
          }
        });

        const images = await Promise.all(imagesPromises);
        const formsData: any = {};

        images.forEach(
          (image) =>
            (formsData[image.key] =
              image.key === "js_code" ? mainFormData.js_code : image.fileUrl)
        );

        return await updateForm({
          ...formsData,
          accessToken: saasUserToken,
          id: formId,
        });
      } catch (error) {
        return error;
      }
    },
    [eventInfoData, mainFormData, saasUserToken]
  );

  const handleUpdateFooter = useCallback(
    async (footerId: string) => {
      try {
        const {
          background_color,
          primary_logo,
          secondary_logo,
          text_color,
          sponsorId,
        } = footerFormData;
        const data: any = {
          background_color,
          primary_logo,
          secondary_logo,
          text_color,
        };
        if (primary_logo && typeof primary_logo !== "string") {
          const primaryLogoFile = await putObject({
            accessToken: saasUserToken,
            bucketName: "check-hublocal-media",
            eventCheck: eventInfoData.slug,
            file: primary_logo,
            batchFile: true,
          });

          data.primary_logo = primaryLogoFile.fileUrl;
        }

        if (secondary_logo && typeof secondary_logo !== "string") {
          const secondaryLogoFile = await putObject({
            accessToken: saasUserToken,
            bucketName: "check-hublocal-media",
            eventCheck: eventInfoData.slug,
            file: secondary_logo,
            batchFile: true,
          });
          data.secondary_logo = secondaryLogoFile.fileUrl;
        }

        if (sponsorId && secondary_logo) {
          await updateSponsorFooterForm({
            accessToken: saasUserToken || "",
            footer: footerId,
            logo: data.secondary_logo,
            id: sponsorId,
          });
        }

        if (!secondary_logo) {
          await deleteSponsorFooterForm({
            accessToken: saasUserToken || "",
            id: sponsorId,
          });
        }

        if (!sponsorId && secondary_logo) {
          await createSponsorFooterForm({
            accessToken: saasUserToken || "",
            footer: footerId,
            logo: data.secondary_logo,
            name: secondary_logo.name,
          });
        }

        await updateFooter({
          ...data,
          main_logo: data.primary_logo,
          sponsor: [data.secondary_logo],
          accessToken: saasUserToken,
          id: footerId,
        });
      } catch (error) {
        enqueueSnackbar("Algo deu errado na atualização!", {
          variant: "warning",
        });
      }
    },
    [enqueueSnackbar, eventInfoData, footerFormData, saasUserToken]
  );

  const handleUpdateEvent = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!isValidSlug) {
        return enqueueSnackbar("O slug não é válido!", {
          variant: "warning",
          autoHideDuration: 1500,
        });
      }

      setIsUpdating(true);

      try {
        const event = await updateEvent({
          name: eventInfoData.event_name,
          slug: eventInfoData.slug,
          status: eventInfoData.status_inicial,
          webhooks: [eventWebhooks.main, ...eventWebhooks.webhooks],
          id: eventId || "",
          accessToken: saasUserToken,
          ...switchesOptions,
        });

        await handleUpdateForm(event.form.id);
        await updateDiagnosis({
          ...diagnosisFormData,
          id: event.diagnosis.id,
          accessToken: saasUserToken,
        });
        await handleUpdateFooter(event.footer.id);
      } catch (error) {
        enqueueSnackbar("Algo deu errado ao atualizar o evento!", {
          variant: "warning",
          autoHideDuration: 1500,
        });
        return null;
      } finally {
        enqueueSnackbar("Evento editado com sucesso!", {
          variant: "success",
          autoHideDuration: 1500,
        });
        setIsUpdating(false);
        navigate("/dashboard/event/list");
      }
    },
    [
      eventInfoData,
      eventId,
      handleUpdateForm,
      saasUserToken,
      diagnosisFormData,
      enqueueSnackbar,
      handleUpdateFooter,
      eventWebhooks,
      navigate,
      isValidSlug,
      switchesOptions,
    ]
  );

  // Check if is editEvent and get Event data
  const handlePutEventInfo = useCallback(async () => {
    const event = await getOneEvent({
      accessToken: saasUserToken,
      eventId: eventId || "",
    });

    const {
      webhooks,
      request_information,
      request_pdv_quantity,
      request_company_field,
      request_position_in_company,
      request_terms_of_service,
      show_landing_page,
    } = event;

    putEventInfo({ event, setEventInfo: setEventInfoData });
    putFormInfo({ event, setEventInfo: setMainFormData });
    putDiagnosisInfo({ event, setEventInfo: setDiagnosisFormData });
    putFooterInfo({ event, setEventInfo: setFooterFormData });

    setSwitchesOptions({
      request_company_field: !!request_company_field,
      request_information: !!request_information,
      request_pdv_quantity: !!request_pdv_quantity,
      request_position_in_company: !!request_position_in_company,
      request_terms_of_service: !!request_terms_of_service,
      show_landing_page: !!show_landing_page,
    });

    if (isNilOrEmpty(webhooks)) {
      setEventWebhooks({ main: "", webhooks: [] });
    } else {
      setEventWebhooks({ main: webhooks[0], webhooks: [...webhooks.slice(1)] });
    }
  }, [eventId, saasUserToken]);

  useEffect(() => {
    if (eventId) {
      handlePutEventInfo();
    }
  }, [eventId, handlePutEventInfo]);

  return (
    <StyledContainer>
      <Helmet>
        <meta
          property="og:title"
          content={`${
            eventId ? "Editar" : "Adicionar"
          } Evento - Check Hublocal`}
        />
        <meta
          property="og:description"
          content={`${eventId ? "Editar" : "Adicionar"} Evento Check Hublocal.`}
        />
        <title>{`${
          eventId ? "Editar" : "Adicionar"
        } Evento - Check Hublocal`}</title>
      </Helmet>
      <Header title={`${eventId ? "Editar" : "Adicionar"} Evento`} />

      <form onSubmit={eventId ? handleUpdateEvent : handleCreateNewEvent}>
        <StyledButton>
          <FormButton
            icon={<ArrowBackIosNewIcon sx={{ height: 10, width: 10 }} />}
            children="Voltar"
            variant="blue"
            uppercase
            type="button"
            onClick={() => navigate("/dashboard/event/list")}
          />
          <GroupButtonRight>
            <FormButton
              icon={<WebhookIcon sx={{ height: 20, width: 20 }} />}
              children="webHook"
              variant="blue"
              type="button"
              uppercase
              onClick={() => setIsWebHookDialogOpen(true)}
            />
            <FormButton
              children="Salvar"
              variant="blue"
              uppercase
              type="submit"
              isLoading={eventId ? isUpdating : isCreated}
            />
          </GroupButtonRight>
        </StyledButton>

        <StyledTables>
          <EventInfoEditForm
            urlEvent="check.hublocal.com.br"
            eventInfoData={eventInfoData}
            setEventInfoData={setEventInfoData}
            initialSlug={eventInfoData.slug}
            eventId={eventId}
            isValidSlug={isValidSlug}
            setIsValidSlug={setIsValidSlug}
            hasNoDefaultSlug={isNilOrEmpty(eventId)}
          />

          <MainEditForm
            mainFormData={mainFormData}
            setMainFormData={setMainFormData}
          />

          <DiagnosisEditForm
            diagnosisFormData={diagnosisFormData}
            setDiagnosisFormData={setDiagnosisFormData}
          />

          <FooterEditForm
            footerFormData={footerFormData}
            setFooterFormData={setFooterFormData}
          />
          <SwitchOptions
            switchesOptions={switchesOptions}
            setSwitchesValue={setSwitchesOptions}
          />
        </StyledTables>
      </form>
      <WebhookDialog
        isOpen={isWebhookDialogOpen}
        onClose={() => setIsWebHookDialogOpen(false)}
        setWebhooks={setEventWebhooks}
        webhooks={eventWebhooks}
      />
    </StyledContainer>
  );
};
export default CreateEvent;
