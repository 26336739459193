import styled from "styled-components";
import { breakpoints } from "../../../assets/styles/devices";

export const StyledSide = styled.div<{ background: string; }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: 100vh;
  min-height: 720px;
  overflow: hidden;

  clip-path: polygon(0 0, 93% 0, 93% 46%, 100% 50%, 93% 54%, 93% 100%, 0 100%);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.background});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(1.5);
    z-index: -1;
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    display: none;
    width: 100%;  
    padding: 3rem 0;
  }
`;

export const StyledImageLayer = styled.img`
  bottom: 0;
  position: absolute;
  width: 100%;
`;

export const StyledCenterBanner = styled.img`
  position: relative;
  width: 500px;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 2rem;
`;
