import styled from "styled-components";
import { breakpoints } from "../../../../assets/styles/devices";
import { Typography } from "@mui/material";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    flex-direction: column;
    text-align: center;
  }
`;

export const StyledTypography = styled(Typography)`
font-weight: 300 !important;
font-size: 20px;
`