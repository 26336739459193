import Saga from "../../../../assets/images/svg/landing-page/feedbacks/saga.png";
import SagaAvatar from "../../../../assets/images/svg/landing-page/feedbacks/avatar-saga.png";
import BotoCenter from "../../../../assets/images/svg/landing-page/feedbacks/botocenter.png";
import BotoCenterAvatar from "../../../../assets/images/svg/landing-page/feedbacks/avatar-botocenter.png";
import Kikos from "../../../../assets/images/svg/landing-page/feedbacks/kikos.png";
import KikosAvatar from "../../../../assets/images/svg/landing-page/feedbacks/avatar-kikos.png";

export const cardContent = [
  {
    text: "Estávamos passando por um momento complicado para conseguir cuidar dos mais de 100 Cards no Google, até que conhecemos a HubLocal. Foi uma das melhores contratações do Grupo, agradeço pelo empenho e manteremos essa parceria por longos anos.",
    logo: Saga,
    avatar: SagaAvatar,
    author: "Joao Vitor",
    position: "SAC/Qualidade Grupo Saga",
  },
  {
    text: "É muito importante e satisfatório encontrar em nossa jornada profissional empresas que acreditam no cliente e dão suporte para que a gente cresça cada vez mais. Os resultados a cada mês são melhores e estamos muito satisfeitos em ter essa parceria com a HubLocal!",
    logo: BotoCenter,
    avatar: BotoCenterAvatar,
    author: "Rafaela Leal",
    position: "Marketing Botocenter",
  },
  {
    text: "A nossa experiência com a Hublocal está sendo muito satisfatória, pois temos a atualização da performance dos cards das nossas lojas através de dashboard que facilita a visualização e apresentação interna. Super recomendo!",
    logo: Kikos,
    avatar: KikosAvatar,
    author: "Pedro Roubles",
    position: "Marketing Kikos Fitness",
  },
];
