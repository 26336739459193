import { Dialog, DialogContent, TextField } from "@mui/material";
import { darken, lighten } from "polished";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
  padding: 1rem 2rem;

  & .MuiPaper-root {
    width: 80%;
    max-width: 440px;
    border-radius: 5px;
  }
`;


export const StyledDialogTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid ${props => lighten(0.9, props.theme.colors.black)};
  font-weight: 600;
  background: ${props => props.theme.colors.primary};

  button {
    margin-left: 0.8rem;
  }
`;

export const StyledDialogTitle = styled.h3`
  font-size: 1rem;
  font-family: Poppins;
  font-weight: normal;
  color: ${props => props.theme.colors.white};
`;

export const StyledDialogContent = styled(DialogContent)`
  margin-top: 0.5rem;
  width: 100%;
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.label`
  display: block;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  text-align: center;
`;

export const StyledInput = styled(TextField)`
  color: ${props => lighten(0.31, props.theme.colors.black)};
  font-weight: bold;
  width: 100%;
  height: fit-content;
  border-radius: 5px;
  margin-bottom: 1rem;

  & .MuiInputBase-input {
    padding: 0.25rem 1rem;
  }
`;

export const StyledButtonsWrapper = styled.div`
  border-top: 1px solid ${props => lighten(0.8, props.theme.colors.black)};
  padding-top: 0.5rem;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StyledButton = styled.button<{ isgrey?: boolean; disabledStyle?:boolean; }>`
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.isgrey ? props.theme.colors.grey : props.theme.colors.primary};
  font-weight: bold;
  cursor: pointer;
  flex: 1;

  background-color: ${(props) =>
    props.disabledStyle && lighten(0.1, props.theme.colors.grey)};

  &:hover {
    background-color: ${props => props.isgrey ? darken(0.15, props.theme.colors.grey) : darken(0.15, props.theme.colors.primary)};

    background-color: ${(props) =>
      props.disabledStyle && lighten(0.1, props.theme.colors.grey)};
  }

  &:active {
    background-color: ${props => props.isgrey ? darken(0.3, props.theme.colors.grey) : darken(0.3, props.theme.colors.primary)};

    background-color: ${(props) =>
      props.disabledStyle && lighten(0.1, props.theme.colors.grey)};
  }
`;