import { IUser } from "./types";

export function addUserAuthenticated(user:IUser, saasUserToken:string, isAuthenticated: boolean) {
  return {
    type: "ADD_USER_AUTHENTICATED",
    payload: {
      isAuthenticated,
      user,
      saasUserToken,
    }
  }
}

export function authenticationExpired(saasUserToken:string, isAuthenticated: boolean) {
  return {
    type: "AUTHENTICATION_EXPIRED",
    payload: {
      isAuthenticated,
      saasUserToken,
    }
  }
}