import { IconButton } from "@mui/material";
import styled from "styled-components";
import Tooltip from '@mui/material/Tooltip';

export const StyledIconButton =  styled(IconButton)<{
  iswhite?: number;
  iconsize: number;
}>`
  display: flex;
  flex-direction: column;
  width: 2rem;
  height: 2rem;
  
  &:focus {
    outline: none;
  }

  .icon-button__icon {
    fill: ${props => props.iswhite ? props.theme.colors.white : props.theme.colors.primary} !important;
    width: ${(props) => props.iconsize}rem;
    height: ${(props) => props.iconsize}rem;
  }
`;

export const StyledDisabledIcon = styled.div<{
  iconSize?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;

  .disabled {
    width: ${(props) => props.iconSize}rem;
    height: ${(props) => props.iconSize}rem;
    fill: ${(props) => props.theme.colors.grey} !important;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledTooltip = styled(Tooltip)`
  & .icon-button-tooltip {
    box-shadow: 0.1rem 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.58);
    background-color: ${props => props.theme.colors.white};
    color: rgba(0, 0, 0, 0.87);
  }
`;
