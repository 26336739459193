import { useMemo } from "react";
import { debounce } from "lodash";

import { Loading } from "../../../shared/components";

import { colors, columns, formatCsvTableData } from "./helpers";
import { TResultEventTableProps, TTableRowData } from "./types";

import { StyledTable, Tag } from "./result-event-table-styles";

const onDebounceSearchChange = debounce(
  (setQuery: React.Dispatch<React.SetStateAction<string>>, text: string) =>
    setQuery(text),
  500
);

const ResultEventTable = ({
  status,
  statusLabel,
  titleEvent,
  visits,
  forms,
  loading,
  data,
  setQuery,
  setPage,
  setPageSize,
  page,
  count,
  onDiagnostic,
  customRowRender,
  ...rest
}: TResultEventTableProps) => {
  const tableOptions = useMemo(
    () => ({
      search: false,
      selectableRowsHideCheckboxes: true,
      draggableColumns: {
        enabled: false,
      },
      textLabels: {
        body: {
          noMatch:
            loading === true ? (
              <Loading className="data-table-loading" />
            ) : (
              "Nenhum resultado encontrado"
            ),
        },
        pagination: {
          next: "Próxima página",
          previous: "Página anterior",
          rowsPerPage: "Linhas por página",
          displayRows: "de",
        },
        toolbar: {
          search: "Pesquisar",
          downloadCsv: "Baixar como CSV",
          print: "Imprimir",
          viewColumns: "Visualizar colunas",
          filterTable: "Filtrar",
        },
        filter: {
          all: "Todos",
          title: "Título",
          reset: "Resetar",
        },
        viewColumns: {
          title: "Mostrar Colunas",
          titleAria: "Mostrar/esconder colunas da tabela",
        },
        selectedRows: {
          text: "linha(s) selecionada(s)",
          delete: "Apagar",
          deleteAria: "Apagar Linhas Selecionadas",
        },
      },
      customRowRender: (rowData: TTableRowData) => customRowRender(rowData),
      onSearchChange: (queryText: string | null) => {
        if (!setQuery) return;

        if (!queryText) {
          if (setQuery) setQuery("");
          return;
        }

        if (setPage) setPage(0);
        onDebounceSearchChange(setQuery, queryText);
      },
      onSearchClose: () => {
        if (setPage) setPage(0);
        if (setQuery) setQuery("");
      },
      onChangePage: (currentPage: number) => {
        if (setPage) setPage(currentPage);
      },
      onChangeRowsPerPage: (numberOfRows: number) => {
        if (setPage) setPage(0);
        if (setPageSize) setPageSize(numberOfRows);
      },
      onDownload: (buildHead: any, _: any, columns: any, data: any[]) => {
        return formatCsvTableData({
          columns,
          buildHead,
          data,
        });
      },
      page: page,
      count: count,
      fixedHeader: true,
      download: true,
      filter: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [10, 50, 100],
      pagination: true,
      print: false,
      sort: false,
      viewColumns: false,
      elevation: 1,
      serverSide: true,
    }),
    [loading, count, page, setPage, setPageSize, setQuery, customRowRender]
  );

  return (
    <StyledTable
      title={[
        <Tag label={statusLabel} color={colors[status]} />,
        " ",
        <strong>{titleEvent}</strong>,
        " | ",
        <span>{visits} visitas</span>,
        " | ",
        <span>{forms} formulários</span>,
      ]}
      data={data}
      columns={columns}
      options={tableOptions}
      {...rest}
    />
  );
};

export default ResultEventTable;
