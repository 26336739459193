import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { isNil } from 'ramda';
import { TFooterEditFormEntity } from "../../../../pages/create-event/types";
import { 
  StyledContainer,
  StyledHeader, 
  StyledTitle, 
  StyledInputBox, 
  StyledInput,
  StyledContent,
} from "./footer-edit-form-styled";
import { debounce } from "lodash";
import FileInput from "../file-input";
import { imageResolution } from "./helpers";
interface FooterEditFormProps { 
  footerFormData: TFooterEditFormEntity;
  setFooterFormData: any
}

const FooterEditForm = ({ footerFormData, setFooterFormData }: FooterEditFormProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeFooter = useCallback(
    ( name: string, value: File | null ) => {

      if (isNil(value)) {
        setFooterFormData( (state: TFooterEditFormEntity) => ({
          ...state,
          [name]: value,
        }));
        return
      }

      const fr = new FileReader();
      fr.onload = () => {
        const img = document.createElement('img');
        const url = fr.result;
        img.src = String(url) || '';
        img.style.position = 'absolute';
        img.style.opacity = '0';
        document.body.appendChild(img);
      
        img.onload = () => {
          const { width, height } = img;
          const { maxWidth, maxHeight, minWidth, minHeight} = imageResolution[name]
          
          document.body.removeChild(img);

          if (width > maxWidth || height > maxHeight) {
            return enqueueSnackbar(`A resolução máxima permitida e de ${maxWidth}X${maxHeight}`, {
              variant: `error`,
              autoHideDuration: 2000 
            });
          } if(width < minWidth || height < minHeight) {
            return enqueueSnackbar(`A resolução mínima permitida e de ${minWidth}X${minHeight}`, {
              variant: `error`,
              autoHideDuration: 2000 
            });
          } else {
            setFooterFormData( (state: TFooterEditFormEntity) => ({
              ...state,
              [name]: value,
            }));
          }
        }
      }
      fr.readAsDataURL(value);
  }, [enqueueSnackbar, setFooterFormData]
  );

  const handleRemoveImage = () => {
    setFooterFormData((state: TFooterEditFormEntity) => ({...state, secondary_logo: null}));
  }

  const delayInMs = 500;
  const handleChangeColor = debounce(( name: string, value: string ) => {
    setFooterFormData( (state: TFooterEditFormEntity) => ({...state, [name]: value }));
  }, delayInMs);

  return (
    <StyledContainer>
        <StyledHeader>
          <StyledTitle>4. Rodapé</StyledTitle>
        </StyledHeader>

        <StyledContent>
          <FileInput 
            label="Logo principal*:"
            name="primary_logo"
            onChange={handleChangeFooter}
            value={footerFormData}
            titleTooltip={
              <>
                <p>Resolução Suportada</p>
                <p>Min: 140px x 100px</p>
                <p>Max: 2000px x 700px</p>
              </>
            }
          />
          <FileInput 
            label="Logo patrocinadores:"
            name="secondary_logo"
            onChange={handleChangeFooter}
            value={footerFormData}
            activeBottomClose
            fileOrFiles={footerFormData.secondary_logo}
            handleRemoveImage={handleRemoveImage}
            titleTooltip={
              <>
                <p>Resolução Suportada</p>
                <p>Min: 140px x 100px</p>
                <p>Max: 2000px x 700px</p>
              </>
            }
          />
          <StyledInputBox>
            <label htmlFor="background_color">Cor de Fundo*:</label>
            <StyledInput
              id="background_color"
              type='color'
              required
              name="background_color"
              value={footerFormData.background_color || '#000000'}
              onChange={(e) => handleChangeColor("background_color", e.target.value)}
              InputProps={{
                endAdornment: <p>{footerFormData.background_color || '#000000'}</p>,
              }}
            />
          </StyledInputBox>

          <StyledInputBox>
            <label htmlFor="text_color">Cor de texto*:</label>
            <StyledInput
              id="text_color"
              type='color'
              required
              name="text_color"
              value={footerFormData.text_color || '#000000'}
              onChange={(e) => handleChangeColor("text_color", e.target.value)}
              InputProps={{
                endAdornment: <p>{footerFormData.text_color || '#000000'}</p>,
              }}
            />
          </StyledInputBox>
        </StyledContent>
    </StyledContainer>
  );
}

export default FooterEditForm