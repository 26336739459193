import styled from "styled-components";
import { devices } from "../../../assets/styles/devices";

export const StyledSwitchContainer = styled.div`
  display: grid;

  @media ${devices.mobile} {
    width: 100%;
    grid-template-columns: 1fr;
  }

  @media ${devices.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const StyledSwitchOption = styled.div`
  background-color: ${(props) => props.theme.page.body};
  align-items: flex-start;
  padding-left: 1rem;

  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 0, 625rem;

  label {
    margin-bottom: 8px;
    font-size: 1rem;
    font-weight: normal;
    color: ${(props) => props.theme.colors.blackLight};
  }
`;
