import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { DatePickerContainer, DateRangePickerContainer } from './date-range-picker-styles';

type DateValue = Date | null;

type DateRangeValue = [DateValue, DateValue]


const CustomDatePicker: React.FC<any> = ({ onChange, fullWidth }) => {
    const [startDate, setStartDate] = useState<DateValue>(null);
    const [endDate, setEndDate] = useState<DateValue>(null);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const handleDateChange = (dates: DateRangeValue) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            onChange([start, end])
            setIsDatePickerOpen(false);
        }
    };

    const toggleDatePicker = () => {
        setIsDatePickerOpen((prevOpen) => !prevOpen);
    };

    const value = startDate && endDate ? `De ${format(startDate, 'dd/MM/yyyy')} Até ${format(endDate, 'dd/MM/yyyy')}` : ''

    return (
        <DatePickerContainer>
            <TextField
                label="Selecione o dia"
                value={value}
                onClick={toggleDatePicker}
                fullWidth={fullWidth}
                size="small"
                autoComplete='off'
                sx={{ backgroundColor: "white" }}
                InputProps={{
                    endAdornment: value ? <IconButton onClick={(e) => {
                        e.stopPropagation();
                        setStartDate(null)
                        setEndDate(null)
                        onChange([null, null])
                    }}>
                        <Close />
                    </IconButton> : null
                }}
            />
            {isDatePickerOpen && (
                <DateRangePickerContainer>
                    <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        locale={ptBR}
                        inline
                    />
                </DateRangePickerContainer>
            )}
        </DatePickerContainer>
    );
};

export default CustomDatePicker;
