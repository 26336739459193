/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { isNil } from "ramda";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { IRootState } from "../../shared/store-redux";

import ResultEventFilter from "./result-event-filter";
import ResultEventTable from "./result-event-table";
import TableRow from "./result-event-table/table-row";

import { statusDictionary } from "./result-event-table/helpers";

import Header from "../../shared/components/header";
import FormButton from "../../shared/components/form-button";
import ModalLead from "../../shared/components/modal-lead";

import getOneEvent from "../../shared/services/events/getOneEvent";
import getAllFromLeads from "../../shared/services/check-form/getAllFromLeads";

import {
  StyledButton,
  StyledContainer,
  StyledFilter,
  StyledTable,
} from "./result-event-styles";

import { TFilterFieldsState, TTableLeads } from "./types";

import LeadHistoryLogDialog from "../../shared/components/lead-history-log-dialog";
import { getFormattedTableData } from "./helpers";

const ResultEvent = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { saasUserToken } = useSelector(
    (state: IRootState) => state.authenticated
  );

  const [ofPeriod, setOfPeriod] = useState<Date | null>(null);
  const [toPeriod, setToPeriod] = useState<Date | null>(null);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading] = useState(false);

  const [tableData, setTableData] = useState<TTableLeads[]>([]);
  const [eventData, setEventData] = useState<any | null>(null);
  const [leadCount, setLeadCount] = useState(0);
  const [isModalLeadOpen, setIsModalLeadOpen] = useState(false);
  const [currentOpenLeadId, setCurrentOpenLeadId] = useState("");
  const [filterFields, setFilterFields] = useState<TFilterFieldsState>({});

  const [isLogDialogOpen, setIsLogDialogOpen] = useState(false);

  const handleChangeFilterField = useCallback(
    debounce(
      (fieldName: string, newValue: string) =>
        setFilterFields((prevState) => ({
          ...prevState,
          [fieldName]: newValue,
        })),
      500
    ),
    []
  );

  const getCurrentEvent = useCallback(async () => {
    if (isNil(eventId)) return;

    const getCurrentEventResponse = await getOneEvent({
      eventId: eventId,
      accessToken: saasUserToken,
    });

    setEventData(getCurrentEventResponse);
  }, [eventId]);

  useEffect(() => {
    getCurrentEvent();
  }, [getCurrentEvent]);

  const formattedData = useCallback(
    (date: Date | null) => (date ? new Date(date).toISOString() : ""),
    []
  );

  const getAllDataResponse = useCallback(async () => {
    if (isNil(eventData)) return;

    const leadsDataResponse = await getAllFromLeads({
      eventId,
      accessToken: saasUserToken,
      params: {
        start_date: formattedData(ofPeriod),
        end_date: formattedData(toPeriod),
        page,
        pageSize,
        ...filterFields,
        pdv_quantity: filterFields.pdv_quantity || undefined,
        company_field: filterFields.company_field || undefined,
      },
    });

    if (isNil(leadsDataResponse)) return;

    const [leads, leadsQuantity] = leadsDataResponse;

    const formattedTableData = getFormattedTableData(leads);

    setTableData(formattedTableData);
    setLeadCount(leadsQuantity);
  }, [
    saasUserToken,
    enqueueSnackbar,
    navigate,
    formattedData,
    eventData,
    filterFields,
    ofPeriod,
    toPeriod,
    page,
    pageSize,
  ]);

  useEffect(() => {
    getAllDataResponse();
  }, [getAllDataResponse]);

  const handleOpenDiagnosisModal = (leadId: string) => {
    setIsModalLeadOpen(true);
    setCurrentOpenLeadId(leadId);
  };

  const handleClipboardCopyLink = (leadId: string) => {
    const currentUrl = `${window.location.origin}/diagnosis/${leadId}`;
    navigator.clipboard.writeText(currentUrl).then(
      function () {
        enqueueSnackbar("Link copiado com sucesso!");
      },
      function (err) {
        enqueueSnackbar("Não foi possível copiar o texto: ", err);
      }
    );
  };

  const handleLog = useCallback((leadId: string) => {
    setCurrentOpenLeadId(leadId);
    setIsLogDialogOpen(true);
  }, []);

  const handleCloseDiagnosisModal = () => {
    setIsModalLeadOpen(false);
    setCurrentOpenLeadId("");
  };

  return (
    <StyledContainer>
      <Header />
      <StyledButton>
        <FormButton
          icon={<ArrowBackIosNewIcon sx={{ height: 14, width: 14 }} />}
          children="Voltar"
          variant="blue"
          uppercase
          onClick={() => navigate("/dashboard/event/list")}
        />
      </StyledButton>
      <StyledFilter>
        <ResultEventFilter
          ofPeriod={ofPeriod}
          setOfPeriod={setOfPeriod}
          toPeriod={toPeriod}
          setToPeriod={setToPeriod}
          handleChangeFilterValues={handleChangeFilterField}
        />
      </StyledFilter>
      <StyledTable>
        <ResultEventTable
          loading={isLoading}
          statusLabel={statusDictionary[eventData?.status]}
          status={eventData?.status}
          titleEvent={eventData?.name}
          visits={0}
          forms={leadCount}
          data={tableData}
          onDiagnostic={handleOpenDiagnosisModal}
          setPage={setPage}
          setPageSize={setPageSize}
          page={page}
          count={leadCount}
          customRowRender={(rowData) => (
            <TableRow
              key={rowData[0].value}
              data={rowData}
              onDiagnostic={handleOpenDiagnosisModal}
              onCopyLink={handleClipboardCopyLink}
              onLog={handleLog}
            />
          )}
        />
      </StyledTable>

      {isModalLeadOpen && (
        <ModalLead
          isOpen={isModalLeadOpen}
          onClose={() => handleCloseDiagnosisModal()}
          currentOpenLeadId={currentOpenLeadId}
        />
      )}
      <LeadHistoryLogDialog
        isOpen={isLogDialogOpen}
        onClose={() => setIsLogDialogOpen(false)}
        leadId={currentOpenLeadId}
      />
    </StyledContainer>
  );
};

export default ResultEvent;
