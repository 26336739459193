import { useCallback } from "react";

import { TWebhookDialogProps } from "./types";
import {
  StyledButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogText,
  StyledDialogTitle,
  StyledInputWrapper,
  StyledWebhookEventDialogHeader,
} from "./webhook-dialog-styles";
import { ReactComponent as  WebhookIcon } from "../../../assets/images/webhook.svg";
import IconButton from "../icon-button";

import WebhookInput from "./webhook-input";

const WebhookDialog = ({ isOpen, onClose, webhooks, setWebhooks } : TWebhookDialogProps) => {

  const handleWebhooksChange = useCallback((value: string, index: number) => {
    setWebhooks(prev => {
      const newWebhooks = [...prev.webhooks];
      newWebhooks[index] = value;
      return ({ ...prev, webhooks: newWebhooks });
    });
  }, [setWebhooks]);

  const handleRemoveWebhook = useCallback((index: number) => {
    setWebhooks(prev => {
      const newWebhooks = prev.webhooks.filter((_, i) => index !== i);
      return ({ ...prev, webhooks: newWebhooks });
    });
  }, [setWebhooks]);

  const handleAddNewField = useCallback(() => {
    setWebhooks(prev => {
      const newWebhooks = [...prev.webhooks, ''];
      return ({ ...prev, webhooks: newWebhooks });
    });
  }, [setWebhooks]);

  return (
    <StyledDialog open={isOpen} onClose={onClose} >
      <StyledWebhookEventDialogHeader>
        <StyledDialogTitle>
          <WebhookIcon />
          Adicionar Webhook
        </StyledDialogTitle>
        <IconButton
          onClick={onClose}
          isWhite
          iconSize={1.5}
        />
      </StyledWebhookEventDialogHeader>

      <StyledDialogContent>
        <StyledDialogText>
          A URL informada será adicionada via requisição POST e o corpo enviado comportará todos os campos do nosso formulário.
        </StyledDialogText>
        <StyledInputWrapper>
          <WebhookInput setValue={(value) => setWebhooks(prev => ({ ...prev, main: value }))} value={webhooks.main}/>
        </StyledInputWrapper>

        {webhooks.webhooks.map((webhook, index) => (
          <StyledInputWrapper key={index}>
            <WebhookInput
              setValue={(value) => handleWebhooksChange(value, index)}
              value={webhook}
            />
            <IconButton
              onClick={() => handleRemoveWebhook(index)}
              iconSize={1.5}
              iconType="Delete"
              title="Limpar"
            />
          </StyledInputWrapper>
        ))}
        <StyledButton onClick={handleAddNewField}>adicionar mais um campo</StyledButton>
      </StyledDialogContent>
    </StyledDialog>
  );
}

export default WebhookDialog;