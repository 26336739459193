import styled, { css } from "styled-components";
import { breakpoints } from "../../../assets/styles/devices";

export const StyledContainer = styled.div<{ borderColor: string; }>`
  border: 4px solid transparent;
  background: linear-gradient(#FFFFFF, #FFFFFF) padding-box, 
              linear-gradient(to bottom, ${props => props.borderColor}, transparent) border-box;
  border-radius: 3.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 1rem;
  margin: 2rem auto;
`;

export const StyledTitle = styled.h3<{ color: string }>`
  text-transform: uppercase;
  color: ${props => props.color};
  margin-bottom: 1rem;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    flex: 1;
    font-size: 1rem;
  }

  @media print {
    font-size: 1rem;
  }
`;

export const StyledContent = styled.div`
  display: flex;
`;

export const StyledRateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & + & {
    margin-left: 2rem;

    @media screen and (max-width: ${breakpoints.tabletLandscape}) {
      margin-left: 1rem;
    }
  }
`;

export const StyledRate = styled.p<{ type?: string; }>`
  color: ${props => props.theme.colors.white};
  width: 140px;
  height: 140px;
  display: grid;
  font-weight: bold;
  font-size: 1.8rem;
  place-items: center;
  background-color: ${props => props.theme.colors.green};
  border-radius: 50%;

  ${props => props.type === 'incorrect' && css`
    background-color: #FF9920;
  `}

  ${props => props.type === 'nonexistent' && css`
    background-color: #FF5C5C;
  `}

  @media screen and (max-width: ${breakpoints.desktop}) {
    width: 100px;
    height: 100px;
    font-size: 1.4rem;
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: 70px;
    height: 70px;
    font-size: 1rem;
  }

  @media print {
    width: 70px;
    height: 70px;
    font-size: 1rem;
  }
`;

export const StyledRateText = styled.p<{ isorange?: boolean; isred?: boolean; }>`
  font-weight: bold;
  margin-top: 1rem;
  color: ${props => props.theme.colors.green};

  ${props => props.isred && css`
    color: #FF5C5C;
  `}

  ${props => props.isorange && css`
    color: #FF9920;
  `}

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    font-size: 0.65rem;
  }
`;
