import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const AlexaIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox="0 0 502 500" {...props}>
            <g clip-path="url(#clip0_1287_25293)">
                <path d="M251.402 0C113.073 0 1.39551 112.78 1.39551 250.007C1.39551 387.234 113.073 500.014 251.402 500.014C389.732 500.014 501.409 387.234 501.409 250.007C501.409 112.78 389.718 0 251.402 0ZM212.199 496.859V447.296C212.199 429.676 201.407 413.97 185.044 407.017C123.795 381.021 80.9337 320.205 80.9337 250.007C80.9337 156.438 157.093 79.5242 251.416 79.5242C345.74 79.5242 421.899 156.438 421.899 250.007C421.899 360.414 336.497 464.803 212.213 496.859H212.199Z" fill="#00CAFF" />
            </g>
            <defs>
                <clipPath id="clip0_1287_25293">
                    <rect width="501" height="501" fill="white" transform="translate(0.662109)" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default AlexaIcon;