import axios from "axios";

type TDuplicateEventProps = {
  id: string;
  name: string;
  accessToken: string | null;
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  slug: string;
};

export default async function duplicateEvent({
  id,
  accessToken,
  setIsFetching,
  name,
  slug,
}: TDuplicateEventProps) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    if (setIsFetching) setIsFetching(true);

    await axios.post(`${process.env.REACT_APP_EVENT_API_URL}/clone/${id}`, { name, slug }, requestOptions);
  } catch (err: any) {
    return err;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
