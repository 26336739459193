import { TUrlImageProps } from "./types";
import { StyledUrlImage } from "./url-image-styles";

const UrlImage = ({
  url,
  className,
}:TUrlImageProps) => (
  <StyledUrlImage src={url} className={className} />
);

export default UrlImage;
