import { TextField, Select, MenuItem } from "@mui/material";
import styled from "styled-components";
import { devices } from "../../../../assets/styles/devices";

export const StyledContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grey};
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 1rem;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`;

export const StyledTitle = styled.div`
  font-size: 1.25rem;
  font-weight: Bold;
`;

export const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.page.body};
  padding: 1rem;
  
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${devices.widescreen} {
    grid-template-columns: repeat(3, 1fr);
  }
  
`;

export const StyledInputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  gap: 0,625rem;

  label { 
    margin-bottom: 8px;
    font-size: 1rem;
    font-weight: normal;
    color: ${(props) => props.theme.colors.blackLight}
  }
`;

export const StyledInput = styled(TextField)`
  background-color: ${(props) => props.theme.colors.white};
  
  & .MuiOutlinedInput-input {
    padding: 0.4rem;
  }
`;

export const StyledSelect = styled(Select)`
  background-color: ${(props) => props.theme.colors.white};

  & .MuiOutlinedInput-input {
    padding: 0.375rem 1rem;
  }
`;

export const StyledMenuItem = styled(MenuItem)``;

export const StyledUrl = styled.div`
  display: flex;
  align-items: center;
  margin-top: -0.9rem;

  p {
    font-size: 1rem;
    font-weight: bold;
  }

  & .MuiInputBase-input {
    padding: 0.25rem 1rem;
  }
`;