export const platformsOrder: any = {
  Google: 1,
  WhatsApp: 2,
  Waze: 3,
  Yelp: 4,
  Foursquare: 5,
  AppleMaps: 6,
  Facebook: 7,
  Instagram: 8,
};
