import axios from "axios";
import { store } from "../../store-redux";

export const findOneBatchCheck = async (id: string | undefined, {
  params,
  onSuccess,
  onError,
  onFinnaly,
}: any) => {
    const { saasUserToken } = store.getState().authenticated
    const result = await axios.get(
      `${process.env.REACT_APP_BATCH_CHECK_API_URL}/get-one/${id}`,
        {
            headers: {
                Authorization: `Bearer ${saasUserToken}`,
            },
            params,
        }
      ).then(({data}) => onSuccess(data)).catch(onError).finally(onFinnaly);

      return result;
};
