import axios from 'axios';
import { TGetAddressByZipCode, TGetAddressByZipCodeResponse } from './types';

const getAddressByZipCode = async ({
  zipcode,
  feedbackMessage,
}: TGetAddressByZipCode): Promise<TGetAddressByZipCodeResponse | null> => {
  try {
    const { data } = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

    if (data.erro && feedbackMessage) {
      feedbackMessage('CEP não encontrado', { variant: 'warning' });
    }

    return data;
  } catch (err) {
    return null;
  }
};

export default getAddressByZipCode;