import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const GoogleErIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox="0 0 500 500" {...props}>
            <path d="M172.416 302.099C87.1887 189.364 -0.311176 275.03 22.5297 353.822C79.9817 479.452 228.418 534.731 354.072 477.29C397.383 457.49 434.142 425.742 460.029 385.776C394.149 447.469 274.518 437.159 172.416 302.099Z" fill="#255FDB" />
            <path d="M248.608 224.471C131.392 46.7198 0.000216136 141.446 0.000216136 250.034C-0.0471975 285.997 7.70678 321.543 22.7274 354.219C7.13089 280.625 91.7047 223.874 170.455 329.508C284.801 482.889 397.017 455.905 459.801 386.117C479.964 355.17 492.987 320.125 497.926 283.522V284.005C470.966 367.626 351.988 381.232 248.608 224.471Z" fill="#4285F4" />
            <path d="M328.949 144.94C241.904 1.35893 131.534 14.2826 56.8185 91.2285C19.9882 135.949 -0.103242 192.104 0.000398969 250.034C7.81294 144.94 134.943 71.8004 250 250.034C352.273 408.67 479.688 367.342 497.812 284.46V283.664C499.399 272.376 500.073 260.977 499.829 249.58V238.218C455.54 269.917 397.301 257.675 328.949 144.94Z" fill="#91BFFF" />
            <path d="M329.545 170.504C409.261 298.633 477.983 265.855 500 238.446C499.11 219.293 496.012 200.307 490.767 181.865C453.608 182.632 444.943 176.44 408.381 120.769C352.5 35.1595 282.102 -22.6424 159.091 17.123C119.235 32.7523 84.0322 58.2992 56.8184 91.3422C141.335 13.5725 245.028 34.6766 329.545 170.504Z" fill="#C4E1FF" />
            <path d="M402.927 131.789C439.376 187.546 461.563 190.386 490.768 181.865C452.901 48.8842 314.382 -28.2271 181.377 9.63234C173.835 11.7789 166.399 14.2787 159.092 17.1231C268.978 -17.5297 346.904 46.1517 402.927 131.789Z" fill="#F5F5F5" />
            <path d="M250 499.988C388.071 499.988 500 388.08 500 250.034C500 111.989 388.071 0.0806885 250 0.0806885C111.929 0.0806885 0 111.989 0 250.034C0 388.08 111.929 499.988 250 499.988Z" fill="url(#paint0_radial_1288_25343)" fill-opacity="0.1" />
            <path d="M250 2.9212C387.517 2.9212 499.218 113.963 500 251.455V250.034C500 111.989 388.071 0.0808105 250 0.0808105C111.929 0.0808105 0 111.989 0 250.034V251.455C0.781154 113.963 112.483 2.9212 250 2.9212Z" fill="white" fill-opacity="0.2" />
            <path d="M250 497.148C112.483 497.148 0.781265 386.106 0 248.614V250.034C0 388.08 111.929 499.988 250 499.988C388.071 499.988 500 388.08 500 250.034V248.614C499.218 386.106 387.517 497.148 250 497.148Z" fill="#1A237E" fill-opacity="0.2" />
            <defs>
                <radialGradient id="paint0_radial_1288_25343" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(75.5099 75.5794) scale(496.704 496.612)">
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </radialGradient>
            </defs>
        </SvgIcon>
    )
}

export default GoogleErIcon;