import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

import {
  StyledCompanyAddress,
  StyledCompanyInfo,
  StyledCompanyName,
  StyledCompanyNonRelevant,
  StyledCompanyNotFound,
  StyledDirectoryLogo,
  StyledServiceNotAvailable,
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
  StyledTableWrapper,
} from "./diagnosis-table-styles";

import LocalStatusInfo from "../local-status-info";
import { getPlatformLogo } from "../../../assets/images/svg";
import { TDiagnosisTableProps } from "./types";
import { isEmpty } from "ramda";
import { Help } from "@mui/icons-material";

const platformsWithPhoneUnavailable = ["Facebook", "Instagram"];

const DiagnosisTable = ({ isUserView, check }: TDiagnosisTableProps) => {
  return (
    <StyledTableWrapper isUserView={isUserView}>
      <TableContainer elevation={0} component={Paper}>
        <Table sx={{ minWidth: 800 }} aria-label="customized table">
          <StyledTableHead>
            <TableRow>
              <TableCell>Diretório</TableCell>
              <TableCell>Empresa</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Endereço</TableCell>
              <TableCell>Telefone</TableCell>
            </TableRow>
          </StyledTableHead>

          <TableBody>
            {isEmpty(check) && <></>}
            {!isEmpty(check) &&
              check?.map((directoryService) => (
                <StyledTableRow key={directoryService.directory}>
                  <StyledTableCell>
                    <StyledDirectoryLogo>
                      {getPlatformLogo({
                        platform: directoryService.directory,
                      })}
                      {directoryService.directory}
                    </StyledDirectoryLogo>
                  </StyledTableCell>

                  <StyledTableCell>
                    <StyledCompanyInfo>
                      {directoryService.not_available ? (
                        <StyledServiceNotAvailable>
                          Indisponível
                        </StyledServiceNotAvailable>
                      ) : !directoryService.address &&
                        !directoryService.company ? (
                        <StyledCompanyNotFound>
                          Não encontrado
                        </StyledCompanyNotFound>
                      ) : directoryService.address === "irrelevant" ? (
                        <StyledCompanyNonRelevant>
                          Local com Baixa Relevância
                          <Tooltip
                            title="Devido a uma limitação da plataforma, locais que não possuem avaliações no Yelp são considerados de 'baixa relevância', podendo interferir nos resultados da nossa pesquisa"
                            arrow
                            placement="top"
                          >
                            <Help />
                          </Tooltip>
                        </StyledCompanyNonRelevant>
                      ) : (
                        <>
                          <StyledCompanyName>
                            {directoryService.company}
                          </StyledCompanyName>

                          <StyledCompanyAddress>
                            {directoryService.address ? (
                              directoryService.address
                            ) : (
                              <StyledServiceNotAvailable>
                                Sem Endereço
                              </StyledServiceNotAvailable>
                            )}
                          </StyledCompanyAddress>
                        </>
                      )}
                    </StyledCompanyInfo>
                  </StyledTableCell>

                  <StyledTableCell>
                    {!directoryService.not_available &&
                      directoryService.company !== "irrelevant" && (
                        <LocalStatusInfo
                          status={directoryService.name_status}
                        />
                      )}
                  </StyledTableCell>

                  <StyledTableCell>
                    {!directoryService.not_available &&
                      directoryService.address !== "irrelevant" && (
                        <LocalStatusInfo
                          status={directoryService.address_status}
                        />
                      )}
                  </StyledTableCell>

                  <StyledTableCell>
                    {!directoryService.not_available &&
                      directoryService.phone !== "irrelevant" && (
                        <LocalStatusInfo
                          status={
                            platformsWithPhoneUnavailable.includes(
                              directoryService.directory
                            )
                              ? "unavailable"
                              : directoryService.phone_number_status
                          }
                        />
                      )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledTableWrapper>
  );
};

export default DiagnosisTable;
