import styled from "styled-components";
import HelpIcon from '@mui/icons-material/Help';

export const StyledInputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  gap: 0,625rem;

  label { 
    padding: 3px;
    font-size: 1rem;
    font-weight: normal;
    color: ${(props) => props.theme.colors.blackLight}
  }

  p {
    font-size: 0.8rem;
    font-weight: normal;
    color: ${(props) => props.theme.colors.blackLight}
  }

  & .drag_styled {
    min-width: 15rem;
    max-width: 100%;
  }
`;

export const StyledLabelGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

export const IconTooltip = styled(HelpIcon)`
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.2rem !important;
`;

export const StyledInputLabel = styled.div<{ hasFiles: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  border: dashed 2px ${(props) => props.hasFiles ? props.theme.colors.green : props.theme.colors.primary};
  padding: 8px 16px 8px 8px;
  border-radius: 5px;
  cursor: pointer;
  flex-grow: 0;

  svg {
    margin-right: 1rem;
    color: ${(props) => props.hasFiles ? props.theme.colors.green : props.theme.colors.primary}
  }
`

export const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  & > span {
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.blackLight};
  }

  .file-types {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 6rem;
  }
`

export const Description = styled.span`
  font-size: 0.875 rem;
  color: ${(props) => props.theme.colors.blackLight};
  span {
    text-decoration: underline;
  }
`;

export const StyledFileUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background: none;
    color: ${(props) => props.theme.colors.blackLight};
	  border: none;
	  padding: 0;
	  cursor: pointer;
  }

  svg {
    color: ${(props) => props.theme.colors.primary}
  }
`;

export const StyledButtonAction = styled.div`
  display: flex;
`;