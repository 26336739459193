export const columns = [
  {
   name: "Id",
   options: {
    display: false,
   }
  },
  {
    name: "Status",
    options: {
     display: true,
    }
   },
   {
    name: "Nome do Evento",
    options: {
     display: true,
    }
   },
   {
    name: "URL do evento",
    options: {
     display: true,
    }
   },
   {
    name: "Data de criação",
    options: {
     display: true,
    }
   },
   {
    name: "Ações",
    options: {
     display: true,
    }
   },
 ];

export const tableOptions = {
  search: false,
  selectableRowsHideCheckboxes: true,
  draggableColumns: {
    enabled: false,
  },
  textLabels: {
    body: {
      noMatch: 'Nenhum resultado encontrado',
    },
    pagination: {
      next: 'Próxima página',
      previous: 'Página anterior',
      rowsPerPage: 'Linhas por página',
      displayRows: 'de',
    },
    toolbar: {
      search: 'Pesquisar',
      downloadCsv: 'Baixar como CSV',
      print: 'Imprimir',
      viewColumns: 'Visualizar colunas',
      filterTable: 'Filtrar',
    },
    filter: {
      all: 'Todos',
      title: 'Título',
      reset: 'Resetar',
    },
    viewColumns: {
      title: 'Mostrar Colunas',
      titleAria: 'Mostrar/esconder colunas da tabela',
    },
    selectedRows: {
      text: 'linha(s) selecionada(s)',
      delete: 'Apagar',
      deleteAria: 'Apagar Linhas Selecionadas',
    },
  },
  fixedHeader: true,
  download: false,
  filter: false,
  rowsPerPageOptions: [10, 50, 100],
  pagination: true,
  print: false,
  viewColumns: false,
  sort: false,
  elevation: 1,
};
