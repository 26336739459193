import styled from "styled-components";

export const StyledDiagnosisStatus = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  color: ${(props) => props.theme.colors.white};
  font-weight: 700;
  font-size: 1.18rem;
  gap: 0 2rem;

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
