import styled from "styled-components";
import { breakpoints } from "../../assets/styles/devices";

export const StyledContainer = styled.div`
  width: 100%;
`;

export const StyledTermsOfServiceContainer = styled.div<{ marginTop: boolean }>`
  margin-top: ${(props) => (props.marginTop ? "2vh" : "0")};
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 0.75rem;
    display: inline;
  }

  a {
    color: ${(props) => props.theme.colors.primary};
    font-size: 0.75rem;
  }
`;

export const StyledFormContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  animation: slide forwards 0.75s;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    height: 100%;
    justify-content: flex-end;
    margin-bottom: 7vh;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    height: 100%;
    justify-content: flex-end;
    margin-bottom: 5vh;
  }

  @keyframes slide {
    from {
      opacity: 0;
      transform: translate(20vw);
    }
    to {
      opacity: 1;
      transform: translate(0);
    }
  }
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const StyledMainSection = styled.main<{
  background?: string;
  textColor: string;
}>`
  display: flex;
  margin-left: auto;
  flex-direction: column;
  color: ${(props) => props.textColor};
  width: 65%;
  height: 100vh;
  min-height: 720px;

  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  overflow: hidden;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    background: none;
    width: 100%;
  }
`;

export const StyledCover = styled.div<{ background?: string }>`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    background-color: transparent;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      min-height: 720px;
      background-image: url(${(props) => props.background});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      filter: brightness(1.5);
      z-index: -1;
    }
  }
`;

export const StyledCenterImage = styled.img`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 0;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    display: initial;
    width: 550px;
    transform: none;
    top: 4rem;

    @media screen and (max-height: ${breakpoints.tabletLandscape}) {
      width: 400px;
      top: 2rem;
    }
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    width: 450px;

    @media screen and (max-height: ${breakpoints.tabletLandscape}) {
      width: 400px;
    }
  }
`;

export const StyledImageLayer = styled.img`
  display: none;
  top: 0;
  position: absolute;
  width: 100%;
  height: 90%;
  z-index: 1;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    display: initial;
    height: auto;
  }
`;

export const StyledForm = styled.form<{ isAddress?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 2;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    height: 100%;
    justify-content: flex-end;
    margin-bottom: ${(props) => (props.isAddress ? "2.5vh" : "5vh")};
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    height: 100%;
    justify-content: flex-end;
    margin-bottom: ${(props) => (props.isAddress ? "0" : "4vh")};
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  width: 40%;
  margin-top: 2rem;
  gap: 2rem;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledLogosContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 1rem;
  top: 7vh;
  left: 4vw;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    inset: initial;
    top: 6rem;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    top: 5rem;
  }
`;

export const StyledButton = styled.button<{ isGrey?: boolean }>`
  background: ${(props) =>
    props.isGrey
      ? props.theme.colors.grey
      : "linear-gradient(90deg, #5CC99D 8.17%, #6DE7B8 95.91%)"};
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  padding: 7.5px ${(props) => (props.isGrey ? "10px" : "30px")};
  font-size: 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-weight: bold;
`;

export const StyledLoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
`;
