import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { ILeadEntity } from "../../shared/services/check-form/types";
import { TTableLeads } from "./types";

export const notVisibleFieldsOnTable = ['id'];

export const tableFields = [
  'id',
  'client_name',
  'company_name',
  'company_field',
  'pdv_quantity',
  'client_phone_number',
  'client_email',
  'city',
  'state',
  'created_at',
  'results',
];

export const getFormattedTableData = (leads:ILeadEntity[]) => leads.map(
  (lead): TTableLeads => tableFields.map((columnRef) => {
    const leadField = lead[columnRef];
    let value = '';

    const visible = !notVisibleFieldsOnTable.includes(columnRef);

    if (typeof leadField === 'string') {
      value = columnRef === 'created_at'
        ? format(new Date(lead.created_at), "dd/MM/yyyy 'ás' hh:mm", {locale: ptBR})
        : leadField;
    }

    return ({
      columnRef,
      value,
      visible,
    })
}));
