import styled from "styled-components";

export const StyledCardContainer = styled.article`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  font-size: 1rem;
  border-radius: 14px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  width: 23.125rem;
  min-height: 23.125rem;

  font-weight: 500;

  padding: 1.332rem 1.776rem;
`;

export const StyledCardHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 6.438rem;
    height: 2.609rem;
  }
`;

export const StyledCardText = styled.p`
  text-align: start;
  color: ${(props) => props.theme.colors.darkGrey};
`;

export const StyledCardFooter = styled.footer`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  img {
    width: 2.886rem;
    height: 2.886rem;
  }
`;

export const StyledAuthor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  span {
    color: ${(props) => props.theme.colors.primaryGrey};
    font-size: 0.775rem;
    text-align: start;
  }

  strong {
    color: ${(props) => props.theme.colors.darkGrey};
  }
`;
