import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const TwitterIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox="0 0 476 476" {...props}>
            <path d="M237.917 0C174.817 0 114.302 25.0661 69.6842 69.6842C25.0661 114.302 0 174.817 0 237.917C0 301.016 25.0661 361.531 69.6842 406.149C114.302 450.767 174.817 475.833 237.917 475.833C301.016 475.833 361.531 450.767 406.149 406.149C450.767 361.531 475.833 301.016 475.833 237.917C475.833 174.817 450.767 114.302 406.149 69.6842C361.531 25.0661 301.016 0 237.917 0Z" fill="#03A9F4" />
            <path d="M380.666 156.073C370.174 160.724 356.886 165.09 344.979 166.542C357.088 159.356 376.3 144.391 380.666 130.854C369.353 137.504 348.892 144.606 335.545 147.175C324.886 135.874 309.684 130.854 292.839 130.854C260.482 130.854 237.916 158.274 237.916 190.333V214.125C190.333 214.125 143.939 177.878 115.068 142.75C109.988 151.327 107.133 161.367 107.133 171.978C107.133 193.616 127.011 215.576 142.749 226.021C133.149 225.723 114.973 218.395 107.062 214.125C107.062 214.315 107.062 214.553 107.062 214.803C107.062 242.96 126.821 262.077 153.598 267.406C148.709 268.751 142.749 273.604 119.814 273.604C127.261 296.622 164.697 308.792 190.333 309.292C170.276 324.839 134.517 333.083 107.062 333.083C102.315 333.083 99.7459 333.345 95.166 332.81C121.075 349.226 157.262 356.875 190.333 356.875C298.073 356.875 356.874 274.579 356.874 197.828C356.874 195.306 356.791 186.86 356.66 184.385C368.175 176.272 372.839 167.779 380.666 156.073Z" fill="white" />
        </SvgIcon>
    )
}

export default TwitterIcon;