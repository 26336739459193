import { Box, Stack, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import StackCard from "../../../../shared/components/stack-card/stack-card";
import SuccessIcon from "../../../../shared/components/icons/success";
import StackApps from "../../../../shared/components/stack-app/stack-apps";
import { AppsByPlatform } from "../../../../shared/components/locale-card/stacks";
import {
  PlatformTypes,
  ResultsType,
  plataformsIcons,
} from "../../../../shared/components/locale-card/locale-card";
import ErrorIcon from "../../../../shared/components/icons/error";
import { PageWrapper } from "../batch-diagnosis-styles";

type TabPlataformProps = {
  data: any[];
  plataforma: PlatformTypes;
  percentage: number;
  description: string;
};

const TabPlatform = forwardRef<HTMLDivElement, TabPlataformProps>(
  ({ data, plataforma, percentage, description }, ref) => {
    const appData = data.map(({ results, ...rest }: any) => {
      const platform = results.find(({ platform }: any) => {
        return platform === plataforma;
      });
      const correctCounts = results.reduce(
        (count: number, item: ResultsType) => {
          const { name_status, address_status, phone_status } = item;
          let increment = 0;

          if (name_status === "correct") {
            increment++;
          }

          if (address_status === "correct") {
            increment++;
          }

          if (phone_status === "correct") {
            increment++;
          }

          return count + increment;
        },
        0
      );

      const isHighRelevance = correctCounts / 9 > 0.8;
      return {
        ...rest,
        result: platform,
        apps: AppsByPlatform[plataforma],
        isHighRelevance,
      };
    });

    const isHighRelevance = percentage > 80;

    return (
      <PageWrapper sx={{ pl: [0, 8] }}>
        <Box position="relative" p={[2, 0]}>
          <Stack maxWidth="600px" gap={2} mt={[0, 8]}>
            <Typography fontSize="20px" fontWeight={500} color="#65FFC4">
              Multiplataformas
            </Typography>
            <Typography fontWeight={700} fontSize="48px" color="white">
              Pack {plataforma}
            </Typography>
            <Typography fontWeight={500} fontSize="20px" color="white">
              Esse stack integra e otimiza {AppsByPlatform[plataforma].length}{" "}
              mapas e listas na internet, abaixo segue o relatório da sua
              empresa nessas plataformas
            </Typography>
          </Stack>
          <Box maxWidth="900px" mt={4}>
            <StackCard
              description={description}
              icon={isHighRelevance ? SuccessIcon : ErrorIcon}
              img={plataformsIcons[plataforma]}
              percentage={percentage}
              name="Desempenho geral"
              relevance={
                isHighRelevance ? "Alta relevancia" : "Baixa relevancia"
              }
              stack=""
            />
          </Box>
          <Stack gap={2} ref={ref}>
            <Typography fontSize="40px" color="white">
              Desempenho por local
            </Typography>
            {appData.map((props) => (
              <StackApps {...props} />
            ))}
          </Stack>
        </Box>
      </PageWrapper>
    );
  }
);

export default TabPlatform;
