import axios from "axios";

type TGetAllEvents = {
  accessToken: string | null;
  eventId: string
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  setTokenLikeExpired?: () => void;
}

export default async function getOneEvent({ 
  accessToken,
  eventId,
  setTokenLikeExpired,
  setIsFetching,
 }: TGetAllEvents) {
  try {
    if(setIsFetching) setIsFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_EVENT_API_URL}/get-one/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return err;
  } finally {
    if (setIsFetching) setIsFetching(false)
  }
}