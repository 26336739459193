import { MenuItem, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  StyledDate,
  StyledFilter,
  StyledGroup,
  StyledLabel,
  StyledInput,
  StyledSelect,
} from "./result-event-filter-styles";
import { ptBR } from "date-fns/locale";
import { TResultEventFilterProps } from "./types";
import {
  companyFieldOptions,
  filterFields,
  pdvQuantityOptions,
} from "./helpers";
import { useCallback } from "react";

const ResultEventFilter = ({
  ofPeriod,
  setOfPeriod,
  toPeriod,
  setToPeriod,
  handleChangeFilterValues,
}: TResultEventFilterProps) => {
  const getFilterField = useCallback(
    ([fieldName, fieldLabel]: string[]) => {
      const selectFields = ["company_field", "pdv_quantity"];
      if (selectFields.includes(fieldName)) {
        const options =
          fieldName === "company_field"
            ? companyFieldOptions
            : pdvQuantityOptions;
        return (
          <StyledGroup key={fieldName}>
            <StyledLabel>{fieldLabel}</StyledLabel>
            <StyledSelect
              onChange={({ target }) =>
                handleChangeFilterValues(fieldName, String(target.value))
              }
            >
              {options?.map((option) => (
                <MenuItem value={option.value}>{option.text}</MenuItem>
              ))}
            </StyledSelect>
          </StyledGroup>
        );
      }

      return (
        <StyledGroup key={fieldName}>
          <StyledLabel>{fieldLabel}</StyledLabel>
          <StyledInput
            onChange={(e) =>
              handleChangeFilterValues(fieldName, e.target.value)
            }
            placeholder="Escreva algo aqui"
          />
        </StyledGroup>
      );
    },
    [handleChangeFilterValues]
  );

  return (
    <StyledFilter>
      {filterFields.map(getFilterField)}
      <StyledGroup>
        <StyledLabel>De: </StyledLabel>
        <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
          <StyledDate
            value={ofPeriod}
            maxDate={toPeriod}
            onChange={(date: any) => {
              setOfPeriod(date);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "Data",
                }}
                sx={{
                  ".MuiInputBase-input": { padding: "0.44rem" },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </StyledGroup>
      <StyledGroup>
        <StyledLabel>Até: </StyledLabel>
        <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
          <StyledDate
            value={toPeriod}
            minDate={ofPeriod}
            onChange={(date: any) => {
              setToPeriod(date);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "Data",
                }}
                sx={{
                  ".MuiInputBase-input": { padding: "0.44rem" },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </StyledGroup>
    </StyledFilter>
  );
};

export default ResultEventFilter;
