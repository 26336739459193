import styled from "styled-components";
import { devices } from "../../../assets/styles/devices";

export const StyledTableMobile = styled.table`
  display: flex;
  margin-top: 1rem;

  @media ${devices.tabletLandscape} {
    display: none;
  }

  page-break-inside: auto;
`;

export const StyledTableBody = styled.tbody`
  width: 100%;

  @media print {
    padding-top: 2rem;
  }
`;

export const StyledTableRow = styled.tr`
  border: 1px solid ${props => props.theme.colors.primary};
  display: block;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);

  page-break-inside: avoid;
  page-break-after: auto;
`;

export const StyledRowContent = styled.td`
  display: flex;
  align-items: center;
`

export const StyledDirectoryLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  gap: 0.5rem;
  font-weight: 600;
  font-size: 0.8rem;
  color: ${props => props.theme.colors.primary};
  width: 30%;
  height: 130px;
  text-align: center;

   & svg {
    width: 2rem;
    height: 2rem;
   }
`;

export const StyledHeader = styled.div`
  text-align: center;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  width: 100%;
  padding: 0.5rem 0;

  & + & {
    border-left: 1px solid ${props => props.theme.colors.white};
  }
`;

export const StyledEmptyCell = styled.div`
  width: 100%;
  height: 50px;
  
  & + & {
    border-left: 1px solid ${props => props.theme.colors.primary};
  }
`;