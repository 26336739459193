import { isNil } from "ramda";
import {
  StyledFormData,
  StyledFormLabel,
  StyledFormRow,
  StyledFormValue,
  StyledInfoFormHeader,
  StyledInformationFormWrapper,
} from "./information-table-styles";
import { TInformationTableProps } from "./types";

const InformationTable = ({ tableInformation }: TInformationTableProps) => {
  return (
    <StyledInformationFormWrapper>
      <StyledInfoFormHeader>
        <span>Informações do formulário</span>
      </StyledInfoFormHeader>
      <StyledFormData>
        {tableInformation.map(({ value, label }) => (
          <StyledFormRow key={label}>
            <StyledFormLabel>{label}</StyledFormLabel>
            <StyledFormValue>
              {isNil(value) ? "Não se Aplica" : value}
            </StyledFormValue>
          </StyledFormRow>
        ))}
      </StyledFormData>
    </StyledInformationFormWrapper>
  );
};

export default InformationTable;
