import axios from "axios";
import { Dispatch, SetStateAction } from "react";

type TCreateEvents = {
  name: string;
  slug: string;
  request_information: boolean;
  request_pdv_quantity: boolean;
  request_company_field: boolean;
  request_position_in_company: boolean;
  request_terms_of_service: boolean;
  show_landing_page: boolean;
  status: string;
  footer: string;
  diagnosis: string;
  form: string;
  webhooks: string[];
  isFetching?: Dispatch<SetStateAction<boolean>>;
  accessToken: string | null;
};

export const createNewEvents = async ({
  name,
  slug,
  request_information,
  request_company_field,
  request_pdv_quantity,
  request_position_in_company,
  request_terms_of_service,
  show_landing_page,
  status,
  footer,
  diagnosis,
  form,
  webhooks,
  isFetching,
  accessToken,
}: TCreateEvents) => {
  try {
    let body: any = {
      name,
      slug,
      request_information,
      request_company_field,
      request_pdv_quantity,
      request_position_in_company,
      request_terms_of_service,
      show_landing_page,
      status,
      footer,
      diagnosis,
      form,
      webhooks,
    };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_EVENT_API_URL}/create`,
      body,
      { headers }
    );

    return data;
  } catch (err) {
    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
