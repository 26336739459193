import { Paper } from "@mui/material";
import { darken, lighten } from "polished";
import styled, { css } from "styled-components";
import { breakpoints } from "../../../assets/styles/devices";

export const StyledContainer = styled(Paper)`
  background-color: ${props => darken(0.05, props.theme.colors.white)} !important;
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    flex: 1;
    padding: 1rem;
  }

  @media print {
    display: none;
  }
`;

export const StyledOptimizationInfo = styled.div`
  display: flex;
  align-items: center;

  & .MuiSvgIcon-root {
    margin-right: 1rem;
    color: ${props => props.theme.colors.green};
  }
`;

export const StyledTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${props => lighten(0.2, props.theme.colors.black)};
  text-align: center;
  font-family: 'Poppins';
  margin-bottom: 2rem;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    font-size: 1rem;
  }
`;

export const StyledText = styled.p`
  font-size: 1rem;
  font-weight: bold;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    font-size: 0.75rem;
  }
`;

export const StyledHighlighter = styled.span<{
  isred?: boolean;
  isorange?: boolean;
}>`
  color: ${props => props.theme.colors.green};

  ${props => props.isorange && css`
    color: ${props.theme.colors.tertiary};
  `}

  ${props => props.isred && css`
    color: ${props.theme.colors.red};
  `}
`;

export const StyledButton = styled.a<{
  isblue?: boolean; 
  color?: string;
  textColor?: string;
}>`
  text-align: center;
  text-decoration: none;
  background: linear-gradient(90deg, #5DCA9E 8.17%, #6EE8B9 95.91%);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 1rem;
  color: ${props => props.textColor || props.theme.colors.white};
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
  transition: 0.2s;

  ${props => props.isblue && css`
    background: linear-gradient(90deg, #3B83F5 8.17%, #67A9FF 95.91%);
  `}

  ${ props => props.color && css`
    background: linear-gradient(90deg, ${darken(0.215, props.color)} 8.17%, ${props.color} 95.91%);
  `}

  &:hover {
    filter: brightness(0.95);
  }

  &:active {
    filter: brightness(0.9);
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    font-size: 0.7rem;
  }
`;
