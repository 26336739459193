import { Stack, Typography } from '@mui/material';
import { Container, InnerCircle, PercentageContainer } from './circles-percentage-styles';
import CirclesPercentageAnimated from '../circle-percentage-animated/circle-percentage-animated';



export const CircleComponent = ({ percentage }: { percentage: string }) => {
  return (
    <Container>
      <InnerCircle>
        <Typography fontSize="46px" fontWeight={700} color="#ffffff">
          {percentage}
        </Typography>
      </InnerCircle>
    </Container>
  )
}

export type Percentages = {
  correct: number;
  incorrect: number;
  nonexistent: number;
}

const CirclesPercentage: React.FC<Percentages> = ({ correct, incorrect, nonexistent }) => {
  return (
    <PercentageContainer direction={['column', 'row']} gap={4}>
      <Stack alignItems="center" gap={0}>
        <CirclesPercentageAnimated percentage={correct} color="#14CC33" />
        <Typography fontSize="18px" fontWeight={500} color="#ffffff">Dados corretos</Typography>
      </Stack>
      <Stack alignItems="center" gap={0}>
        <CirclesPercentageAnimated percentage={nonexistent} color="#FF6363" />
        <Typography fontSize="18px" fontWeight={500} color="#ffffff">Dados inexistentes</Typography>
      </Stack>
      <Stack alignItems="center" gap={0}>
        <CirclesPercentageAnimated percentage={incorrect} color="#FFB219" />
        <Typography fontSize="18px" fontWeight={500} color="#ffffff">Dados incorretos</Typography>
      </Stack>
    </PercentageContainer >
  );
};

export default CirclesPercentage;
