import Loading from "../loading";
import { StyledFormButton } from "./form-button-styles";
import { TFormButtonProps } from "./types";

const FormButton = ({
  children,
  icon,
  variant,
  onClick,
  uppercase,
  type,
  isLoading,
}: TFormButtonProps) => {
  return (
    <StyledFormButton 
      onClick={onClick}
      variant={isLoading ? 'grey' : variant} 
      type={type}
      disabled={isLoading}
    >
     { isLoading ?
        <Loading primary style={{ width: 20, height: 20 }}/> :
        <>
          { icon }
          <span>
            { uppercase ? children?.toLocaleString().toUpperCase() : children }
          </span>
        </>
      }
    </StyledFormButton>
  )
}

export default FormButton;
