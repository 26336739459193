import axios from "axios";
import { store } from "../../store-redux";

export const findAllBatchCheck = async ({
  params,
  onSuccess,
  onError,
  onFinnaly = () => {},
  isLoading = () => {}
}: any) => {
    const { saasUserToken } = store.getState().authenticated;
    isLoading(true)
    const result = await axios.get(
        `${process.env.REACT_APP_BATCH_CHECK_API_URL}/get-all`,
        {
            headers: {
                Authorization: `Bearer ${saasUserToken}`
            },
            params,
        }
      ).then(({data}) => onSuccess(data)).catch(onError).finally(() => {
        isLoading(false)
        onFinnaly();
      });

      return result;
};
