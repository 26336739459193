
import { useSnackbar } from "notistack";
import { isNil } from "ramda";
import { useState } from "react";
import { useSelector } from "react-redux";
import deleteEvent from "../../services/events/delete-event";
import { IRootState } from "../../store-redux";
import IconButton from "../icon-button";
import {
  StyledButton,
  StyledButtonsContainer,
  StyledDeleteEventDialogHeader,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledEventDelete,
  StyledEventInfo
} from "./delete-event-dialog-styles";
import { TDeleteEventDialogProps } from "./types";

const DeleteEventDialog = ({ isOpen, onClose, eventToDelete, tableData, setTableData }: TDeleteEventDialogProps) => {

  const [isDeleting, setIsDeleting] = useState(false);
  const { saasUserToken } = useSelector((state: IRootState) => state.authenticated)
  const { enqueueSnackbar } = useSnackbar();

  async function handleDelete() {
    const errorResponse = await deleteEvent({
      id: eventToDelete.id,
      accessToken: saasUserToken,
      setIsFetching: setIsDeleting
    });

    if (isNil(errorResponse)) {
      enqueueSnackbar('Evento deletado com sucesso!.', {
        variant: `success`,
        autoHideDuration: 1500 
      });
      const updatedEvent = tableData.filter( event => event.id !== eventToDelete.id)
      setTableData(updatedEvent);
      return onClose();
    }

    if (errorResponse.response.data.statusCode === 404)
      return enqueueSnackbar('Evento não encontrado.', {
        variant: `error`,
        autoHideDuration: 1500 
      });

    if (errorResponse.response.data.statusCode === 400) {
      const { data } = errorResponse.response;

      if (data.message === "Can't delete default event") {
        return enqueueSnackbar('Não é possível remover o evento padrão.', {
          variant: `warning`,
          autoHideDuration: 1500 
        });
      }

      return enqueueSnackbar('Formato de ID inválido.', {
        variant: `error`,
        autoHideDuration: 1500 
      });
    }

    return enqueueSnackbar('Erro interno do servidor.', {
      variant: `error`,
      autoHideDuration: 1500 
    });
  }

  return (
    <StyledDialog open={isOpen}>
      <StyledDeleteEventDialogHeader>
        <StyledDialogTitle>Excluir Evento</StyledDialogTitle>
        <IconButton
          onClick={onClose}
          isWhite
          iconSize={1.5} />
      </StyledDeleteEventDialogHeader>
      <StyledDialogContent>
        <StyledEventInfo>
          <span>Você está prestes a excluir o evento:</span>
          <h2>{ eventToDelete.name }</h2>
        </StyledEventInfo>
        <hr />
        <StyledEventDelete>
          <span>Após executada, a ação não poderá ser desfeita. Deseja continuar?</span>
          <StyledButtonsContainer>
            <StyledButton
              onClick={onClose}
              disabled={isDeleting}
              disabledStyle={isDeleting}
              isgrey>
                CANCELAR
            </StyledButton>
            <StyledButton
              onClick={handleDelete}
              disabled={isDeleting}
              disabledStyle={isDeleting}>
                EXCLUIR EVENTO
            </StyledButton>
          </StyledButtonsContainer>
        </StyledEventDelete>
      </StyledDialogContent>
    </StyledDialog>
  )
}

export default DeleteEventDialog;