import { Dialog, TextareaAutosize } from "@mui/material";
import { darken, lighten } from "polished";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
  padding: 1rem;

  & .MuiPaper-root {
    width: 100%;
    border-radius: 5px;
    margin: 0;
  }
`;

export const StyledJSCodeDialogHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid ${(props) => lighten(0.9, props.theme.colors.black)};
  font-weight: 600;
  background: ${(props) => props.theme.colors.primary};

  button {
    margin-left: 0.8rem;
  }
`;

export const StyledDialogTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  font-family: Poppins;
  font-weight: normal;
  color: ${(props) => props.theme.colors.white};
`;

export const StyledDialogContent = styled.div`
  text-align: center;
  font-size: 0.8rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  max-height: 40rem;

  hr {
    border: none;
    outline: 0.001rem solid ${(props) => props.theme.colors.grey};
    width: 100%;
  }
`;

export const StyledTextArea = styled(TextareaAutosize)<{ isError: boolean }>`
  width: 100%;
  border: 1.5px solid
    ${(props) =>
      props.isError ? props.theme.colors.danger : props.theme.colors.grey};
  color: ${(props) =>
    props.isError ? props.theme.colors.danger : props.theme.colors.black};
  border-radius: 5px;
  padding: 1rem;
  resize: none;
  transition: border 0.2s;
  overflow: auto !important;

  &:focus {
    border-color: ${(props) =>
      props.isError ? props.theme.colors.danger : props.theme.colors.primary};
  }
`;

export const StyledTextErro = styled.p`
  margin-top: -2rem;
  color: ${(props) => props.theme.colors.danger};
`;

export const StyledButton = styled.button<{
  isgrey?: boolean;
  disabledStyle?: boolean;
}>`
  margin-left: auto;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  width: 10rem;
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.white};

  background-color: ${(props) =>
    props.isgrey
      ? lighten(0.1, props.theme.colors.grey)
      : props.theme.colors.primary};

  background-color: ${(props) =>
    props.disabledStyle && lighten(0.1, props.theme.colors.grey)};

  font-weight: bold;
  cursor: pointer;
  flex: 1;

  &:hover {
    background-color: ${(props) =>
      props.isgrey
        ? lighten(0.05, props.theme.colors.grey)
        : darken(0.15, props.theme.colors.primary)};

    background-color: ${(props) =>
      props.disabledStyle && lighten(0.1, props.theme.colors.grey)};
  }

  &:active {
    background-color: ${(props) =>
      props.isgrey
        ? props.theme.colors.grey
        : darken(0.3, props.theme.colors.primary)};

    background-color: ${(props) =>
      props.disabledStyle && lighten(0.1, props.theme.colors.grey)};
  }
`;
