import axios from "axios";
import { Dispatch, SetStateAction } from "react";

type TUpdateSponsorProps = {
    accessToken: string | null;
    logo: string;
    footer: string;
    isFetching?: Dispatch<SetStateAction<boolean>>;
    id: string;
}

export const updateSponsorFooterForm = async({ 
    accessToken,
    logo,
    id,
    isFetching,
}: TUpdateSponsorProps) => {
    
    try {
        if (isFetching) isFetching(true);

        const body = {
          logo,
        };

        const { data } = await axios.put(
            `${process.env.REACT_APP_SPONSOR_API_URL}/update/${id}`,
            body,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,    
                }
            },
        );
        return data;
    } catch (err) {
        return null;
    } finally {
        if (isFetching) isFetching(false);
    }
}