import React from 'react';

import Loading from '../loading';
import ReactFadeIn from 'react-fade-in'

import {
  StyledOverlayLoading,
} from './overlay-loading-styles';

export type TOverlayLoadingBaseProps = {
  textToLoading?: string;
  fullScreen?: boolean;
};

const OverlayLoading = ({
  textToLoading = 'Carregando...',
  fullScreen = false,
}: TOverlayLoadingBaseProps) => (
  <StyledOverlayLoading fullScreen={fullScreen}>
      <ReactFadeIn>
        <Loading />
        <span className="text-disclaimer">{textToLoading}</span>
      </ReactFadeIn>
    </StyledOverlayLoading>
  );

export default OverlayLoading;
