import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const PolygonIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox='0 0 11 9' {...props}>
            <path d="M5.19615 0L10.3923 9H0L5.19615 0Z" />
        </SvgIcon >
    )
}

export default PolygonIcon;