import { useCallback } from "react";
import { v4 as uuidV4 } from 'uuid';
import { StyledTable } from "./events-table-styles";
import { columns, tableOptions } from "./helpers";
import TableRow from "./table-row";
import { TEventsTable } from "./types";

const EventsTable = ({
  onCopy, 
  onQrCode, 
  onDelete, 
  setEventToDelete, 
  data, 
  onDiagnosis, 
  onEdit,
}: TEventsTable) => {
  const customRowRender = useCallback((data: any, dataIndex: number, rowIndex: number) => {
    return (
      <TableRow 
        key={uuidV4()}
        data={data}
        onCopy={() => onCopy(data[0], data[2])}
        onDelete={onDelete} 
        setEventToDelete={setEventToDelete}
        onDiagnosis={() => onDiagnosis(data[0])}
        onQrCode={() => onQrCode(data[3]?.props?.children)}
        onEdit={onEdit}
      />
      );
  }, [
    onCopy, 
    onQrCode, 
    onDiagnosis, 
    onDelete, 
    setEventToDelete,
    onEdit,
  ])

  return <StyledTable
    title="Eventos"
    data={data}
    columns={columns}
    options={{...tableOptions, customRowRender}}
  />
}

export default EventsTable;
