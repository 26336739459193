import styled from "styled-components";
import { devices } from "../../../../assets/styles/devices";

export const StyledContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grey};
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 1rem;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`;

export const StyledTitle = styled.div`
  font-size: 1.25rem;
  font-weight: Bold;
`;

export const StyledContent = styled.div`
  background-color: ${(props) => props.theme.page.body};
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${devices.widescreen} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledInputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  gap: 0,625rem;

  label { 
    margin-bottom: 8px;
    font-size: 1rem;
    font-weight: normal;
    color: ${(props) => props.theme.colors.blackLight}
  }
`;

export const StyledContentOption = styled.div`
  background-color: ${(props) => props.theme.page.body};
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${devices.widescreen} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const StyledSwitchOption = styled(StyledInputBox)`
  background-color: ${(props) => props.theme.page.body};
  align-items: flex-start;
  padding-left: 1rem;
`

export const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.page.body};
  padding: 1rem;
  display: flex;
  padding-bottom: 2rem;
`;

export const StyledGroup = styled.div`
  width: 100%;
  display: grid;
  justify-content: flex-end;
  gap: 1rem;
  grid-template-columns: 1fr;

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${devices.widescreen} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledGroup2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const ButtonJS = styled.button<{ disabled?: boolean; }>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${(props) => props.disabled ? props.theme.colors.grey : props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  gap: 5px;
  min-width: 10rem;
  max-width: max-content;
  border-radius: 5rem;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));

  img { 
    width: 1rem;
    height: 1rem;
  }
`;