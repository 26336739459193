import axios from "axios";

type TUpdateFormProps = {
  id: string;
  accessToken: string | null;
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  first_logo?: string;
  second_logo?: string;
  background_image?: string;
  left_first_layer?: string;
  left_second_layer?: string;
  mobile_first_layer?: string;
  mobile_second_layer?: string;
  email?: string;
  js_code?: string;
  html_code?: string;
};

export default async function updateForm({
  id,
  accessToken,
  setIsFetching,
  background_image,
  email = "",
  first_logo,
  js_code,
  left_first_layer,
  left_second_layer,
  mobile_first_layer,
  mobile_second_layer,
  second_logo,
  html_code,
}: TUpdateFormProps) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const requestBody = {
    background_image,
    email,
    first_logo,
    js_code,
    left_first_layer,
    left_second_layer,
    mobile_first_layer,
    mobile_second_layer,
    second_logo,
    html_code,
  };

  try {
    if (setIsFetching) setIsFetching(true);

    await axios.put(
      `${process.env.REACT_APP_FORM_API_URL}/update/${id}`,
      requestBody,
      requestOptions
    );
  } catch (err: any) {
    return err;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
