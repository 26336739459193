import { Chip } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { lighten } from "polished";
import styled from 'styled-components';

export const StyledTable = styled(MUIDataTable)`
  border: 1px solid ${props => props.theme.colors.grey};

  .MuiTypography-root {
    font-weight: bold;
    font-family: 'Poppins';
    color: ${props => lighten(0.2, props.theme.colors.black)};
  }

  .MuiIconButton-root {
    color: ${props => props.theme.colors.primary};
  }


  .MuiTableCell-head {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    font-weight: bold;

    &:last-child {
      border-top-right-radius: 5px;
    }
  }

  & .MuiTableCell-body {
    color: ${props => lighten(0.3, props.theme.colors.black)};
    border: none;
  }
`;

export const Tag = styled(Chip)`
  height: 1.8rem !important;
`;