import { Box, Button, Stack, Tab, TablePagination, Tabs, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import SuccessIcon from '../../../../shared/components/icons/success';
import GoogleAsButtonIcon from '../../../../shared/components/icons/google-as-button';
import FoursquareAsButtonIcon from '../../../../shared/components/icons/foursquare-as-button';
import YelpAsButtonIcon from '../../../../shared/components/icons/yelp-as-button';
import TabGeral from './tab-geral';
import { findBatchResultSumary } from '../../../../shared/services/batch-check/find-result-sumary';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Plataforms } from '../batch-diagnosis';
import ErrorIcon from '../../../../shared/components/icons/error';
import TabPlatform from './tab-platform';
import { findAllBatchCheckLocatios } from '../../../../shared/services/batch-check-location/find-all';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Header from '../../../../shared/components/header/header';
import { CustomTypography } from '../batch-diagnosis-styles';
import { useReactToPrint } from 'react-to-print';
import Breadcrumbs from '../../../../shared/components/breadcrumbs/breadcrumbs';
import { useSelector } from 'react-redux';
import Paginator from '../../../../shared/components/pagination/pagination';



export interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
  ref?: React.Ref<HTMLDivElement>;
}

const TabPanel = React.forwardRef<HTMLDivElement, TabPanelProps>((props, ref) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      ref={ref}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )
      }
    </div >
  );
});
const Details: React.FC = () => {
  const [tab, setTab] = useState(0);
  const ref = useRef<any>(null)
  const [data, setData] = useState<any>({});
  const printRef = useRef<any>()
  const [list, setList] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const intl = useIntl();
  const { id } = useParams();
  const isAuthenticated = useSelector((state: any) => {
    return state.authenticated.isAuthenticated
  });
  const handleChangeTab = (e: any, index: number) => setTab(index);
  const fetch = async () => await findBatchResultSumary(id, { onSuccess: setData })

  const onSuccessLocations = (data: any) => {
    setList(data[0].map(({ location_name, ...rest }: any) => {
      return { ...rest, name: location_name }
    }));
    setTotal(data[1])
  }

  const fetchCheckLocations = async (params = {}, onSuccessCb = (data: any) => { }) => await findAllBatchCheckLocatios(id, { params, onSuccess: onSuccessCb })

  useEffect(() => {
    fetch()
    fetchCheckLocations({ pageSize }, onSuccessLocations)
  }, [])

  const percentages = {
    correct: data?.results?.byStatus?.correct * 100 || 0,
    incorrect: data?.results?.byStatus?.incorrect * 100 || 0,
    nonexistent: data?.results?.byStatus?.nonexistent * 100 || 0,
  };

  const platforms = Object.entries(data?.results?.byPlatform || {}).map(([prop, value]: any) => {
    const key = prop as Plataforms;
    const imgs = {
      google: GoogleAsButtonIcon,
      foursquare: FoursquareAsButtonIcon,
      yelp: YelpAsButtonIcon,
    }
    const descriptions = {
      google: 'O Google contém integrações com milhares de outras plataformas que utilizam seu imenso banco de dados para oferecer informações precisas sobre os seus locais. Além da posição marcada no mapa, o usuário consegue ter acesso ao perfil completo da empresa, incluindo o Perfil de Empresa no Google, assim como acesso a rotas, trânsito e trajetos atualizados em tempo real.',
      yelp: 'O Yelp se tornou uma das plataformas mais acessadas quando se trata de buscas sobre informações de estabelecimentos locais e informações sobre serviços de comércios. Com sua gama de avaliações, é uma ferramenta importante para a construção de uma boa reputação e evidência na internet.',
      foursquare: 'O Foursquare oferece funcionalidades como avaliações de estabelecimentos, comentários e notas, bem como a possibilidade de os utilizadores se encontrarem a partir do check-in realizado. Ele também administra uma série de outras plataformas que usam seu banco de dados local, ampliando o alcance dessas empresas.'
    }
    return {
      name: key,
      img: imgs[key] || '',
      description: descriptions[key],
      relevance: value > 0.8 ? 'Alta relevancia' : 'Baixa relevancia',
      percentage: Number((value * 100).toFixed(0)),
      icon: value > 0.8 ? SuccessIcon : ErrorIcon,
    }
  })

  const carousel = Object.entries(data?.results?.byPlatform || {}).map(([prop, value]: any) => {
    const key = prop as Plataforms
    const imgs = {
      google: GoogleAsButtonIcon,
      foursquare: FoursquareAsButtonIcon,
      yelp: YelpAsButtonIcon
    }
    const descriptions = {
      google: 'SEO local: Google gerencia mapas e listas para visibilidade.',
      yelp: 'Essencial para descoberta de locais, e ampliar exposição empresarial.',
      foursquare: 'Descoberta de empresas, amplia exposição e conexão online.'
    }
    return {
      stack: key,
      description: descriptions[key],
      img: imgs[key],
      percentage: intl.formatNumber(value, { style: 'percent' }),
    }
  })

  const platformsPercentages = {
    google: intl.formatNumber(data?.results?.byPlatform?.google || 0, { style: 'percent' }).slice(0, -1),
    yelp: intl.formatNumber(data?.results?.byPlatform?.yelp || 0, { style: 'percent' }).slice(0, -1),
    foursquare: intl.formatNumber(data?.results?.byPlatform?.foursquare || 0, { style: 'percent' }).slice(0, -1),
  }

  const breadcrumbItems = [
    { label: 'Check individual', href: '/dashboard/event/list' },
    { label: 'Check em massa', href: '/dashboard/batch/list' },
    { label: data?.company_name, href: `/dashboard/batch/list/${id}` },
    { label: 'Detalhes', href: '#', current: true, },
  ];

  return (
    <>
      <Header />
      {isAuthenticated && (
        <Box mt={4} mx={2}>
          <Breadcrumbs items={breadcrumbItems} />
        </Box>
      )}
      <Box display="flex" flexDirection="column" mx={[0, 2]} mb={2}>
        <Box>
          <Typography px={[2, 0]} align="left" fontWeight={400} mt={[2, 4]}>
            Empresa:
          </Typography>
          <Typography
            px={[2, 0]}
            mb={4}
            fontWeight={700}
            fontSize="40px"
            color="#374957"
          >
            {data?.company_name}
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "white",
            padding: " 0px 16px",
            borderRadius: "8px",
            paddingBottom: "16px",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "white",
              borderBottom: "2px solid #EFEFEF",
              marginBottom: "24px",
            }}
            display="flex"
            justifyContent="center"
          >
            <Tabs
              variant="scrollable"
              value={tab}
              onChange={handleChangeTab}
              sx={{ justifyContent: "center" }}
            >
              <Tab label="Geral" />
              <Tab label="Google" />
              <Tab label="Yelp" />
              <Tab label="Foursquare" />
            </Tabs>
          </Box>
          <TabPanel index={0} value={tab}>
            <TabGeral
              ref={ref}
              resultList={list}
              percentages={percentages}
              data={carousel}
              stacksCards={platforms}
            />
          </TabPanel>
          <TabPanel index={1} value={tab}>
            <TabPlatform
              ref={ref}
              description=" Seja encontrado no maior mapa virtual do mundo com integração profunda com outras plataformas do Google."
              data={list}
              plataforma="Google"
              percentage={Number(platformsPercentages.google)}
            />
          </TabPanel>
          <TabPanel index={2} value={tab}>
            <TabPlatform
              ref={ref}
              description="Seja encontrado em uma das maiores plataformas de reviews sobre pontos comerciais da internet e mostre que sua empresa é destaque na sua região."
              data={list}
              plataforma="Yelp"
              percentage={Number(platformsPercentages.yelp)}
            />
          </TabPanel>
          <TabPanel index={3} value={tab}>
            <TabPlatform
              ref={ref}
              description="Seja encontrado em uma das maiores plataformas de check-in e avaliação de pontos comerciais da internet."
              data={list}
              plataforma="Foursquare"
              percentage={Number(platformsPercentages.foursquare)}
            />
          </TabPanel>
          <Box display="flex" justifyContent="flex-end" px={2}>
            <Paginator
              rowsPerPageOptions={[10, 50, 100]}
              colSpan={3}
              count={total}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={(ev, newPage) => {
                fetchCheckLocations(
                  {
                    page: newPage,
                    pageSize,
                  },
                  onSuccessLocations
                );
                const scrollTop = ref.current.offsetTop;
                window.scrollTo({ top: scrollTop - 50, behavior: "smooth" });
                setPage(newPage);
              }}
              onRowsPerPageChange={(ev) => {
                fetchCheckLocations(
                  {
                    page,
                    pageSize: ev.target.value,
                  },
                  onSuccessLocations
                );
                setPageSize(Number(ev.target.value));
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Details;