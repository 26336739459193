
import { StyledBallGraph, StyledStatsName, StyledStatisticsBallWrapper } from "./statistics-ball-styles";
import { TStatisticsBall } from "./types";

const StatisticsBall = ({ 
  color,
  name,
  percentage,
}: TStatisticsBall) => {
  return (
    <StyledStatisticsBallWrapper>
      <StyledBallGraph color={color}>
        { percentage + '%' }
      </StyledBallGraph>
      <StyledStatsName color={color}>
        { name }
      </StyledStatsName>
    </StyledStatisticsBallWrapper>
  )
}

export default StatisticsBall;