import { Stack } from "@mui/material";
import React from "react";
import { RelevanceCard } from "../../../shared/components/locale-card/locale-card";
import {
  Circle,
  Container,
  PercentageText,
  Wave1,
  Wave2,
  Wave3,
} from "./stack-card-styles";
import { CustomTypography } from "../../../pages/diagnosis/batch-diagnosis/batch-diagnosis-styles";

export interface StackCardProps {
  percentage: number;
  img: any;
  description: string;
  name: string;
  relevance: string;
  icon: any;
  stack?: string;
}
interface PercentageCircleProps {
  percentage: number;
}

const PercentageCircle: React.FC<PercentageCircleProps> = ({ percentage }) => {
  return (
    <Circle>
      <Wave1 fillTo={percentage} />
      <Wave2 fillTo={percentage} />
      <Wave3 fillTo={percentage} />
      <PercentageText>{percentage}%</PercentageText>
    </Circle>
  );
};

const StackCard: React.FC<StackCardProps> = ({
  percentage,
  img: Img,
  description,
  name,
  relevance,
  icon: Icon,
  stack = 'Pack'
}) => {
  return (
    <Container
      direction={["column", "row"]}
      sx={{ pageBreakInside: 'avoid' }}
      gap={2}
      marginBottom={4}
      alignItems={["center", "left"]}
    >
      <Img sx={{ fontSize: '140px' }} />
      <Stack>
        <CustomTypography color="#ffffff">{stack}</CustomTypography>
        <CustomTypography
          color="#ffffff"
          fontSize="40px"
          fontWeight={700}
          textTransform="capitalize"
        >
          {name}
        </CustomTypography>
        <CustomTypography fontWeight={400} color="#ffffff">
          {description}
        </CustomTypography>
      </Stack>
      <Stack gap={2} alignItems="center">
        <PercentageCircle percentage={percentage} />
        <RelevanceCard relevance={relevance} icon={Icon} />
      </Stack>
    </Container>
  );
};

export default StackCard;
