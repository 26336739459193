import { StyledErrorMessage, StyledSlugInput } from "./slug-input-styles";
import { TSlugInputProps } from "./types";
import { debounce } from "lodash";
import { getEventBySlug } from "../../services/events/getEventBySlug";
import { IRootState } from "../../store-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import isNilOrEmpty from "../../utils/isNilOrEmpty";
import { isEmpty } from "ramda";
import { useSnackbar } from "notistack";

const SlugInput = ({
  setSlug,
  isValidSlug,
  setIsValidSlug,
  isDuplicate = true,
  eventId,
  initialSlug,
}: TSlugInputProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [, setValue] = useState('');
  const [hasInitialValue, setHasInitialValue] = useState(false);

  const delayInMs = 500;
  const { saasUserToken } = useSelector(
    (state: IRootState) => state.authenticated
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleSetSlug = debounce(async (text: string) => {
    const regex = /[^a-zA-z_-]/g;
    const isInvalidSlug = text.match(regex);

    setValue(text);
    setSlug(text);

    if(isEmpty(text)) {
      setErrorMessage('O slug não pode ser vazio!');
      setIsValidSlug(false);
      return;
    }

    const isSlugInUse = await getEventBySlug({
      slug: text,
      accessToken: saasUserToken || '',
      type: 'no-default',
    });

    const { id } = isSlugInUse;

    if(!isNilOrEmpty(isSlugInUse) && id !== eventId) {
      setErrorMessage('Já existe um evento com essa Slug!');
    } else if(!isNilOrEmpty(isInvalidSlug)) {
      setErrorMessage('Slug deve ter apenas letras!');
    }
    const isValid = (isNilOrEmpty(isSlugInUse) || eventId === id) && isNilOrEmpty(isInvalidSlug);

    setIsValidSlug(isValid);
  }, delayInMs);

  useEffect(() => {
    if(!errorMessage) return;

    if(!isDuplicate && !isValidSlug) {
      enqueueSnackbar(errorMessage, { variant: 'warning', autoHideDuration: 1500 });
    }
  }, [errorMessage, enqueueSnackbar, isDuplicate, isValidSlug]);

  // Put initial slug if it exists
  useEffect(() => {
    if(initialSlug && !hasInitialValue) {
      setHasInitialValue(true);
      setValue(initialSlug);
    }
  }, [initialSlug, hasInitialValue]);

  return (
    <>
      <StyledSlugInput
        variant="outlined"
        placeholder={'slug'}
        required
        onChange={({target}) => {
          handleSetSlug(target.value);
        }}
        error={!isValidSlug}
        defaultValue={initialSlug}
      />
      {
        isDuplicate && 
        <StyledErrorMessage hasError={!isValidSlug}>
          {errorMessage || 'O slug não pode ser vazio!'}
        </StyledErrorMessage>
      }
    </>
  )
}

export default SlugInput;