import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import CirclesPercentage, { Percentages } from '../../../../shared/components/circles-percentage/circles-percentage';
import { CustomTypography } from '../batch-diagnosis-styles';
import StackCard, { StackCardProps } from '../../../../shared/components/stack-card/stack-card';
import Carousel, { CarouselType } from '../swiper';
import styled from 'styled-components';
import Icon from '../../../../shared/components/Icon/Icon';
import ExpandedLocaleCards from '../../../../shared/components/expanded-locale-card';

export type TabGeralProps = {
    data: CarouselType[];
    stacksCards: StackCardProps[];
    percentages: Percentages;
    resultList: any[];
}

const PageWrapper = styled(Box)`
    background: linear-gradient(180deg, #0080FF 0%, #0039A8 100%);
    height: 100%;
    padding-top: 20px;
    padding-bottom: 80px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`;

export const PageContainer = styled.div`
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
`

export const ResultWrapper = styled(Box)`
    background: linear-gradient(180deg, #0080FF 0%, #0039A8 100%);
    padding: 16px 32px;
`

export const StyledTypography = styled(Typography)`
    @media only print {
        margin-bottom: 40px !important;
    }
`

const TabGeral = React.forwardRef<HTMLDivElement, TabGeralProps>(({ data, stacksCards, percentages, resultList }, ref) => {
    return (
        <>
            <PageWrapper>
                <PageContainer>
                    <Box>
                        <Typography fontWeight={700} fontSize="48px" color="white" align="center">Seus dados de localização definem sua relevância na internet</Typography>
                        <Typography fontWeight={500} fontSize="20px" color="white" align='center'>Confira o diagnóstico de qualidade dos seus dados locais nas principais plataformas de busca online:</Typography>
                    </Box>
                    <Box mx="auto" display="flex" flexDirection="column" alignItems="center" justifyContent="center" my={4}>
                        <CirclesPercentage {...percentages} />
                    </Box>
                    <Box mx="auto" mt={4} px={[0, 4]} sx={{ pageBreakInside: 'avoid' }}>
                        <CustomTypography color="#65FFC4" fontWeight={500}>Plataformas integradas</CustomTypography>
                        <CustomTypography mb={2} color="#FFFFFF" fontSize="40px" fontWeight={700}>Conheça as plataformas onde as suas informações precisam estar presentes</CustomTypography>
                        <CustomTypography mb={4} lineHeight="30px" color="#FFFFFF" fontWeight={500} fontSize="20px">Se você possui um negócio físico, é importante ser encontrado em plataformas de busca online, de forma a estar sempre ao alcance de seus clientes.</CustomTypography>
                    </Box>
                    <Box mx="auto" marginBottom={8}>
                        {stacksCards.map((props) => <StackCard {...props} />)}
                    </Box>
                    <Box mt={6} sx={{ pageBreakInside: 'avoid' }}>
                        <StyledTypography textAlign="center" mb={[0, 10]} color="#FFFFFF" fontSize="40px" fontWeight={700}>Desempenho nas principais plataformas</StyledTypography>
                        <Carousel data={data} />
                    </Box>
                </PageContainer>
            </PageWrapper>
            <ResultWrapper ref={ref}>
                <Stack alignItems="center" mx="auto" gap={2} mb={8}>
                    <Icon name="building" prefix="sr" color="#ffffff" fontSize="42px" />
                    <Typography color="#FFFFFF" fontSize="40px" fontWeight={700} textAlign="center">Desempenho por local</Typography>
                    <Typography color="#FFFFFF" fontSize="20px" fontWeight={500} textAlign="center">Essa é a qualidade dos seus dados de localização, em cada um dos seus {resultList.length} locais, nas principais plataformas de busca da internet.</Typography>
                </Stack>
                <ExpandedLocaleCards cards={resultList} />
            </ResultWrapper>
        </>
    )
})

export default TabGeral;