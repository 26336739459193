import styled from "styled-components";

export const StyledStatisticsBallWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const StyledBallGraph = styled.div<{
  color: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 50%;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.color};
  width: 7.8rem;
  height: 7.8rem;
`;

export const StyledStatsName = styled.h3<{ color: string }>`
  color: ${(props) => props.color};
`;
