import React from 'react';

import {
    CircularProgressbarWithChildren,
    buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import {
    StyledLocationScore,
    CircleContainer,
} from './circle-percentage-animated-styles';

const CirclesPercentageAnimated = ({ percentage = 0, color }: any) => (
    <StyledLocationScore>
        <CircleContainer>
            <CircularProgressbarWithChildren
                value={percentage}
                text={`${Math.trunc(percentage)}%`}
                styles={buildStyles({
                    rotation: 1 / 2,
                    trailColor: '#eee',
                    textColor: '#eee',
                    pathColor: color,
                })}
            />
        </CircleContainer>
    </StyledLocationScore>
);

export default CirclesPercentageAnimated;
