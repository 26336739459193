import axios from "axios";

type TUpdateFooterProps = {
  id: string;
  accessToken: string | null;
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  main_logo?: string;
  text_color?: string;
  sponsor?: string[];
  background_color?: string;
};

export default async function updateFooter({
  id,
  accessToken,
  setIsFetching,
  main_logo,
  text_color,
  background_color
}: TUpdateFooterProps) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const requestBody = {
    main_logo,
    text_color,
    background_color,
  }

  try {
    if (setIsFetching) setIsFetching(true);

    await axios.put(
      `${process.env.REACT_APP_FOOTER_API_URL}/update/${id}`,
      requestBody,
      requestOptions,
    );
  } catch (err: any) {
    return err;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
