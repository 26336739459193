import axios from "axios";
import { TGetDiagnosisByIdResponse } from "./types";

type TGetEventBySlug = {
  leadId: string;
  accessToken: string | null;
};

export const getDiagnosisById = async ({
  leadId,
  accessToken,
}: TGetEventBySlug): Promise<TGetDiagnosisByIdResponse> => {
  try {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_CHECK_API_URL}/get-one-from-lead/${leadId}`,
      requestOptions
    );

    return data;
  } catch (error) {
    return null;
  }
};
