import axios from "axios";
import { store } from "../../store-redux";

export const findAllBatchCheckLocatios = async (id: string | undefined, {
  params,
  onSuccess,
  onError,
  onFinnaly = () => {},
  isLoading = () => {},
}: any) => {
    const { saasUserToken } = store.getState().authenticated;
    isLoading(true)
    const result = await axios.get(
      `${process.env.REACT_APP_BATCH_CHECK_LOCATION_API_URL}/get-all-from-checklist/${id}`,
        {
            headers: {
                Authorization: `Bearer ${saasUserToken}`,
            },
            params,
        }
      ).then(({data}) => onSuccess(data)).catch(onError).finally(() => {
        onFinnaly();
        isLoading(false);
      });

      return result;
};
