import styled from "styled-components";
import { devices } from "../../../assets/styles/devices";

export const StyledLandingPageContainer = styled.section`
  font-family: "Poppins", sans-serif;
`;

export const StyledPlatformContainer = styled.article`
  padding: 1rem 3rem;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
  background: ${(props) => props.theme.colors.white};

  @media ${devices.desktop} {
    display: grid;
    grid-template-columns: 2fr 2fr;
    padding: 5rem 10rem;
    align-items: center;
    justify-content: center;

    img {
      width: 50rem;
      height: 60rem;
    }
  }

  @media ${devices.tablet} {
    img {
      margin-top: 2rem;
      height: 20rem;
    }
  }
`;

export const StyledPlatformMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: none;
  max-height: none;

  @media ${devices.desktop} {
    align-items: flex-start;
  }
`;

export const StyledTitle = styled.h1`
  margin-top: 1.5rem;
  line-height: 1.3;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;

  @media ${devices.desktop} {
    text-align: start;
    margin-bottom: 3rem;
  }

  @media ${devices.tablet} {
    margin-bottom: 3rem;
  }
`;

export const StyledAbout = styled.p`
  font-size: 1.125rem;
  color: ${(props) => props.theme.colors.neutra};
  margin-bottom: 2rem;
`;

export const StyledBenefitsContainer = styled.article`
  padding: 2rem;
  text-align: center;

  @media ${devices.desktop} {
    padding: 5rem;
  }
`;

export const StyledBenefitsMain = styled.main`
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;

  @media ${devices.desktop} {
    flex-direction: row;
    margin-bottom: 3rem;
  }
  @media ${devices.tablet} {
    flex-direction: column-reverse;
    margin-bottom: 3rem;
  }
`;

export const StyledFeedbacksContainer = styled.article`
  padding: 2rem;
  background: ${(props) => props.theme.colors.primary};
  text-align: center;

  @media ${devices.desktop} {
    padding: 5rem;
  }
`;

export const StyledFeedbacksCardsContainer = styled.section`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  padding-bottom: 2rem;
  margin: 1rem;
  flex-direction: column;

  @media ${devices.desktop} {
    flex-direction: row;
    margin-bottom: 3rem;
  }

  @media ${devices.tablet} {
    flex-direction: row;
    margin-bottom: 3rem;
    flex-wrap: wrap;
  }
`;

export const StyledFeedbacksTitle = styled.h1`
  color: ${(props) => props.theme.colors.white};
  font-weight: 600;
  font-size: 2rem;
`;

export const StyledFAQContainer = styled.article`
  background: "#EBF4FF";
  display: flex;
  flex-direction: column;
  padding: 5rem;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  main {
    margin-bottom: 1rem;
  }

  padding: 2rem;
`;

export const StyledQuestionTitle = styled.strong`
  color: ${(props) => props.theme.colors.darkGrey};
`;

export const StyledQuestionAnswer = styled.p`
  color: ${(props) => props.theme.colors.primaryGrey};
`;

export const StyledAccordionContainer = styled.section`
  width: auto;

  @media ${devices.desktop} {
    width: 62.5rem;
  }
`;
