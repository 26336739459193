import axios from "axios"

type TGetEventBySlug = {
  slug: string;
  accessToken: string;
  type?: string;
}

export const getEventBySlug = async ({ slug, accessToken, type = "default" }: TGetEventBySlug) => {
  try {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_EVENT_API_URL}/by-slug/${slug}?type=${type}`,
      requestOptions
    );
    
    return data;
  } catch(error) {
    return null;
  }
}