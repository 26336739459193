import axios from "axios";
import { store } from "../../store-redux";

export const createCheck = async ({
  data,
  onSuccess,
  onError,
  onFinnaly,
}: any) => {
    const { saasUserToken } = store.getState().authenticated
    const result = await axios.post(
        `${process.env.REACT_APP_BATCH_CHECK_API_URL}/create`,
        data,
        {
            headers: {
                Authorization: `Bearer ${saasUserToken}`,
                'Content-Type': 'multipart/form-data'
            }
        }
      ).then(onSuccess).catch(onError).finally(onFinnaly);

      return result;
};
