import styled from "styled-components";

export const StyledButtonContainer = styled.a<{ outlined?: boolean }>`
  padding: 0.775rem 1.375rem;

  font-family: "Poppins";
  font-weight: 600;
  text-decoration: none;

  background: ${(props) =>
    props.outlined ? "transparent" : props.theme.colors.primary};

  border: ${(props) =>
    props.outlined ? `1px solid ${props.theme.colors.white}` : "transparent"};
  border-radius: 7.5px;

  color: ${(props) => props.theme.colors.white};
`;
