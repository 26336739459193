import { MenuItem } from "@mui/material";
import {
  StyledFormFilter,
  StyledInput,
  StyledInputBox,
  StyledSelect
} from "./form-filter-styles";
import { TFormFilter } from "./types";

const FormFilter = ({
  statuses,
  onSearchWritten,
  onStatusSelected,
  statusSelected 
}: TFormFilter) => {
  return (
    <StyledFormFilter elevation={1}>
      <StyledInputBox>
        <label htmlFor="search">Pesquisa: </label>
        <StyledInput 
          id="search"
          onChange={(event) => onSearchWritten(event.target.value as string)} />
      </StyledInputBox>
      <StyledInputBox>
        <label htmlFor="status">Status: </label>
        <StyledSelect
          id="status"
          value={statusSelected}
          onChange={(event) => onStatusSelected(event.target.value as string)}>
          { 
            statuses.map((status) => (
              <MenuItem
                value={status.id} 
                key={status.id}>
                  {status.name}
              </MenuItem>  
            )) 
          }
        </StyledSelect>
      </StyledInputBox>
    </StyledFormFilter>
  )
}

export default FormFilter;