import axios from 'axios';
import { TValidateTokenProps } from './types';

export default async function validateToken({
  accessToken,
  setTokenLikeExpired,
}: TValidateTokenProps) {
  try {
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_AUTH_VALIDATION_API_URL}`,
      requestOptions,
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return err;
  }
}
