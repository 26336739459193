import { isNil } from "ramda";
import { formattedCompanyField, formattedPDV, formattedPositionInCompany } from "../../../pages/result-event/result-event-table/table-row/helpers";
import { ILeadEntity } from "../../services/check-form/types";

const brazilianStates:{ [key: string]: string; } = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rion Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

const tableInfoFields = [
  ['client_name', 'Nome: '],
  ['company_name', 'Nome da Empresa: '],
  ['address', 'Endereço: '],
  ['neighborhood', 'Bairro'],
  ['cep', 'CEP: '],
  ['number', 'Número: '],
  ['city', 'Cidade: '],
  ['state', 'Estado: '],
  ['company_phone_number', 'Telefone Principal: '],
  ['client_email', 'E-mail: '],
  ['pdv_quantity', 'Quantidade de PDVs: '],
  ['company_field', 'Segmento: '],
  ['position_in_company', 'Cargo: '],
];

const formattedData: Record<string, (value: string) => string> = {
  address: (value: string) => value.split(',')[0],
  state: (value: string) => brazilianStates[value],
  pdv_quantity: (value: string) => formattedPDV[value],
  company_field: (value: string) => formattedCompanyField[value],
  position_in_company: (value: string) => formattedPositionInCompany[value],
}

export const formattedTableInformation = (
  lead: ILeadEntity,
) => tableInfoFields.map(([key, label]) => {
  let value = String(lead[key] || '');
  const formatFunction = formattedData[key];

  if (!isNil(formatFunction)) value = formatFunction(value);
  if (key === 'neighborhood') value = String(lead.address.split(',')[1] || '');

  return ({
    label,
    value,
  });
});
