import React from "react";
import { ReactComponent as Log } from "../../../../assets/images/log.svg";
import IconButton from "../../../../shared/components/icon-button";
import {
  formattedCompanyField,
  formattedPDV,
  getDataRowCellValue,
} from "./helpers";
import {
  StyledActions,
  StyledTableCell,
  StyledTableRow,
} from "./table-row-styled";
import {
  TTableRowData,
  TRenderCellDataLeadRow,
  TTableColumnsName,
  TTableColumn,
} from "./types";

const TableRow = ({ data, onDiagnostic, onCopyLink, onLog }: TTableRowData) => {
  const [currentRowData, setCurrentRowData] = React.useState<TTableColumn[]>(
    []
  );

  React.useEffect(() => {
    setCurrentRowData(data);
  }, [data]);

  const rowId = React.useMemo(
    () =>
      getDataRowCellValue({
        columnRef: "id",
        dataRowCell: data,
      }),
    [data]
  );

  const renderCellData = React.useMemo<TRenderCellDataLeadRow>(
    () => ({
      id: (value) => value,
      client_name: (value) => value,
      company_name: (value) => value,
      company_field: (value) => formattedCompanyField[value] || "Não se Aplica",
      pdv_quantity: (value) => formattedPDV[value] || "Não se Aplica",
      client_phone_number: (value) => value,
      client_email: (value) => value,
      city: (value) => value,
      state: (value) => value,
      created_at: (value) => value,
      results: (value) => (
        <StyledActions>
          <IconButton
            title="Diagnóstico"
            iconSize={1.5}
            onClick={() => onDiagnostic(rowId)}
            iconType="QueryStats"
          />
          <IconButton
            title="Link do Resultado"
            iconSize={1.5}
            onClick={() => onCopyLink(rowId)}
            iconType="ContentCopy"
          />
          <IconButton
            title="Log das Plataformas"
            iconSize={1.5}
            onClick={() => onLog(rowId)}
            CustomIcon={Log}
          />
        </StyledActions>
      ),
    }),
    [onCopyLink, onDiagnostic, rowId, onLog]
  );

  return (
    <StyledTableRow>
      {currentRowData.map(
        (dataColumnCell) =>
          dataColumnCell?.visible && (
            <StyledTableCell key={dataColumnCell.columnRef}>
              {renderCellData[dataColumnCell.columnRef as TTableColumnsName]?.(
                dataColumnCell.value
              )}
            </StyledTableCell>
          )
      )}
    </StyledTableRow>
  );
};

export default TableRow;
