import styled from 'styled-components';

export const StyledContainer = styled.main`
  max-width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.page.body};
`;

export const StyledButton = styled.div`
  margin-left: 4.5rem;
  padding-top: 1.6rem;
  padding-bottom: 1rem;
`;

export const StyledFilter = styled.div`
  margin-left: 4.5rem;
  margin-right: 4.5rem;
  padding-bottom: 1rem;
`;

export const StyledTable = styled.div`
  margin-left: 4.5rem;
  margin-right: 4.5rem;
  padding-bottom: 1rem;
`;