import { useState } from "react";
import hublocalCheckLogo from '../../../assets/images/logo_hublocal_check.svg';
import AvatarProfile from "./avatar-profile";
import { StyledConteiner, StyledHeader, StyledLogo, StyledTitle } from "./header-styled";
import { THeaderProps } from "./types";
import { useSelector } from "react-redux";

const Header = ({ title }: THeaderProps) => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const isAuthenticated = useSelector((state: any) => {
    return state.authenticated.isAuthenticated
  });


  const handleClickOpen = () => {
    setIsProfileOpen(true);
  };

  const handleClose = () => {
    setIsProfileOpen(false);
  };

  return (
    <StyledHeader>
      <StyledConteiner>
        <StyledLogo>
          <img src={hublocalCheckLogo} alt="Hublocal Check Logo" />
          <StyledTitle>{title}</StyledTitle>
        </StyledLogo>
        {isAuthenticated &&
          <AvatarProfile
            handleClickOpen={handleClickOpen}
            isProfileOpen={isProfileOpen}
            handleClose={handleClose}
          />}
      </StyledConteiner>
    </StyledHeader>
  )
};

export default Header;
