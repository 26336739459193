import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

import {
  StyledLocalStatusIconWrapper,
  StyledLocalStatusInfo,
  StyledStatusText,
} from "./local-status-info-styles";
import { TLocalStatusInfoProps } from "./types";

const localStatusInfoProperties = {
  correct: {
    color: "#00da91",
    icon: <CheckRoundedIcon />,
    text: "Informação correta",
  },
  nonexistent: {
    color: "#FF5C5C",
    icon: <WarningAmberRoundedIcon />,
    text: "Informação inexistente",
  },
  incorrect: {
    color: "#FF9920",
    icon: <CloseRoundedIcon />,
    text: "Informação incorreta",
  },
  unavailable: {
    color: "#415D78",
    icon: <PriorityHighIcon />,
    text: "Informação indisponível",
  },
};

const LocalStatusInfo = ({ status }: TLocalStatusInfoProps) => {
  const localStatusInfo =
    localStatusInfoProperties[
      status as "correct" | "incorrect" | "nonexistent" | "unavailable"
    ];

  return (
    <StyledLocalStatusInfo>
      <StyledLocalStatusIconWrapper color={localStatusInfo.color}>
        {localStatusInfo.icon}
      </StyledLocalStatusIconWrapper>
      <StyledStatusText color={localStatusInfo.color}>
        {localStatusInfo.text}
      </StyledStatusText>
    </StyledLocalStatusInfo>
  );
};

export default LocalStatusInfo;
