
import { SvgIcon } from '@mui/material';
import React from 'react';

const EllipseIcon: React.FC<any> = ({ fontSize = '14px', color = "#FFB219" }) => {
    return (
        <SvgIcon viewBox="0 0 16 16" sx={{ fontSize }}>
            <g filter="url(#filter0_d_785_6039)">
                <circle cx="8" cy="6" r="4" fill={color} />
            </g>
            <defs>
                <filter id="filter0_d_785_6039" x="0" y="0" width="16" height="16" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_785_6039" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_785_6039" result="shape" />
                </filter>
            </defs>
        </SvgIcon>
    )
}

export default EllipseIcon;