import { useCallback, useEffect, useState } from "react";

import IconButton from "../icon-button";
import {
  StyledDialog,
  StyledJSCodeDialogHeader,
  StyledDialogTitle,
  StyledDialogContent,
  StyledButton,
  StyledTextArea,
  StyledTextErro,
} from "./code-dialog-styles";

import { ReactComponent as JSLogo } from "../../../assets/images/js.svg";
import { ReactComponent as HTMLLogo } from "../../../assets/images/html.svg";

import { TCodeDialogProps } from "./types";
import { htmlToJsDom } from "../../utils/html-to-js-dom";
import { isNil } from "ramda";

export const codeDialogProperties = {
  html: {
    title: "Código HTML",
    icon: <HTMLLogo />,
    placeholder: "<script>// Código JavaScript</script>",
  },
  js: {
    title: "Código JavaScript",
    icon: <JSLogo />,
    placeholder: "var test = 100;",
  },
};

const CodeDialog = ({
  isOpen,
  onClose,
  handleCode,
  code,
  type,
}: TCodeDialogProps) => {
  const [codeText, setCodeText] = useState(code ?? "");
  const [isError, setIsError] = useState(false);

  const handleClose = useCallback(() => {
    setCodeText(code || "");
    onClose();
  }, [code, onClose]);

  if (isNil(type)) return <span>Error</span>;

  const { title, icon, placeholder } = codeDialogProperties[type];

  const handleSendCode = () => {
    try {
      if (type === "js") new Function(codeText)();
      if (type === "html") {
        const jsCodeParsed = htmlToJsDom(codeText);
        setIsError(jsCodeParsed.label === "ERROR");
      }
      handleCode(codeText);
      setIsError(false);
      onClose();
    } catch {
      setIsError(true);
    }
  };

  function handleChangeCodeText(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setCodeText(event.target.value);
  }

  return (
    <StyledDialog open={isOpen} onClose={handleClose}>
      <StyledJSCodeDialogHeader>
        <StyledDialogTitle>
          {icon}
          {title}
        </StyledDialogTitle>
        <IconButton onClick={handleClose} isWhite iconSize={1.5} />
      </StyledJSCodeDialogHeader>
      <StyledDialogContent>
        <StyledTextArea
          isError={isError}
          minRows={10}
          onChange={handleChangeCodeText}
          placeholder={placeholder}
          value={codeText}
        />
        {isError && (
          <StyledTextErro>
            Erro no {title} inserido acima, o mesmo não será inserido no evento!
          </StyledTextErro>
        )}
        <StyledButton disabled={isError} onClick={handleSendCode}>
          Salvar
        </StyledButton>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default CodeDialog;
