import { Typography, Stack, Grid, Box, AccordionSummary, AccordionDetails, Tooltip, useMediaQuery, CircularProgress } from '@mui/material';
import PolygonIcon from '../icons/polygon';
import ExpandRow from './expand';
import Stacks from './stacks';
import BaseIcon from '../../components/Icon'
import FoursquareAsButtonIcon from '../icons/foursquare-as-button';
import GoogleAsButtonIcon from '../icons/google-as-button';
import YelpAsButtonIcon from '../icons/yelp-as-button';
import { AccordionStyled, RelevanteStyled } from './locale-card-styles';
import SuccessIcon from '../icons/success';
import { useMemo } from 'react';
import ErrorIcon from '../icons/error';

export const plataformsIcons = {
    Google: GoogleAsButtonIcon,
    Yelp: YelpAsButtonIcon,
    Foursquare: FoursquareAsButtonIcon,
}

export type RelevanceValues = 'low_relevance' | 'correct' | 'incorrect' | 'nonexistent';
export type PlatformTypes = 'Google' | 'Yelp' | 'Foursquare';
export type ResultsType = {
    id: string;
    platform: PlatformTypes;
    name_status: RelevanceValues;
    address_status: RelevanceValues;
    phone_status: RelevanceValues;
    address: string;
    phone: string;
    name: string;
}
export type LocaleCardProps = {
    name: string;
    results: ResultsType[];
    status: 'ERROR' | 'PENDING' | 'PROCESSING' | 'SUCCESS' | 'CANCELLED'
    address: string;
    phone: string;
    number: string;
    neighborhood: string;
    state: string;
    city: string;
    cep: string;
    country: string;
    open?: boolean;
    onChange?: () => void;
}

const ProcessingRender = () => {
    return <Stack direction="row" alignItems="center">
        <CircularProgress size={18} />
        <Typography ml={2}>Processando</Typography>
    </Stack>
}

export const getRelevanceColor = (relevance: RelevanceValues) => {
    const relevanceColors = {
        'low_relevance': '#FFB219',
        correct: '#14CC33',
        incorrect: '#FF6363',
        nonexistent: '#FF6363',
    }
    return relevanceColors[relevance] || 'white'
}

export const getResultLabel = (status: RelevanceValues, value: string) => {
    const relevanceColors = {
        'low_relevance': 'Baixa relevancia',
        'nonexistent': 'Inexistente',
        correct: value,
        incorrect: value
    }
    return relevanceColors[status]
}

export const RelevanceCard: React.FC<any> = ({ relevance, icon: Icon }) => {
    return (
        <RelevanteStyled padding={1}>
            <Icon />
            <Typography ml={1} component="span" variant="subtitle1" fontWeight={700}>
                {relevance}
            </Typography>
        </RelevanteStyled>
    )
}


const LocaleCard: React.FC<LocaleCardProps> = ({ name, results = [], status, onChange = () => { }, ...rest }) => {
    const isSmallScreen = useMediaQuery('(max-width:900px)');
    const isProcessing = status === 'PROCESSING';
    const isFinished = status === 'SUCCESS';

    const correctCounts = results.reduce((count, item) => {
        const { name_status, address_status, phone_status } = item;
        let increment = 0;

        if (name_status === "correct") {
            increment++;
        }

        if (address_status === "correct") {
            increment++;
        }

        if (phone_status === "correct") {
            increment++;
        }

        return count + increment;
    }, 0);

    const isHighRelevance = correctCounts / 9 > 0.8

    const relevanceLabel = isHighRelevance ? 'Alta relevancia' : 'Baixa relevancia';
    const relevanceIcon = isHighRelevance ? SuccessIcon : ErrorIcon;

    const arrayToMap: ResultsType[] = useMemo(() => ([
        results.find(({ platform }) => platform === 'Google') || {} as ResultsType,
        results.find(({ platform }) => platform === 'Yelp') || {} as ResultsType,
        results.find(({ platform }) => platform === 'Foursquare') || {} as ResultsType,
    ]), [results])


    const renders: any = {
        PENDING: () => 'Pendente',
        PROCESSING: ProcessingRender,
        SUCCESS: () => {
            return <Grid item md={4} sm={6}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <RelevanceCard relevance={relevanceLabel} icon={relevanceIcon} />
                    <Stack direction="row" gap={2}>
                        {arrayToMap.map(({
                            platform,
                            name_status,
                            address_status,
                            phone_status,
                            id,
                            ...rest
                        }) => {
                            const isCorrect = name_status === 'correct' && phone_status === 'correct' && address_status === 'correct'

                            const IconB = plataformsIcons[platform]
                            return (
                                <Stack key={id} direction="row" justifyContent={"right"} alignItems={"center"} >
                                    <Tooltip
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    backgroundColor: 'white',
                                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                                    minWidth: '340px',
                                                    padding: '0px'
                                                }
                                            }
                                        }}
                                        placement='top-start'
                                        title={
                                            <Stacks
                                                name={`Stacks do ${platform}`}
                                                platform={platform}
                                                values={{
                                                    platform,
                                                    name_status,
                                                    address_status,
                                                    phone_status,
                                                    id,
                                                    ...rest
                                                }}
                                            />
                                        }>
                                        <Stack padding={0} alignItems="center">
                                            <IconB sx={{ fontSize: '32px' }} />
                                            <PolygonIcon sx={{ transform: isCorrect ? '' : 'rotate(180deg)', color: isCorrect ? '#14CC33' : '#FF6363', fontSize: '14px' }} />
                                        </Stack>
                                    </Tooltip>
                                </Stack>
                            )
                        })}
                    </Stack>
                </Box>
            </Grid>
        },
        ERROR: () => 'Error',
        CANCELLED: () => 'Cancelado',
    }

    const Render = renders[status]

    return (
        <AccordionStyled expanded={rest.open} onClick={onChange}>
            <AccordionSummary
                disabled={!isFinished}
                sx={{ maxHeight: '80px', border: '1px solid #E5F3FF', borderRadius: '8px' }}
            >
                <Grid container alignItems="center">
                    <Grid display="flex" alignItems="center" item md={isProcessing ? 10 : 8} xs={isProcessing ? 8 : 6} sx={{ paddingRight: 1 }}>
                        <BaseIcon color="#415D78" name="home" prefix='sr' />
                        <Typography
                            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            fontSize="20px"
                            fontWeight={500}
                            ml={1}
                            color="#415D78"
                        >
                            {name}
                        </Typography>
                    </Grid>
                    {isSmallScreen ? <></> : <Render />}
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <ExpandRow data={results} name={name} {...rest} />
            </AccordionDetails>
        </AccordionStyled >

    );
};

export default LocaleCard;
