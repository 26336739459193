import { SvgIcon } from '@mui/material';
import React from 'react';



const ErrorIcon: React.FC = () => {
    return <SvgIcon viewBox="0 0 28 29">
        <rect y="0.844543" width="28" height="28" rx="8" fill="#FF6363" />
        <circle cx="14" cy="14.8445" r="8" fill="white" />
        <path d="M15.117 10.4809L14.8494 16.0314H12.9061L12.6385 10.4809H15.117ZM13.8836 19.1965C13.4802 19.1965 13.1544 19.0879 12.9061 18.8707C12.6657 18.6457 12.5454 18.3626 12.5454 18.0213C12.5454 17.6799 12.6657 17.3968 12.9061 17.1718C13.1544 16.9468 13.4802 16.8344 13.8836 16.8344C14.2792 16.8344 14.5973 16.9468 14.8378 17.1718C15.086 17.3968 15.2101 17.6799 15.2101 18.0213C15.2101 18.3548 15.086 18.6341 14.8378 18.8591C14.5973 19.084 14.2792 19.1965 13.8836 19.1965Z" fill="#FF6363" />
    </SvgIcon>
}

export default ErrorIcon;