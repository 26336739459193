import { TextField } from "@mui/material";
import styled from "styled-components";
import { devices } from "../../../../assets/styles/devices";

export const StyledContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grey};
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 1rem;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`;

export const StyledTitle = styled.div`
  font-size: 1.25rem;
  font-weight: Bold;
`;

export const StyledContent = styled.div`
  background-color: ${(props) => props.theme.page.body};
  padding: 1rem;

  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${devices.widescreen} {
    grid-template-columns: repeat(4, 1fr);
  }

`;

export const StyledInputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  gap: 0,625rem;

  label { 
    margin-bottom: 8px;
    font-size: 1rem;
    font-weight: normal;
    color: ${(props) => props.theme.colors.blackLight};
  }

  p {
    font-size: 0.8rem;
    font-weight: normal;
    color: ${(props) => props.theme.colors.blackLight}
  }

  & .drag_styled {
    min-width: 15rem;
    max-width: 100%;
  }
`;

export const StyledInput = styled(TextField)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  
  & .MuiOutlinedInput-input {
    padding: 0.4rem;
    height: 2rem;
    width: 70%;
  }

  p {
      width: 30%;
      font-size: 1rem;
      color: ${(props) => props.theme.colors.blackLight};
      display: flex;
      justify-content: center;
    }

  input[type="file" i] {
    label { 
      background: ${(props) => props.theme.colors.danger};
    }   
  }
`;
