import styled from "styled-components";

export const StyledModalContainer = styled.section`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  max-width: 80vh;
`;

export const StyledHeader = styled.header`
  background: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.85rem 1.5rem;
`;

export const StyledContent = styled.article`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  padding: 2rem 6rem;
`;

export const StyledActions = styled.article`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const StyledButton = styled.button<{ isGrey?: boolean }>`
  background: ${(props) =>
    props.isGrey
      ? props.theme.colors.grey
      : "linear-gradient(90deg, #5CC99D 8.17%, #6DE7B8 95.91%)"};
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  padding: 7.5px ${(props) => (props.isGrey ? "10px" : "30px")};
  font-size: 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-weight: bold;
`;

export const StyledLoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
`;
