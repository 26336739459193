import { isEmpty, isNil } from "ramda";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import { useSelector } from "react-redux";
import { getPlatformLogo } from "../../../assets/images/svg";
import { getDiagnosisById } from "../../services/diagnosis/getDiagnosisById";
import { IRootState } from "../../store-redux";
import isNilOrEmpty from "../../utils/isNilOrEmpty";
import IconButton from "../icon-button";
import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledDialogTitleWrapper,
  StyledLogo,
  StyledPlatformLog,
  StyledRow,
} from "./lead-history-log-dialog-styles";
import { TLogDialogProps } from "./types";
import { formatApiResponse } from "./helpers";

const LeadHistoryLogDialog = ({ isOpen, onClose, leadId }: TLogDialogProps) => {
  const { saasUserToken } = useSelector(
    (state: IRootState) => state.authenticated
  );
  const [platformsLogs, setPlatformsLogs] = useState<{ platform: string; api_response: string; }[]>([]);

  const getPlatFormLogs = useCallback(async () => {
    if (isEmpty(leadId)) return;

    const diagnosisDataResponse = await getDiagnosisById({
      leadId: leadId || '',
      accessToken: saasUserToken,
    });

    if(isNil(diagnosisDataResponse)) return;

    let logs = diagnosisDataResponse.map((check: any) => {
      const { platform, api_response } = check;
      return { platform, api_response };
    });

    logs = logs.filter((log:any) => !isNilOrEmpty(log.api_response));

    setPlatformsLogs(logs);
    
  }, [leadId, saasUserToken]);

  useEffect(() =>{
    getPlatFormLogs();
  }, [getPlatFormLogs]);

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <StyledDialogTitleWrapper>
        <StyledDialogTitle>Log das Plataformas</StyledDialogTitle>
        <IconButton
          onClick={onClose}
          iconSize={1.5}
        />
      </StyledDialogTitleWrapper>
      <StyledDialogContent>
        {
          platformsLogs.map(({ platform, api_response }) => {
            return (
              <StyledRow key={uuidV4()}>
                <StyledLogo>
                  { getPlatformLogo({ platform, className: 'icon' }) }
                  {platform}
                </StyledLogo>
                <StyledPlatformLog>{formatApiResponse(api_response)}</StyledPlatformLog>
              </StyledRow>
            )
          })
        }
      </StyledDialogContent>
    </StyledDialog>
  )
}

export default LeadHistoryLogDialog;