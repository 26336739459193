import { Avatar, Dialog } from '@mui/material';
import styled from 'styled-components';

export const StyledAvatar = styled(Avatar)` 
  background-color: ${props => props.theme.colors.primary} !important;
  margin-right: 3rem;
  cursor: pointer;
`;

export const StyledProfileDialog = styled(Dialog)`
  
  & .MuiDialog-container {
    position: absolute;
    left: 78%;
    top: -32%;
  }

  & .MuiBackdrop-root {
    background-color: transparent;
  }
`;