import { TextField } from "@mui/material";
import styled from "styled-components";

export const StyledInputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  gap: 0,625rem;

  label { 
    margin-bottom: 8px;
    font-size: 1rem;
    font-weight: normal;
    color: ${(props) => props.theme.colors.blackLight}
  }
`;

export const StyledInput = styled(TextField)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  
  & .MuiOutlinedInput-input {
    padding: 0.4rem;
  }

  p {
      width: 30%;
      font-size: 1rem;
      color: ${(props) => props.theme.colors.blackLight};
      display: flex;
      justify-content: center;
    }
`;
