import {
  StyledContainer,
  StyledHighlighter,
  StyledText,
  StyledTitle,
  StyledButton,
  StyledOptimizationInfo,
} from "./retest-styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TRetestProps } from "./types";
import { useNavigate } from "react-router-dom";

const Retest = ({
  hasOptionalButton,
  buttonTitle,
  slugEvent,
  buttonColor,
  buttonTextColor,
  redirect_link,
}: TRetestProps) => {
  const hasHttp = redirect_link?.includes('http') || redirect_link?.includes('https');
  const newLink = hasHttp ? redirect_link : `https://${redirect_link}`;
  const navigate = useNavigate();
  return (
    <StyledContainer>
      <StyledTitle>Otimize a presença online da sua empresa:</StyledTitle>

      <StyledOptimizationInfo>
        <CheckCircleIcon />
        <StyledText>
          Corrija tudo o que estiver
          <StyledHighlighter isorange> incorreto</StyledHighlighter>
        </StyledText>
      </StyledOptimizationInfo>
      <StyledOptimizationInfo>
        <CheckCircleIcon />
        <StyledText>
          Cadastre sua empresa onde estiver
          <StyledHighlighter isred> inexistente</StyledHighlighter>
        </StyledText>
      </StyledOptimizationInfo>
      <StyledOptimizationInfo>
        <CheckCircleIcon />
        <StyledText>
          Mantenha tudo atualizado e
          <StyledHighlighter> correto</StyledHighlighter>
        </StyledText>
      </StyledOptimizationInfo>
      <StyledOptimizationInfo>
        <CheckCircleIcon />
        <StyledText>
          Transforme sua empresa em uma
          <StyledHighlighter> máquina de atrair clientes</StyledHighlighter>
        </StyledText>
      </StyledOptimizationInfo>

      {hasOptionalButton && (
        <StyledButton
          color={buttonColor}
          textColor={buttonTextColor}
          href={newLink || ''}
          target="_blank"
        >
          {buttonTitle}
        </StyledButton>)
      }
      <StyledButton isblue onClick={() => navigate(`/${slugEvent}`)}>
        Refazer o teste
      </StyledButton>
    </StyledContainer>
  );
};

export default Retest;
