import {
  StyledImageLayer,
  StyledCenterBanner,
  StyledSide,
} from "./form-side-styles";

import { FormSideProps } from "./types";

const FormSide = ({
  bannerBackground = '',
  bannerImg = '',
  bannerText = '',
}: FormSideProps) => {
  return (
    <StyledSide background={bannerBackground}>
      <StyledCenterBanner src={bannerText}/>
      <StyledImageLayer src={bannerImg} />
    </StyledSide>
  );
};

export default FormSide;
