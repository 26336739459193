import React, { useCallback, useEffect, useRef, useState } from 'react';
import Header from '../../../../shared/components/header/header';
import { Box, Button, CircularProgress, IconButton, Stack, TableCell, TextField, Tooltip, Typography } from '@mui/material';
import { StyledTable } from '../../../../shared/components/events-table/events-table-styles';
import ExcelUploadModal from './dialogs/batch-form';
import Icon from '../../../../shared/components/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import SuccessIcon from '../../../../shared/components/icons/success';
import WarningIcon from '../../../../shared/components/icons/warning';
import Breadcrumbs from '../../../../shared/components/breadcrumbs/breadcrumbs';
import { Container, StyledTypography } from './batch-list-styles';
import { findAllBatchCheck } from '../../../../shared/services/batch-check/find-all';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { debounce } from 'lodash'
import { isNil } from 'ramda';
import DateRangePicker from '../../../../shared/components/date-range-picker/date-range-picker';
import { createCheck } from '../../../../shared/services/batch-check/create';
import { socket } from '../../../../shared/utils/socket';
import { useSnackbar } from 'notistack';
import { LoadingList } from '../single/single';


interface RowItem {
    id: number;
    name: string;
    date: string;
    status: 'PENDING' | 'FINISHED' | 'PROCESSING';
    statusTranslated: string;
    requester_email: string;
}

const RenderStatus = ({ value }: { value: RowItem }) => {
    const status = value.status;
    const statuses = {
        PROCESSING: <CircularProgress size={15} />,
        FINISHED: <SuccessIcon />,
        PENDING: <WarningIcon />,
    }
    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            {statuses[status] || ''}
            <StyledTypography ml={1} color="#374957">{value.statusTranslated}</StyledTypography>
        </Box>
    )
}

const BatchList: React.FC = () => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState({
        company: '',
        ofPeriod: null,
        toPeriod: null,
        page: 0,
        pageSize: 10
    })
    const [list, setList] = useState<RowItem[]>([]);
    const navigate = useNavigate();

    const handleClipboardCopyLink = (id: number) => {
        const currentUrl = `${window.location.origin}/diagnosis/batch/${id}/details`;
        navigator.clipboard.writeText(currentUrl).then(
            function () {
                enqueueSnackbar("Link copiado com sucesso!");
            },
            function (err) {
                enqueueSnackbar("Não foi possível copiar o texto: ", err);
            }
        );
    };

    const columns = [
        {
            name: "name",
            label: "Empresas",
            options: {
                customBodyRenderLite: (index: number) => {
                    const value = list[index];
                    return <StyledTypography>{value.name}</StyledTypography>
                }
            }
        },
        {
            name: "requester_email",
            label: 'Email',
            options: {
                customBodyRenderLite: (index: number) => {
                    const value = list[index];
                    return <StyledTypography>{value.requester_email}</StyledTypography>
                }
            }
        },
        {
            name: "date",
            label: 'Data',
            options: {
                customBodyRenderLite: (index: number) => {
                    const value = list[index];
                    return <StyledTypography>{value.date}</StyledTypography>
                }
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: false,
                customBodyRenderLite: (index: number) => <RenderStatus value={list[index]} />,
                customHeadRender: (v: any) => <TableCell align="center">{v.label.toUpperCase()}</TableCell>
            }
        },
        {
            name: "actions",
            label: "Ações",
            options: {
                setCellHeaderProps: () => ({ style: { display: 'flex', justifyContent: 'right', flexDirection: 'row-reverse', borderBottom: 'none' } }),
                customBodyRenderLite: (index: number) => {
                    const value = list[index];
                    return (
                        <Stack direction="row" gap={4} justifyContent="right">
                            <Tooltip placement='top' arrow title="Link do resultado">
                                <IconButton disabled={value.status !== 'FINISHED'} onClick={() => handleClipboardCopyLink(value.id)} color="primary" size='small'>
                                    <Icon name="share" prefix='sr' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip placement='top' arrow title="Locais">
                                <IconButton onClick={() => navigate(`/dashboard/batch/list/${value.id}`)} color="primary" size='small'>
                                    <Icon name="building" prefix='sr' />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    );
                },
            }
        },
    ];


    const onSuccess = (data: any) => {
        setList(data[0].map(({ status, company_name, created_at, id, statusTranslated, requester_email }: any) => ({ id, requester_email, name: company_name, date: format(new Date(created_at), 'dd/MM/yyyy', { locale: ptBR }), status, statusTranslated })))
        setCount(data[1]);
    }
    useEffect(() => {
        const onSuccessConnection = (data: any) => {
            onSuccess(data)
            socket.on('update-check', (updatedRow) => {
                setList((prev: any) =>
                    prev.map((item: any) => {
                        if (item.id === updatedRow.id) {
                            return {
                                ...item,
                                ...updatedRow,
                            };
                        } else {
                            return item;
                        }
                    })
                );
            });
        }
        fetch({}, onSuccessConnection)
        return () => {
            // socket.off('update-check')
        };
    }, []);


    const handleSearch = useCallback(debounce((value: string, obj: any) => {
        fetch({
            ...obj,
            company: value,
        })
    }, 1000)
        , []);


    const fetch = async (params = {}, onSuccessCb = onSuccess) => await findAllBatchCheck({ onSuccess: onSuccessCb, params, isLoading: setLoading })

    const breadcrumbItems = [
        { label: 'Check individual', href: '/dashboard/event/list' },
        { label: 'Check em massa', href: '/dashboard/batch/list' },
    ];

    const handleChangeDate = ([ofPeriod, toPeriod]: any) => {
        setParams((prev) => ({
            ...prev,
            ofPeriod,
            toPeriod,
            page: 0,
        }))
        if (isNil(toPeriod) && isNil(ofPeriod)) {
            fetch({
                company: params.company,
                page: 0,
            });
        }
        fetch({
            toPeriod,
            ofPeriod,
            company: params.company,
            page: 0
        })
    }


    const onSubmit = async (data: any) => {
        return await createCheck({
            data, onSuccess: () => {
                fetch()
                setOpen(false)
            }
        })
    };

    const onPageChange = (currentPage: number) => {
        setParams(prev => ({
            ...prev,
            page: currentPage
        }))
        fetch({
            ...params,
            page: currentPage,
        })
    }

    const onPageSizeChange = (pageSize: number) => {
        setParams(prev => ({
            ...prev,
            pageSize
        }))
        fetch({
            ...params,
            pageSize
        })
    }

    return (
        <>
            <Header />
            <Box sx={{ padding: '0px 64px', marginBottom: '24px' }}>
                <Box mt={4}>
                    <Breadcrumbs items={breadcrumbItems} />
                </Box>
                <Container>
                    <Typography variant="h4">Diagnósticos</Typography>
                    <Button
                        startIcon={<Icon name="add" prefix="sr" display='flex' />}
                        variant="contained" onClick={() => setOpen(true)}>Submeter arquivo</Button>
                </Container>
                <Stack direction="row" gap={2} mb={4}>
                    <TextField
                        fullWidth
                        label="Nome da empresa"
                        onChange={(e) => {
                            setParams((prev) => ({
                                ...prev,
                                company: e.target.value,
                                page: 0,
                            }))
                            handleSearch(e.target.value, { ...params, page: 0 })
                        }}
                        InputProps={{
                            endAdornment: <Icon name="search" prefix="rs" />
                        }}
                        variant="outlined"
                        size='small'
                        sx={{ bgcolor: 'white' }}
                    />
                    <DateRangePicker fullWidth onChange={handleChangeDate} />
                </Stack>
                <StyledTable
                    title={<Box display="flex" alignItems="center">
                        <Icon name='list' prefix="br" />
                        <Typography ml={2} sx={{ alignItems: 'center' }}>
                            Lista de diagnosticos
                        </Typography></Box>}
                    columns={columns}
                    data={list}
                    options={{
                        search: false,
                        download: false,
                        elevation: 0,
                        print: false,
                        count,
                        page: params.page,
                        rowsPerPage: params.pageSize,
                        filter: false,
                        serverSide: true,
                        viewColumns: false,
                        selectableRows: 'none',
                        onChangePage: onPageChange,
                        onChangeRowsPerPage: onPageSizeChange,
                        textLabels: {
                            body: {
                                noMatch:
                                    loading === true ? (
                                        <LoadingList />
                                    ) : (
                                        "Nenhum resultado encontrado"
                                    ),
                            },
                            pagination: {
                                next: "Próxima página",
                                previous: "Página anterior",
                                rowsPerPage: "Linhas por página",
                                displayRows: "de",
                            },
                            toolbar: {
                                search: "Pesquisar",
                                downloadCsv: "Baixar como CSV",
                                print: "Imprimir",
                                viewColumns: "Visualizar colunas",
                                filterTable: "Filtrar",
                            },
                            filter: {
                                all: "Todos",
                                title: "Título",
                                reset: "Resetar",
                            },
                            viewColumns: {
                                title: "Mostrar Colunas",
                                titleAria: "Mostrar/esconder colunas da tabela",
                            },
                            selectedRows: {
                                text: "linha(s) selecionada(s)",
                                delete: "Apagar",
                                deleteAria: "Apagar Linhas Selecionadas",
                            },
                        }
                    }} />
                <ExcelUploadModal open={open} onClose={() => setOpen(false)} onSubmit={onSubmit} />
            </Box>
        </>
    )
}

export default BatchList;