import axios from "axios";
import { TCreateDiagnosisProps } from "./types";

export const createDiagnosis = async ({
  background_color = '#000000',
  description = "Confira nos gráficos abaixo como está atualmente a qualidade da sua presença digital",
  has_additional_button = false,
  redirect_link,
  text_color = '#000000',
  title = "Seu relatório está pronto!",
  button_background_color = '#000000',
  button_text,
  button_text_color = '#000000',
  js_code,
  isFetching,
  accessToken,
}: TCreateDiagnosisProps) => {
  try {
    let body: any = {
      title,
      description,
      text_color,
      background_color,
      has_additional_button,
      js_code: js_code || '',
    };

    if(has_additional_button) {
      body = { 
        ...body,
        redirect_link,
        button_background_color,
        button_text,
        button_text_color,
      };
    }

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    }

    const { data } = await axios.post(
      `${process.env.REACT_APP_DIAGNOSIS_API_URL}/create`,
      body,
      { headers },
    );

    return data;
  } catch (error) {
    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
