import {
  StyledAddress,
  StyledAddressWrapper,
  StyledCity,
  StyledContactBtn,
  StyledContentHeader,
  StyledFooter,
  StyledFooterContentContainer,
  StyledIconContainer,
  StyledLogo,
  StyledSocialLink,
  StyledText
} from './form-footer-styles';

import Logo from '../../../assets/images/logo_hublocal_branco.png';
import initialPoweredBy from '../../../assets/images/powered_by.png';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedinIcon from '@mui/icons-material/LinkedIn';
import YoutubeIcon from '@mui/icons-material/YouTube';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { TFormFooterProps } from './types';

const FormFooter = ({
  backgroundColor = '#0F2C54',
  mainLogo = Logo,
  poweredBy = initialPoweredBy,
  textColor = '#FFFFFF',
}: TFormFooterProps) => {
  return (
    <StyledFooter backgroundColor={backgroundColor} textColor={textColor}>
      <StyledFooterContentContainer center>
        <StyledLogo src={mainLogo} alt="Hublocal" />
        <img src={poweredBy || initialPoweredBy} alt="patrocinadores" width={'264px'} />
      </StyledFooterContentContainer>
      <StyledFooterContentContainer>
        <StyledContentHeader>Fale conosco</StyledContentHeader>
        <StyledText>Ficou alguma dúvida sobre os nossos serviços ou quer conhecer melhor nossos planos?</StyledText>
        <StyledContactBtn href='https://bit.ly/3QA18sr' target='_blank'>Entre em contato</StyledContactBtn>
        <StyledIconContainer>
          <StyledSocialLink backgroundColor={backgroundColor} href="https://www.facebook.com/HubLocalOficial/" target="_blank">
            <FacebookIcon />
          </StyledSocialLink>
          <StyledSocialLink backgroundColor={backgroundColor} href="https://www.instagram.com/hub.local/?hl=pt" target="_blank">
            <InstagramIcon />
          </StyledSocialLink>
          <StyledSocialLink backgroundColor={backgroundColor} href="https://www.linkedin.com/company/hublocaloficial" target="_blank">
            <LinkedinIcon />
          </StyledSocialLink>
          <StyledSocialLink backgroundColor={backgroundColor} href="https://www.youtube.com/channel/UC_r-VTrVBOgEDMjvJ94o8-A" target="_blank">
            <YoutubeIcon />
          </StyledSocialLink>
        </StyledIconContainer>
      </StyledFooterContentContainer>
      <StyledAddress>
        <StyledContentHeader>
          <FmdGoodIcon />
          HubSpaces
        </StyledContentHeader>
        <StyledAddressWrapper>
          <StyledCity>Fortaleza</StyledCity>
          <StyledText>Av.Des. Moreira, S/N Palladium Business</StyledText>
          <StyledText>Center Sala 1305 a 1308 - Meireles</StyledText>
        </StyledAddressWrapper>
        <StyledAddressWrapper>
          <StyledCity>São Paulo</StyledCity>
          <StyledText>Alameda Vicente Pinzon, 54 - Vila</StyledText>
          <StyledText>OLímpia, São Paulo - SP, 04547-130</StyledText>
        </StyledAddressWrapper>
      </StyledAddress>
    </StyledFooter>
  );
}

export default FormFooter;
