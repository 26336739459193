import styled from 'styled-components';

export const StyledContainer = styled.main`
  max-width: 100%;
  background-color: ${props => props.theme.page.body};
  margin-bottom: 4rem;
`;

export const StyledButton = styled.div`
  margin-left: 4.5rem;
  padding-top: 1.6rem;
  padding-bottom: 1rem;

  display: flex;
  justify-content: space-between;
`;

export const GroupButtonRight = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 4.5rem;
`;

export const StyledTables = styled.div`
  margin-left: 4.5rem;
  margin-right: 4.5rem;
`;
