import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { StyledDiagnosisStatus } from './relevance-bar.styles';
import { TRelevanceBarProps } from './types';

const RelevanceBar = ({ relevance }: TRelevanceBarProps) => {
  return relevance === 'low' ? (
    <StyledDiagnosisStatus color="#FF5C5C">
      <SentimentVeryDissatisfiedIcon />
      BAIXA RELEVÂNCIA
    </StyledDiagnosisStatus>
  ) : (
    <StyledDiagnosisStatus color="#00DA91">
      <SentimentSatisfiedAltIcon />
      ALTA RELEVÂNCIA
    </StyledDiagnosisStatus>
  );
}

export default RelevanceBar;