import { TableCell, TableRow, TableHead } from "@mui/material";

import styled, { css } from "styled-components";
import { devices } from "../../../assets/styles/devices";

export const StyledTableCell = styled(TableCell)``;

export const StyledTableHead = styled(TableHead)`
  .MuiTableCell-root {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    text-align: center;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
  }
`;

export const StyledTableRow = styled(TableRow)``;

export const StyledDirectoryLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Poppins";
  gap: 0.5rem;
  font-weight: 600;
  width: 4rem;
  height: 4rem;

  @media ${devices.desktop} {
    width: 5rem;
    height: 5rem;
  }
`;

export const StyledCompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  text-align: center;
  font-family: "Poppins";
  flex: 1;
  padding: 0rem 0.5rem;
  justify-content: center;
  height: 130px;

  @media ${devices.tabletLandscape} {
    height: initial;
    justify-content: flex-start;
    padding: 0;
  }

  @media print {
    justify-content: space-evenly;
    text-align: center;
  }
`;

export const StyledCompanyNonRelevant = styled.span`
  color: #ff9920;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const StyledCompanyName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;

  @media ${devices.mobile} {
    max-width: 300px;
  }

  @media ${devices.tablet} {
    max-width: 350px;
  }


  @media ${devices.tabletLandscape} {
    width: initial;
    max-width: none;
    white-space: normal;
    overflow: auto;
    text-overflow: clip;
  }
`;

export const StyledCompanyAddress = styled.span`
  font-size: 0.9rem;

  @media ${devices.tabletLandscape} {
    font-size: 0.875rem;
  }
`;

export const StyledTableWrapper = styled.div<{ isUserView?: boolean }>`
  border: 5px solid ${(props) => props.theme.colors.primary};
  border-radius: 0.75rem;
  display: none;

  ${(props) =>
    !props.isUserView &&
    css`
      display: flex;
    `}

  ${(props) =>
    props.isUserView &&
    css`
      @media ${devices.tabletLandscape} {
        display: block;
      }
    `}
`;

export const StyledCompanyNotFound = styled.span`
  color: #ff5c5c;
  font-size: 1rem;

  @media ${devices.tabletLandscape} {
    font-size: 0.875rem;
  }
`;

export const StyledServiceNotAvailable = styled.span`
  color: ${(props) => props.theme.colors.grey};
  font-size: 1rem;

  @media ${devices.tabletLandscape} {
    font-size: 0.875rem;
  }
`;
