import {
  StyledAbout,
  StyledAccordionContainer,
  StyledBenefitsContainer,
  StyledBenefitsMain,
  StyledFAQContainer,
  StyledFeedbacksCardsContainer,
  StyledFeedbacksContainer,
  StyledFeedbacksTitle,
  StyledLandingPageContainer,
  StyledPlatformContainer,
  StyledPlatformMain,
  StyledQuestionAnswer,
  StyledQuestionTitle,
  StyledTitle,
} from "./landing-page-styles";

import Platform from "../../../assets/images/svg/landing-page/plataforma.svg";
import Widgets from "../../../assets/images/svg/landing-page/widgets.svg";
import Button from "./button";
import BenefitsList from "./benefits-list/benefits-list";
import Card from "./card";
import { cardContent } from "./card/card-helper";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Add } from "@mui/icons-material";
import { accordionHelper } from "./helpers";

type TProps = {
  redirectLink: string | undefined;
  buttonTitle: string;
  hasOptionalButton: boolean;
};

const LandingPage = ({
  redirectLink,
  hasOptionalButton,
  buttonTitle,
}: TProps) => {
  return (
    <StyledLandingPageContainer>
      <StyledPlatformContainer>
        <img
          src={Platform}
          alt="Imagem representando uma dashboard da HubLocal"
        />
        <StyledPlatformMain>
          <StyledTitle>
            Melhore a presença online da sua empresa e conquiste
            <br /> novos clientes
          </StyledTitle>
          <StyledAbout>
            Com a plataforma única da HubLocal, o seu negócio ganha destaque nos
            principais sites de busca e mapas da internet, ampliando a sua
            presença online e atraindo mais clientes até o seu estabelecimento.
          </StyledAbout>
          {hasOptionalButton && (
            <Button text={buttonTitle} action={redirectLink} />
          )}
        </StyledPlatformMain>
      </StyledPlatformContainer>
      <StyledBenefitsContainer>
        <h2>
          Conheça todas as vantagens que a<br /> HubLocal tem a oferecer para o
          seu negócio
        </h2>
        <StyledBenefitsMain
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BenefitsList />
          <img
            src={Widgets}
            alt="Imagem de widgets e telas da plataforma HubLocal"
          />
        </StyledBenefitsMain>
        {hasOptionalButton && (
          <Button text={buttonTitle} action={redirectLink} />
        )}
      </StyledBenefitsContainer>
      <StyledFeedbacksContainer>
        <StyledFeedbacksTitle>
          Confira os depoimentos de quem já corrigiu
          <br /> suas informações com a plataforma da HubLocal
        </StyledFeedbacksTitle>
        <main>
          <StyledFeedbacksCardsContainer>
            {cardContent.map(({ text, position, logo, avatar, author }) => (
              <Card
                key={author}
                text={text}
                position={position}
                logo={logo}
                avatar={avatar}
                author={author}
              />
            ))}
          </StyledFeedbacksCardsContainer>
          {hasOptionalButton && (
            <Button text={buttonTitle} outlined={true} action={redirectLink} />
          )}
        </main>
      </StyledFeedbacksContainer>
      <StyledFAQContainer>
        <main>
          <header>
            <h2>Perguntas frequentes</h2>
          </header>
        </main>
        <StyledAccordionContainer>
          {accordionHelper.map(({ question, answer }) => (
            <Accordion key={question}>
              <AccordionSummary expandIcon={<Add />}>
                <StyledQuestionTitle>{question}</StyledQuestionTitle>
              </AccordionSummary>
              <AccordionDetails>
                <StyledQuestionAnswer>{answer}</StyledQuestionAnswer>
              </AccordionDetails>
            </Accordion>
          ))}
        </StyledAccordionContainer>
      </StyledFAQContainer>
    </StyledLandingPageContainer>
  );
};

export default LandingPage;
