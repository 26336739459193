import { getPlatformLogo } from "../../../assets/images/svg";
import { v4 as uuidV4 } from "uuid";
import {
  StyledCompanyAddress,
  StyledCompanyInfo,
  StyledCompanyName,
  StyledCompanyNonRelevant,
  StyledCompanyNotFound,
  StyledServiceNotAvailable,
} from "../diagnosis-table/diagnosis-table-styles";

import {
  StyledDirectoryLogo,
  StyledRowContent,
  StyledTableBody,
  StyledTableMobile,
  StyledTableRow,
  StyledHeader,
  StyledEmptyCell,
} from "./diagnosis-table-mobile-styles";

import { TDiagnosisTableMobileProps } from "./types";
import LocalStatusInfo from "../local-status-info";
import { Help } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const platformsWithPhoneUnavailable = ["Facebook", "Instagram"];

const DiagnosisTableMobile = ({ data }: TDiagnosisTableMobileProps) => {
  return (
    <StyledTableMobile>
      <StyledTableBody>
        {data.map((rowData) => (
          <StyledTableRow key={uuidV4()}>
            <StyledRowContent>
              <StyledDirectoryLogo>
                {getPlatformLogo({ platform: rowData.directory || "" })}
                {rowData.directory === "Google"
                  ? "Google Maps"
                  : rowData.directory}
              </StyledDirectoryLogo>
              <StyledCompanyInfo>
                {rowData.not_available ? (
                  <StyledServiceNotAvailable>
                    Indisponível
                  </StyledServiceNotAvailable>
                ) : !rowData.address && !rowData.company ? (
                  <StyledCompanyNotFound>Não encontrado</StyledCompanyNotFound>
                ) : rowData.address === "irrelevant" ? (
                  <StyledCompanyNonRelevant>
                    Local com Baixa Relevância
                    <Tooltip
                      title="Devido a uma limitação da plataforma, locais que não possuem avaliações no Yelp são considerados de 'baixa relevância', podendo interferir nos resultados da nossa pesquisa"
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <Help />
                    </Tooltip>
                  </StyledCompanyNonRelevant>
                ) : (
                  <>
                    <StyledCompanyName title={rowData.company || ""}>
                      {rowData.company}
                    </StyledCompanyName>
                    <StyledCompanyAddress>
                      {rowData.address}
                    </StyledCompanyAddress>
                  </>
                )}
              </StyledCompanyInfo>
            </StyledRowContent>
            <StyledRowContent>
              <StyledHeader>Nome</StyledHeader>
              <StyledHeader>Endereço</StyledHeader>
              <StyledHeader>Telefone</StyledHeader>
            </StyledRowContent>
            <StyledRowContent>
              {!rowData.not_available && rowData.company !== "irrelevant" ? (
                <LocalStatusInfo status={rowData.name_status} />
              ) : (
                <StyledEmptyCell />
              )}
              {!rowData.not_available && rowData.address !== "irrelevant" ? (
                <LocalStatusInfo status={rowData.address_status} />
              ) : (
                <StyledEmptyCell />
              )}
              {!rowData.not_available && rowData.phone !== "irrelevant" ? (
                <LocalStatusInfo
                  status={
                    platformsWithPhoneUnavailable.includes(rowData.directory!)
                      ? "unavailable"
                      : rowData.phone_number_status
                  }
                />
              ) : (
                <StyledEmptyCell />
              )}
            </StyledRowContent>
          </StyledTableRow>
        ))}
      </StyledTableBody>
    </StyledTableMobile>
  );
};

export default DiagnosisTableMobile;
