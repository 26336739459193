import { useCallback } from "react";
import { isEmpty, isNil } from "ramda";
import { MenuItem } from "@mui/material";

import { TFormInputProps } from "./types";

import {
  StyledInput,
  StyledInputMask,
  StyledInputWrapper,
  StyledLabel,
  StyledSelect,
  StyledSelectPlaceholder,
} from "./form-input-styles";

const FormInput = ({
  label,
  name,
  placeHolder,
  setValue,
  value,
  mask,
  isSelect,
  options,
}: TFormInputProps) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const isJustNumbers = e.target.value.match(/\D/);
    const isMaxCompanyPhoneSize =
      name === "companyPhone" && e.target.value.length > 14;

    if (
      (name === "companyPhone" && !isNil(isJustNumbers)) ||
      isMaxCompanyPhoneSize
    ) {
      return;
    }
    setValue((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const selectedHasFilled = !isEmpty(value[name]);

  const selectItemFromSelectInput = useCallback(
    (value: string) => {
      const option = options?.find((option) => option.value === value);

      return option;
    },
    [options]
  );

  function onRenderValueInSelect() {
    if (!selectedHasFilled)
      return (
        <StyledSelectPlaceholder>Selecione uma opção</StyledSelectPlaceholder>
      );

    const optionSelected = selectItemFromSelectInput(value[name]);
    return optionSelected?.text;
  }

  return (
    <>
      <StyledLabel textColor="#FFFFFF">{label}</StyledLabel>
      {mask && !isSelect && (
        <StyledInputWrapper>
          <StyledInputMask
            mask={mask}
            value={value[name]}
            name={name}
            onChange={(e) =>
              setValue((prev) => ({ ...prev, [name]: e.target.value }))
            }
            required
            placeholder={placeHolder}
            type="tel"
          />
        </StyledInputWrapper>
      )}
      {!mask && !isSelect && (
        <StyledInput
          value={value[name]}
          variant="standard"
          name={name}
          onChange={handleChange}
          required
          placeholder={placeHolder}
          type={name === "companyPhone" ? "tel" : "text"}
        />
      )}

      {isSelect && (
        <StyledSelect
          variant="standard"
          value={!selectedHasFilled ? "placeholder" : value[name]}
          onChange={({ target }) => {
            setValue((prev) => ({ ...prev, [name]: String(target.value) }));
          }}
          required
          renderValue={onRenderValueInSelect}
        >
          {options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </StyledSelect>
      )}
    </>
  );
};

export default FormInput;
