import { Stack } from "@mui/material";
import { lighten } from "polished";
import styled, { keyframes } from "styled-components";

interface WaveProps {
  fillTo: number;
}

export const Container = styled(Stack)`
  border: 2px solid;
  border-color: #ffffff;
  border-radius: 32px;
  background: radial-gradient(
      90.16% 143.01% at 15.32% 21.04%,
      rgba(165, 239, 255, 0.2) 0%,
      rgba(110, 191, 244, 0.0447917) 77.08%,
      rgba(70, 144, 213, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  padding: 24px;

  @media only print {
    flex-direction: row !important;
    align-items: left !important;
  }
`;

const waveAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(3600deg); }
`;

const waveAnimation2 = keyframes`
  0% { transform: rotate(30deg); }
  100% { transform: rotate(3630deg); }
`;

const waveAnimation3 = keyframes`
  0% { transform: rotate(60deg); }
  100% { transform: rotate(3660deg); }
`;

const fillAnimation = (fillTo: number) => keyframes`
  from { top: 110% }
  to { top: ${(100 - fillTo) * 1.1}% }
`;

export const Circle = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: white;
  overflow: hidden;

  @media only print {
    width: 140px;
    height: 140px;
  }
`;

const Wave = styled.div<WaveProps>`
  position: absolute;
  top: 110%;
  height: 200%;
  width: 200%;
  border-radius: 39%;
  left: -50%;
`;

export const Wave1 = styled(Wave)`
  background-color: #3f68c5;
  animation: ${waveAnimation} 60s linear infinite,
    ${(props) => fillAnimation(props.fillTo)} 5s forwards;
  opacity: 0.7;
  @media only print {
 display: none;
  }
`;

export const Wave2 = styled(Wave)`
  background-color: #004abe;
  transform: rotate(30deg);
  animation: ${waveAnimation2} 40s linear infinite,
    ${(props) => fillAnimation(props.fillTo)} 5s forwards;
  opacity: 0.7;
  @media only print {
 display: none;
  }
`;

export const Wave3 = styled(Wave)`
  background-color: #009bde;
  transform: rotate(60deg);
  animation: ${waveAnimation3} 30s linear infinite,
    ${(props) => fillAnimation(props.fillTo)} 5s forwards;
  opacity: 0.7;
  @media only print {
 display: none;
  }
`;

export const PercentageText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 40px;
  background: linear-gradient(135deg, #00b8b9, #20e198, #65ffc4);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media only print {
    background: none;
    color: ${lighten(0.6, 'black')}
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    font-size: 20px;
  }
`;
