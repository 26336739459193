import axios from "axios";
import { Dispatch, SetStateAction } from "react";

type TDeleteSponsorProps = {
    accessToken: string | null;
    id?: string;
    isFetching?: Dispatch<SetStateAction<boolean>>;
}
export const deleteSponsorFooterForm = async({ 
    accessToken,
    id,
    isFetching,
}: TDeleteSponsorProps) => {
    try {
        if (isFetching) isFetching(true);
        const { data } = await axios.delete(
            `${process.env.REACT_APP_SPONSOR_API_URL}/delete/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,    
                }
            },
        );
        return data;
    } catch (err) {
        return null;
    } finally {
        if (isFetching) isFetching(false);
    }
}