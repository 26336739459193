import { Avatar } from '@mui/material';
import styled from 'styled-components';

export const StyledConteiner = styled.div`
  display: flex;
  justify-items: center;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.grey};
  word-break: break-all;
  min-width: 19rem;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  gap: 1rem;
`;

export const StyledAvatarProfile = styled(Avatar)`
  width: 80px !important;
  height: 80px !important;
  font-size: 50px !important;
  background-color: ${props => props.theme.colors.primary} !important; 
`;

export const StyledProfile = styled.div`
  display: flex;
  flex-direction: column;
  

  & strong {
    color: ${props => props.theme.colors.primary};
    font-size: 1rem;
    font-weight: bold;
  }

  & span {
    color: ${props => props.theme.colors.blackLight};
    font-size: 0.8rem;
    font-weight: normal;
  }
`;

export const StyledFooter = styled.div`
  border-top: 1px solid ${props => props.theme.colors.grey};
  margin: 0;
  padding: 1.2rem;
`;

export const StyledLogout = styled.div`
  color: ${props => props.theme.colors.blackLight};
  gap: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;