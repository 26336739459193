import { TableCell, TableRow } from '@mui/material';
import styled from 'styled-components';

export const StyledTableRow = styled(TableRow)``;

export const StyledTableCell = styled(TableCell)``;

export const StyledActions = styled.div`
    display: flex;
    column-gap: 0.5rem;
`;