import styled from "styled-components";
import { breakpoints } from "../../../assets/styles/devices";

export const StyledLocalStatusInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 0.5rem;
  font-family: "Poppins";

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    flex: 1;
    padding: 0.5rem 0;
    width: 100%;

    & + & {
      border-left: 1px solid ${props => props.theme.colors.primary};
    }
  }

  @media print {
    flex: 1;
    padding: 0.5rem 0;
    width: 100%;

    & + & {
      border-left: 1px solid ${props => props.theme.colors.primary};
    }
  }
`;

export const StyledStatusText = styled.span<{ color: string }>`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1;
  color: ${(props) => props.color};

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    display: none;
  }

  @media print {
    display: none;
  }
`;

export const StyledLocalStatusIconWrapper = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border-radius: 50%;
  color: ${(props) => props.theme.colors.white};
  font-size: 1.2rem;
  background-color: ${(props) => props.color};

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    font-size: 0.7rem;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    font-size: 1.2rem;
  }
`;
