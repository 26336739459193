import LogoutIcon from '@mui/icons-material/Logout';
import {
  StyledAvatarProfile,
  StyledConteiner,
  StyledFooter,
  StyledHeader, StyledLogout, StyledProfile
} from './profile-info-styled';
import { TProfileInfoProps } from './types';

const ProfileInfo = ({signOut, user, email, letraAvatar}: TProfileInfoProps) => {

return (
  <StyledConteiner>
    <StyledHeader>
      <StyledAvatarProfile>
        {letraAvatar}
      </StyledAvatarProfile>
      <StyledProfile>
        <strong>{user}</strong>
        <span>{email}</span>
      </StyledProfile>
    </StyledHeader>
    <StyledFooter>
      <StyledLogout
        onClick={ signOut }
      >
        <LogoutIcon/>
        Sair
      </StyledLogout>
    </StyledFooter>
  </StyledConteiner>
)
};

export default ProfileInfo