import Chat from "../../../../assets/images/svg/landing-page/icons/chat.svg";
import Check from "../../../../assets/images/svg/landing-page/icons/check.svg";
import Search from "../../../../assets/images/svg/landing-page/icons/search.svg";
import Location from "../../../../assets/images/svg/landing-page/icons/location.svg";
import {
  StyledBenefitItem,
  StyledBenefitsListContainer,
} from "./benefits-list-styles";

const benefits = [
  {
    description:
      "Atrair mais clientes melhorando a visibilidade do seu negócio na internet;",
    icon: Location,
  },
  {
    description: "Aumentar o número de ligações para o seu estabelecimento;",
    icon: Search,
  },
  {
    description:
      "Monitorar e responder rapidamente a todas as avaliações online da sua empresa;",
    icon: Check,
  },
  {
    description:
      "Ser encontrado na internet quando pessoas pesquisarem pelos seus produtos ou serviços.",
    icon: Chat,
  },
];

const BenefitsList = () => {
  return (
    <StyledBenefitsListContainer>
      {benefits.map(({ description, icon }) => (
        <>
          <StyledBenefitItem key={description}>
            <img src={icon} alt={description} />
            <p>{description}</p>
          </StyledBenefitItem>
          <hr />
        </>
      ))}
    </StyledBenefitsListContainer>
  );
};

export default BenefitsList;
