import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WazeIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox="0 0 501 501" {...props}>
            <g clip-path="url(#clip0_1278_24955)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M275.398 426.259L224.279 426.227C221.471 412.049 213.835 399.282 202.673 390.1C191.511 380.918 177.512 375.887 163.059 375.865C152.568 375.792 142.23 378.386 133.015 383.402C123.801 388.418 116.011 395.692 110.377 404.542L110.361 404.945C91.6146 395.257 74.55 382.615 59.8215 367.503C42.2126 349.637 32.6428 333.3 28.2607 322.973C39.7275 320.171 50.1361 314.105 58.2266 305.509C69.0202 293.837 74.9849 278.505 74.9173 262.607V225.278C74.9173 180.813 89.6746 137.378 116.918 102.322C155.857 52.2174 213.485 24.1848 275.398 24.1848C328.693 24.1848 378.91 45.0965 416.754 83.1016C435.413 101.715 450.207 123.835 460.282 148.189C470.358 172.542 475.516 198.649 475.461 225.004C475.461 278.653 454.662 329.079 416.866 367.02C379.957 404.075 329.305 426.259 275.415 426.259" fill="white" />
                <mask id="mask0_1278_24955" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="-1" y="-1" width="502" height="503">
                    <path d="M-0.0771484 -0.786734H500.385V501.383H-0.0771484V-0.786734Z" fill="black" />
                </mask>
                <g mask="url(#mask0_1278_24955)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M275.393 426.258L224.274 426.226C221.465 412.048 213.83 399.281 202.668 390.099C191.506 380.917 177.507 375.886 163.054 375.864C152.562 375.791 142.225 378.385 133.01 383.401C123.796 388.417 116.006 395.691 110.372 404.541L110.356 404.943C91.6094 395.255 74.5448 382.613 59.8164 367.502C42.2074 349.635 32.6377 333.299 28.2556 322.972C39.7223 320.17 50.1309 314.104 58.2214 305.508C69.015 293.836 74.9797 278.504 74.9121 262.606V225.277C74.9121 180.812 89.6695 137.377 116.913 102.32C155.852 52.2163 213.48 24.1837 275.393 24.1837C328.687 24.1837 378.904 45.0954 416.748 83.1005C435.408 101.713 450.201 123.834 460.277 148.188C470.353 172.541 475.511 198.648 475.456 225.003C475.456 278.652 454.657 329.078 416.861 367.019C379.952 404.073 329.3 426.258 275.409 426.258M500.379 225.003C500.442 195.37 494.644 166.017 483.319 138.633C471.994 111.249 455.365 86.3755 434.39 65.4432C391.841 22.7338 335.389 -0.787823 275.442 -0.787823C206.714 -0.787823 142.657 29.9513 98.9331 84.9693C67.3011 124.875 50.0971 174.307 50.1177 225.229V262.686C50.1177 281.761 35.1348 300.546 9.82494 300.578C7.76307 300.587 5.75827 301.257 4.10529 302.489C2.4523 303.721 1.23834 305.452 0.641854 307.425C-4.06246 323.085 15.0126 357.626 42.0463 385.063C59.2414 402.55 79.2427 417.035 101.221 427.917C99.6473 436.917 100.06 446.151 102.431 454.974C104.802 463.797 109.073 471.995 114.945 478.993C120.817 485.992 128.148 491.622 136.425 495.49C144.702 499.357 153.724 501.368 162.86 501.382C177.331 501.45 191.373 496.477 202.575 487.315C213.777 478.154 221.438 465.378 224.242 451.181L276.731 451.213C283.723 486.689 320.471 511 359.781 497.676C368.249 494.779 375.955 490.011 382.328 483.728C388.701 477.444 393.577 469.805 396.594 461.379C402.458 445.011 401.637 429.126 396.304 415.528C410.121 406.652 422.935 396.302 434.518 384.66C455.485 363.707 472.1 338.813 483.406 311.412C494.712 284.012 500.486 254.645 500.395 225.003" fill="black" />
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M400.37 174.916C400.37 161.061 389.173 149.816 375.366 149.816C361.559 149.816 350.346 161.045 350.346 174.916C350.346 188.772 361.559 200.033 375.366 200.033C389.173 200.033 400.386 188.788 400.386 174.916M250.186 174.916C250.186 161.061 238.989 149.816 225.166 149.816C211.36 149.816 200.146 161.045 200.146 174.916C200.146 188.772 211.36 200.033 225.166 200.033C238.989 200.033 250.186 188.788 250.186 174.916ZM232.819 257.145C231.786 255.001 230.166 253.194 228.147 251.933C226.128 250.673 223.793 250.011 221.413 250.024C219.316 250.022 217.253 250.547 215.414 251.553C213.574 252.559 212.018 254.012 210.889 255.779C209.76 257.545 209.095 259.568 208.955 261.66C208.814 263.751 209.204 265.845 210.087 267.746C218.148 284.957 230.945 299.516 246.98 309.719C263.014 319.922 281.623 325.346 300.629 325.358C319.634 325.346 338.243 319.922 354.278 309.719C370.313 299.516 383.11 284.957 391.171 267.746C392.054 265.843 392.444 263.749 392.303 261.656C392.162 259.563 391.495 257.539 390.364 255.772C389.233 254.005 387.675 252.552 385.833 251.547C383.992 250.543 381.927 250.019 379.829 250.024H379.507C377.175 250.042 374.896 250.722 372.936 251.986C370.976 253.249 369.417 255.044 368.439 257.161C362.374 270.03 352.779 280.911 340.772 288.541C328.765 296.17 314.839 300.233 300.613 300.257C286.39 300.225 272.47 296.157 260.468 288.525C248.467 280.893 238.879 270.012 232.819 257.145" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_1278_24955">
                    <rect width="500" height="500" fill="white" transform="translate(0.384766 0.153931)" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default WazeIcon;