import styled from "styled-components";

export const StyledInformationFormWrapper = styled.div``;

export const StyledInfoFormHeader = styled.header`
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  background: ${(props) => props.theme.colors.primary};
  padding: 0.5rem;
  color: ${(props) => props.theme.colors.white};
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
`;

export const StyledFormData = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid #c2c2c2;
  border-radius: 0rem 0rem 0.25rem 0.25rem;
`;

export const StyledFormRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledFormLabel = styled.span`
  font-weight: 600;
`;

export const StyledFormValue = styled.span``;
