import { Box } from '@mui/material';
import { lighten } from 'polished';
import styled from 'styled-components';

export const StyledLocationScore = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
  justify-content: center;

  .location-score {
    color: ${props => lighten(0.6, props.theme.colors.black)};
  }
`;

export const StyledChildren = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 7rem;
`;

export const StyledStrong = styled.strong`
  color: #FFFFFF;
`;

export const StyledScore = styled.h2`
  color: ${props => props.theme.colors.red};
`;

export const CircleContainer = styled(Box)`
  @media only print {
    width: 180px;
    height: 180px;
  }
`;
