import styled from "styled-components";
import { ReactComponent as LoadingSvg } from '../../../assets/images/loading.svg';
import { breakpoints } from "../../../assets/styles/devices";

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    justify-content: flex-end;
    margin-bottom: 4vh;
  }
`;

export const StyledText = styled.p`
  color: ${props => props.theme.colors.white};
  font-size: 1.5rem;
  font-weight: bold;

  @media screen and (max-width: ${breakpoints.mobile}) {
    font-size: 1rem;
  }
`;

export const StyledIcon = styled(LoadingSvg)<{ color?: string;}>`
  width: 100px;
  height: 100px;

  path {
    fill: ${props => props.color || props.theme.colors.primary};
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
      width: 80px;
      height: 80px;
  }
`;