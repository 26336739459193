import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const TomtomIcon: React.FC<SvgIconProps> = (props) => {
    return <SvgIcon viewBox="0 0 501 500" {...props}>
        <g clip-path="url(#clip0_1278_25197)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M249.924 500L316.091 385.708H183.758L249.924 500ZM249.924 262.167C226.028 262.178 203.104 252.707 186.184 235.834C169.264 218.96 159.729 196.063 159.674 172.167C159.729 148.271 169.264 125.373 186.184 108.5C203.104 91.6263 226.028 82.1557 249.924 82.1668C273.82 82.1557 296.744 91.6263 313.665 108.5C330.585 125.373 340.119 148.271 340.174 172.167C340.119 196.063 330.585 218.96 313.665 235.834C296.744 252.707 273.82 262.178 249.924 262.167ZM249.924 0.00012207C154.716 0.00012207 77.2783 77.2293 77.2783 172.167C77.2783 267.104 154.716 344.333 249.924 344.333C345.133 344.333 422.57 267.104 422.57 172.167C422.57 77.2293 345.112 0.00012207 249.924 0.00012207Z" fill="#DF1B12" />
        </g>
        <defs>
            <clipPath id="clip0_1278_25197">
                <rect width="500.001" height="500" fill="white" transform="translate(0.276367)" />
            </clipPath>
        </defs>
    </SvgIcon>
}

export default TomtomIcon;