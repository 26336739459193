import React, { useEffect, useState } from "react";
import LocaleCard, { LocaleCardProps } from "./locale-card/locale-card";
import { Button, Stack } from "@mui/material";
import Icon from "./Icon/Icon";

type Props = {
  cards: LocaleCardProps[];
};

const ExpandedLocaleCards: React.FC<Props> = ({ cards }) => {
  const [expandeds, setExpandeds] = useState<number[]>([]);
  const [isAllExpanded, setIsAllExpanded] = useState(true);

  const handleAccordionChange = (index: number) => {
    if (expandeds.includes(index)) {
      setExpandeds((prevExpandeds) =>
        prevExpandeds.filter((item) => item !== index)
      );
    } else {
      setExpandeds((prevExpandeds) => [...prevExpandeds, index]);
    }
  };

  const expandAll = () => {
    setIsAllExpanded(true);
  };

  const collapseAll = () => {
    setIsAllExpanded(false);
  };

  useEffect(() => {
    if (isAllExpanded)
      setExpandeds(Array.from({ length: cards.length }, (_, index) => index));
    else setExpandeds([]);
  }, [cards, isAllExpanded]);

  const toggleExpanded = () => {
    if (isAllExpanded) {
      collapseAll();
    } else {
      expandAll();
    }
  };

  return (
    <Stack gap={2} mx="auto">
      <Stack bgcolor="white" maxWidth="200px">
        <Button
          onClick={toggleExpanded}
          startIcon={
            <Icon
              name={`${isAllExpanded ? "caret-up" : "caret-down"}`}
              prefix="sr"
            />
          }
          variant="outlined"
        >
          {isAllExpanded ? "Recolher todos" : "Expandir todos"}
        </Button>
      </Stack>
      {cards.map((props, index) => (
        <LocaleCard
          {...props}
          open={expandeds.includes(index)}
          onChange={() => handleAccordionChange(index)}
        />
      ))}
    </Stack>
  );
};

export default ExpandedLocaleCards;
