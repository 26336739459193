/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from "react";
import { isNil, isEmpty } from 'ramda';
import { TEventInfoEditFormEntity } from "../../../../pages/create-event/types";
import SlugInput from "../../slug-input";
import { StyledContainer,StyledHeader, StyledTitle, StyledFooter, StyledInputBox, StyledInput, StyledSelect, StyledMenuItem, StyledUrl } from "./event-info-edit-form-styled"
import { statusSelected } from "./helpers";

export type TEventInfoEditFormProps = {
  eventInfoData: TEventInfoEditFormEntity,
  setEventInfoData: any,
  urlEvent: string,
  eventId?: string;
  initialSlug?: string;
  setIsValidSlug(value: boolean): void;
  isValidSlug: boolean;
  hasNoDefaultSlug: boolean;
};

const eventInfoEditForm = ({
  eventInfoData,
  setEventInfoData,
  urlEvent,
  eventId,
  initialSlug,
  isValidSlug,
  setIsValidSlug,
  hasNoDefaultSlug = false,
}: TEventInfoEditFormProps) => {
  const [defaultSlug, setDefaultSlug] = useState('');

  useEffect(() => {
    if (isNil(initialSlug) || isEmpty(initialSlug)) return;
    setDefaultSlug(initialSlug)
  }, [initialSlug])

  const handleChangeEventInfo = useCallback(( name: string, value: string ) => {
    setEventInfoData( (state: TEventInfoEditFormEntity) => ({...state, [name]: value }));
  }, [setEventInfoData])

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledTitle>1. Informações iniciais do evento</StyledTitle>
      </StyledHeader>
      <StyledFooter>
        <StyledInputBox>
          <label htmlFor="event_name">Nome do evento*: </label>
          <StyledInput 
            id='event_name'
            name='event_name'
            type='text'
            value={eventInfoData.event_name}
            onChange={(e) => handleChangeEventInfo("event_name", e.target.value)}
            required
          />
        </StyledInputBox>
        
        <StyledInputBox>
          <label htmlFor="status_inicial">Status do evento*: </label>
          <StyledSelect
            id="status_inicial"
            name="status_inicial"
            value={eventInfoData.status_inicial || 'INACTIVE'}
            onChange={(e) => handleChangeEventInfo("status_inicial", e.target.value as string)}
          >
            { 
              statusSelected.map((status) => (
            <StyledMenuItem
              value={status.id} 
              key={status.id}>
                {status.name}
            </StyledMenuItem>  
            )) 
            }
          </StyledSelect>
        </StyledInputBox>

        <StyledInputBox>
          <label htmlFor='slug'>Url*:</label>

          {(hasNoDefaultSlug || !isEmpty(defaultSlug)) && 
            <StyledUrl>
              <p>{urlEvent}/</p>
              <SlugInput
                setSlug={(text: string) =>  {
                  handleChangeEventInfo("slug", text)
                }}
                isValidSlug={isValidSlug}
                setIsValidSlug={setIsValidSlug}
                isDuplicate={false}
                eventId={eventId}
                initialSlug={initialSlug}
              />
            </StyledUrl>
          }
        </StyledInputBox>
      </StyledFooter>
    </StyledContainer>
  )
}

export default eventInfoEditForm