import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from '@mui/material';
import React, { useState } from "react";
import { StyledInput, StyledLabel } from "./input-login-styled";
import { TInputLoginProps } from "./types";

type State = 'password' | 'text' | 'email';

const InputLogin = ({
  label, 
  fieldRef,
  name,
  type,
  value,
  error,
  helperText,
  onBlur,
  onChange,
  showPassword,
}: TInputLoginProps) => {

  const [changeShowPassword, setChangeShowPassword] = useState<State>(type)

  const handleClickShowPassword = () => {
    setChangeShowPassword(prev => prev === 'password' ? 'text' : 'password')
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput
        ref={fieldRef}
        required
        margin="normal"
        name={name}
        type={changeShowPassword}
        value={value}
        error={error}
        helperText={helperText}
        onBlur={onBlur}
        onChange={onChange}
        InputProps={
          showPassword?
          {
            endAdornment: 
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {changeShowPassword === 'text' ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            :
          undefined  
        }
      >
      </StyledInput>
    </>
  );  
}

export default InputLogin;