import React from "react";
import { isNil } from 'ramda';

import { ReactComponent as Google } from "./google_maps_logo.svg";
import { ReactComponent as Yelp } from "./yelp_logo.svg";
import { ReactComponent as Foursquare } from "./foursquare_logo.svg";
import { ReactComponent as Waze } from "./waze_logo.svg";
import { ReactComponent as AppleMaps } from "./apple_maps_logo.svg";
import { ReactComponent as Facebook } from "./facebook_logo.svg";
import { ReactComponent as WhatsApp } from "./whatsapp_logo.svg";
import { ReactComponent as LinkedIn } from "./linkedin_logo.svg";
import { ReactComponent as Instagram } from "./instagram_logo2.svg";

export const PlatformsLogosSVG: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>
> = {
  Google,
  Yelp,
  Foursquare,
  Waze,
  AppleMaps,
  Facebook,
  WhatsApp,
  LinkedIn,
  Instagram,
};

type Test = {
  platform: string;
  className?: string;
};

export const getPlatformLogo = ({ platform = '', className = "" }: Test) => {
  const PlatformLogo = PlatformsLogosSVG[platform];
  
  return (
    <>
      {!isNil(PlatformLogo) &&<PlatformLogo className={["platform-logo-svg", className].join(" ")} />}
    </>
  );
};
