import styled from "styled-components";

export const StyledBenefitsListContainer = styled.ul`
  hr {
    margin: 0.5rem 0rem;
    border-top: 1px solid ${(props) => props.theme.colors.grey};
  }
`;

export const StyledBenefitItem = styled.li`
  text-align: left;
  color: ${(props) => props.theme.colors.primaryGrey};
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
