import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LocaleCard from '../../../../shared/components/locale-card/locale-card';
import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack, Tab, Tabs, TextField, Typography, } from '@mui/material';
import Icon from '../../../../shared/components/Icon/Icon';
import styled from 'styled-components';
import Header from '../../../../shared/components/header/header';
import Breadcrumbs from '../../../../shared/components/breadcrumbs/breadcrumbs';
import Paginator from '../../../../shared/components/pagination/pagination';
import { findAllBatchCheckLocatios } from '../../../../shared/services/batch-check-location/find-all';
import { useParams } from 'react-router-dom';
import debounce from 'lodash/debounce'
import omitBy from 'lodash/omitBy'
import { findOneBatchCheck } from '../../../../shared/services/batch-check/find-one';
import { socket } from '../../../../shared/utils/socket';

export const LoadingList = () => {
    return (
        <Stack gap={2} justifyContent="center" alignItems="center">
            <CircularProgress />
        </Stack>
    )
}

const EmptyResults = ({ text }: { text: string; }) => {
    return (
        <Typography textAlign="center" variant="body1">{text}</Typography>
    )
}

const StyledTabs = styled(Tabs)`
  & .MuiTabs-flexContainer {
    justify-content: center;
    border-bottom: 2px solid #EFEFEF;
  }
`;

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box my={2}>
                    {children}
                </Box>
            )}
        </div>
    );
}



const Single: React.FC = () => {
    const [tab, setTab] = useState(0);
    const { id } = useParams();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0);
    const [expandeds, setExpandeds] = useState<number[]>([]);
    const [batch, setBatch] = useState<any>(null)

    const [query, setQuery] = useState({
        locationName: '',
        status: '',
        platform: '',
        relevance: '',
        page: 0,
        pageSize: 10,
    })

    const onSuccess = (data: any) => {
        setList(data[0].map(({ location_name, ...rest }: any) => {
            return { ...rest, name: location_name }
        }));
        setTotal(data[1])
    }

    const onSuccessBatch = (data: any) => {
        setBatch(data)
    }

    const fetch = async (params = {}, onSuccessCb = onSuccess) => await findAllBatchCheckLocatios(id, { params, onSuccess: onSuccessCb, isLoading: setLoading, })
    const fetchBatch = async () => await findOneBatchCheck(id, { onSuccess: onSuccessBatch })

    const fetchDebounced = useCallback(debounce((params: any) => fetch(omitBy(params, v => !v)), 1000), [])

    const handleSearchStatus = (value: string, key: string) => {
        setQuery((prev) => {
            const newParams = {
                ...prev,
                [key]: value,
                page: 0,
            }
            fetchDebounced(newParams)
            return newParams;
        })
    }


    useEffect(() => {
        const onSuccessConnection = (data: any) => {
            onSuccess(data);
            fetchBatch();
            socket.on('update-location', (updatedRow) => {
                setList((prev: any) => prev.map((item: any) => {
                    if (item.id === updatedRow.id) {
                        return {
                            ...item,
                            ...updatedRow,
                        }
                    } else {
                        return item
                    }
                }))
            })
        }

        fetch({}, onSuccessConnection)

        return () => {
            socket.off('update-location')
        }
    }, [])

    const breadcrumbItems = [
        { label: 'Check individual', href: '/dashboard/event/list' },
        { label: 'Check em massa', href: '/dashboard/batch/list' },
        { label: batch?.company_name, href: `/dashboard/batch/list/${id}`, current: true },
    ];

    const handleAccordionChange = (index: number) => {
        if (expandeds.includes(index)) {
            setExpandeds((prevExpandeds) => prevExpandeds.filter((item) => item !== index));
        } else {
            setExpandeds((prevExpandeds) => [...prevExpandeds, index]);
        }
    };
    const expandAll = () => {
        setExpandeds(Array.from({ length: list.length }, (_, index) => index));
    };

    const collapseAll = () => {
        setExpandeds([]);
    };

    const isAllExpanded = useMemo(() => expandeds.length === list.length, [expandeds, list])


    const toggleExpanded = () => {
        if (isAllExpanded) {
            collapseAll()
        } else {
            expandAll()
        }
    }
    return (
        <>
            <Header />
            <Box px={8}>
                <Box mt={4}>
                    <Breadcrumbs items={breadcrumbItems} />
                </Box>
                <Typography fontSize="40px" fontWeight={700} color="#374957">{batch?.company_name || ''}</Typography>
                <Stack direction="row" gap={4}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-helper-label"
                            label="Status"
                            variant="outlined"
                            sx={{ bgcolor: 'white' }}
                            value={query.status}
                            onChange={(e) => handleSearchStatus(e.target.value, 'status')}
                        >
                            <MenuItem value="">
                                <em>Nenhum</em>
                            </MenuItem>
                            <MenuItem value="correct">Correto</MenuItem>
                            <MenuItem value="incorrect">Incorreto</MenuItem>
                            <MenuItem value="nonexistent">Inexistente</MenuItem>
                            <MenuItem value="low_relevance">Baixa relevancia</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-helper-d">Plataforma</InputLabel>
                        <Select
                            fullWidth
                            labelId="demo-simple-select-helper-d"
                            label="Plataforma"
                            variant="outlined"
                            onChange={(e) => handleSearchStatus(e.target.value, 'platform')}
                            sx={{ bgcolor: 'white' }}
                            value={query.platform}
                        >
                            <MenuItem value="">
                                <em>Nenhum</em>
                            </MenuItem>
                            <MenuItem value="Google">Google</MenuItem>
                            <MenuItem value="Yelp">Yelp</MenuItem>
                            <MenuItem value="Foursquare">Foursquare</MenuItem>
                        </Select>
                    </FormControl>

                </Stack>
                <Box mt={2}>
                    <TextField
                        fullWidth
                        label="Nome do local"
                        InputProps={{
                            endAdornment: <Icon name="search" prefix="rs" />
                        }}
                        variant="outlined" size='small' sx={{ bgcolor: 'white' }}
                        value={query.locationName}
                        onChange={(e) => handleSearchStatus(e.target.value, 'locationName')}
                    />
                </Box>
                <Box bgcolor="white" mt={2} borderRadius={"8px"} px={4} pb={2}>
                    <Box
                        sx={{
                            justifyContent: 'center',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                            backgroundColor: 'white',
                            borderBottom: '2px solid #EFEFEF',
                            marginBottom: '24px',
                        }}
                        display="flex"
                        justifyContent="center"
                    >
                        <StyledTabs
                            value={tab}
                            onChange={(e, newValue) => {
                                setTab(newValue);
                                const params = {
                                    locationName: '',
                                    status: '',
                                    platform: '',
                                    relevance: newValue === 1 ? 'high' : !!newValue ? 'low' : '',
                                    page: 0,
                                    pageSize: 10,
                                };
                                setQuery(params);
                                setList([]);
                                const fetchParams = { relevance: newValue === 1 ? 'high' : !!newValue ? 'low' : '', page: 0 };
                                fetch(omitBy(fetchParams, v => !v));
                            }}
                            sx={{ flexGrow: 1 }}
                        >
                            <Tab label={'Todos'}></Tab>
                            <Tab label="Alta relevância"></Tab>
                            <Tab label="Baixa relevância"></Tab>
                        </StyledTabs>
                        <Box my="auto">
                            <Button onClick={toggleExpanded} startIcon={<Icon name={`${isAllExpanded ? 'caret-up' : 'caret-down'}`} prefix="sr" />} variant="outlined">{isAllExpanded ? 'Recolher todos' : 'Expandir todos'}</Button>
                        </Box>
                    </Box>
                    <TabPanel value={tab} index={0} >
                        <Stack gap={2}  >
                            {loading ? <LoadingList /> : list.length ? list.map((props: any, index: number) => (
                                <LocaleCard key={props.id} {...props} onChange={() => handleAccordionChange(index)} open={expandeds.includes(index)} />
                            )) : <EmptyResults text="Não foi encontrado nenhum local" />}
                        </Stack>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Stack gap={2}  >
                            {loading ? <LoadingList /> : list.length ? list.map((props: any, index: number) => (
                                <LocaleCard key={props.id} {...props} onChange={() => handleAccordionChange(index)} open={expandeds.includes(index)} />
                            )) : <EmptyResults text="Nenhum local com alta relevância" />}
                        </Stack>
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <Stack gap={2}  >
                            {loading ? <LoadingList /> : list.length ? list.map((props: any, index: number) => (
                                <LocaleCard key={props.id} {...props} onChange={() => handleAccordionChange(index)} open={expandeds.includes(index)} />
                            )) : <EmptyResults text="Nenhum local com baixa relevância" />}
                        </Stack>
                    </TabPanel>
                    {!loading && <Box display="flex" justifyContent="right">
                        <Paginator
                            rowsPerPageOptions={[5, 10, 25]}
                            count={total}
                            rowsPerPage={query.pageSize}
                            page={query.page}
                            onPageChange={(ev, newPage) => {
                                setQuery(prev => {
                                    const newValue = { ...prev, page: newPage }
                                    fetch(omitBy(newValue, v => !v))
                                    return newValue
                                })
                            }}
                            onRowsPerPageChange={(ev) => {
                                setQuery(prev => {
                                    const newValue = { ...prev, pageSize: Number(ev.target.value) }
                                    fetch(omitBy(newValue, v => !v))
                                    return newValue
                                })
                            }}
                        />
                    </Box>}
                </Box>
            </Box>
        </>
    )
}

export default Single;