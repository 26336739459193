import { Navigate, Outlet } from "react-router-dom";
import validateToken from "../../services/auth/validateToken";
import { userSetTokenLikeExpired } from "../../utils/setUsetLikeExpired";

type TPrivateRouter = {
  isAuthenticated: boolean;
  redirectPath: string;
  accessToken: string | null;
};

export const PrivateRouter = ({
  isAuthenticated,
  redirectPath,
  accessToken,
}: TPrivateRouter) => {
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }
  
  validateToken({
    accessToken: accessToken || '',
    setTokenLikeExpired: userSetTokenLikeExpired
  });

  return <Outlet />;
};
