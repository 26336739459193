import { Dialog } from "@mui/material";
import { lighten } from "polished";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 100%;
    max-width: fit-content;
  }
`;

export const StyledDialogTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid ${props => props.theme.colors.black};
  font-weight: 600;
  background: ${props => lighten(0.95, props.theme.colors.black)};

  button {
    margin-left: 0.8rem;
  }
`;

export const StyledDialogTitle = styled.h3`
  font-size: 1rem;
  font-family: Poppins;
  font-weight: normal;
  color: ${props => props.theme.colors.black};
`;

export const StyledDialogContent = styled.div`
  text-align: center;
  font-size: 0.8rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;

  & + & {
    padding-top: 1rem;
    border-top: 1px solid ${props => lighten(0.8, props.theme.colors.black)};
  }

  .icon {
    width: 30px;
    height: 30px;
  }
`;

export const StyledLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  gap: 0.5rem;
`;

export const StyledPlatformLog = styled.pre`
  color: ${props => lighten(0.4, props.theme.colors.black)};
  text-align: left;
`;
