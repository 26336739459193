import { TablePagination, TablePaginationProps } from "@mui/material";

export default function Paginator(props: TablePaginationProps) {
    return (
        <TablePagination
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
            }
            sx={{ border: 0 }}
            {...props}
        />
    );
}
