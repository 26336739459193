import axios from "axios";
import { TGetAllFromLeadsResponse } from "./types";

type TGetAllFromLeadsParams = {
  name?: string;
  company?: string;
  phone?: string;
  email?: string;
  start_date?: string;
  end_date?: string;
  city?: string;
  state?: string;
  pageSize?: number;
  page?: number;
  pdv_quantity?: string;
  company_field?: string;
}

type TGetAllFromLeads = {
  eventId?: string;
  accessToken?: string | null;
  params?: TGetAllFromLeadsParams;
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  setTokenLikeExpired?: () => void;
}

export default async function getAllFromLeads({
  eventId,
  accessToken,
  params,
  setTokenLikeExpired,
  setIsFetching,
 }: TGetAllFromLeads): Promise<TGetAllFromLeadsResponse> {
  try {
    if(setIsFetching) setIsFetching(true);

    const { data } = await axios.get(
      `${process.env.REACT_APP_LEAD_API_URL}/get-all-from-event/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params
      }
    );

    return data;
  } catch (err: any) {
    if (err.response?.status === 401 && setTokenLikeExpired) setTokenLikeExpired();

    return null;
  } finally {
    if (setIsFetching) setIsFetching(false)
  }
}