import {
  StyledContainer,
  StyledContent,
  StyledRate,
  StyledRateText,
  StyledRateWrapper,
  StyledTitle,
} from "./relevance-panel-styles";
import { TRelevancePanelProps } from "./types";

const RelevancePanel = ({ relevance, relevanceRate }: TRelevancePanelProps) => {
  const relevanceColor = relevance === "Baixa" ? "#FF5C5C" : "#00DA91";
  
  return (
    <StyledContainer borderColor={relevanceColor}>
      <StyledTitle color={relevanceColor}>{`${relevance} relevância online`}</StyledTitle>
      <StyledContent>
        <StyledRateWrapper>
          <StyledRate>{relevanceRate.correct.toFixed(1)}%</StyledRate>
          <StyledRateText>Correto</StyledRateText>
        </StyledRateWrapper>

        <StyledRateWrapper>
          <StyledRate type="incorrect">
            {relevanceRate.incorrect.toFixed(1)}%
          </StyledRate>
          <StyledRateText isorange>Incorreto</StyledRateText>
        </StyledRateWrapper>

        <StyledRateWrapper>
          <StyledRate type="nonexistent">
            {relevanceRate.nonexistent.toFixed(1)}%
          </StyledRate>
          <StyledRateText isred>Inexistente</StyledRateText>
        </StyledRateWrapper>
      </StyledContent>
    </StyledContainer>
  );
};

export default RelevancePanel;
