import styled from "styled-components";

export const StyledHeader = styled.header<{ bkColor: string; }>`
  padding: 3rem 1rem;
  width: 100%;
  background-color: ${props => props.bkColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media print {
    padding: 1rem;
  }
`;

export const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2.5rem;
`;

export const StyledTitle = styled.h1<{ color: string; }>`
  color: ${props => props.color};
  margin-bottom: 1.5rem;

  @media print {
    font-size: 1.5rem;
  }
`;

export const StyledDescription = styled.p<{ color: string; }>`
  color: ${props => props.color};
  margin-bottom: 1.5rem;
  font-weight: 200;
`;

export const StyledLinksContainer = styled.div`
  display: flex;
  gap: 3rem;

  @media print {
    display: none;
  }
`;

export const StyledButton = styled.button<{ color: string; }>`
  color: ${props => props.color};
  background: none;
  border: none;

  display: flex;
  align-items: center;
  
  font-family: 'Poppins';
  font-weight: 200;
  font-size: 0.7rem;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  & svg {
    margin-right: 0.5rem;
  }
`;
