import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const GoogleIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 350 500" >
            <g clip-path="url(#clip0_1825_22842)">
                <path d="M96.9346 370.39C111.395 388.818 124.557 408.23 136.323 428.485C146.369 447.534 150.557 460.451 157.899 483.403C162.402 496.085 166.471 499.869 175.221 499.869C184.755 499.869 189.08 493.431 192.422 483.473C199.362 461.797 204.808 445.255 213.399 429.624C230.258 399.447 251.206 372.631 271.789 346.858C277.361 339.564 313.388 297.07 329.608 263.54C329.608 263.54 349.542 226.707 349.542 175.267C349.542 127.15 329.883 93.7781 329.883 93.7781L273.282 108.936L238.909 199.469L230.405 211.952L228.704 214.214L226.443 217.045L222.473 221.567L216.8 227.239L186.191 252.176L109.665 296.361L96.9346 370.39V370.39Z" fill="#34A853" />
                <path d="M17.9531 257.348C36.6302 300.009 72.6466 337.51 97.0145 370.42L226.444 217.104C226.444 217.104 208.209 240.952 175.133 240.952C138.29 240.952 108.525 211.531 108.525 174.433C108.525 148.992 123.831 131.516 123.831 131.516L35.9708 155.058L17.9531 257.347V257.348Z" fill="#FBBC04" />
                <path d="M227.892 7.88564C270.879 21.7453 307.672 50.842 329.927 93.748L226.486 217.015C226.486 217.015 241.792 199.222 241.792 173.94C241.792 135.978 209.826 107.619 175.293 107.619C142.638 107.619 123.883 131.427 123.883 131.427L123.883 53.7698L227.892 7.88428V7.88564Z" fill="#4285F4" />
                <path d="M41.6631 62.3504C67.3375 31.6324 112.515 0.000244141 174.798 0.000244141C205.016 0.000244141 227.782 7.93349 227.782 7.93349L123.782 131.493H50.0963L41.6631 62.3517V62.3504Z" fill="#1A73E8" />
                <path d="M17.9521 257.347C17.9521 257.347 0.769531 223.699 0.769531 174.952C0.769531 128.88 18.6796 88.6077 41.6619 62.3516L123.839 131.506L17.9521 257.347V257.347Z" fill="#EA4335" />
            </g>
            <defs>
                <clipPath id="clip0_1825_22842">
                    <rect width="348.774" height="500" fill="white" transform="translate(0.769531)" />
                </clipPath>
            </defs>
        </SvgIcon>

    )
}

export default GoogleIcon;