import { TLoadingBaseProps } from './types';

import {
  StyledLoading,
  StyledSpinner
} from './loading-styles';

const Loading = ({
  large,
  primary,
  className,
  style,
}: TLoadingBaseProps) => {
  const spinners = [
    'spinner-1',
    'spinner-2',
    'spinner-3',
    'spinner-4',
    'spinner-5',
    'spinner-6',
    'spinner-7',
    'spinner-8',
    'spinner-9',
    'spinner-10',
    'spinner-11',
    'spinner-12',
  ];

  return (
    <StyledLoading
      className={className}
      large={large}
      style={style}
    >
      {spinners.map(spinner => (
        <StyledSpinner
          key={Math.random()}
          large={large}
          className={spinner}
          primary={primary}
        />
      ))}
    </StyledLoading>
  );
};

export default Loading;
