/* eslint-disable no-new-func */
import { useRef, useState, useCallback, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { IRootState } from "../../shared/store-redux";
import { isEmpty, isNil } from "ramda";
import { useReactToPrint } from "react-to-print";
import ReactFadeIn from "react-fade-in";

import DiagnosisHeader from "../../shared/components/diagnosis-header";
import DiagnosisTableMobile from "../../shared/components/diagnosis-table-mobile";
import DiagnosisTable from "../../shared/components/diagnosis-table/diagnosis-table";
import FormFooter from "../../shared/components/form-footer";
import RelevancePanel from "../../shared/components/relevance-panel";
import Retest from "../../shared/components/retest";
import { TDiagnosisCompanyData } from "../../shared/components/diagnosis-table/types";
import { platformsOrder } from "../../shared/components/diagnosis-table/helpers";
import { OverlayLoading } from "../../shared/components";

import { getDiagnosisById } from "../../shared/services/diagnosis/getDiagnosisById";
import getOneLead from "../../shared/services/leads/getOneLead";

import isNilOrEmpty from "../../shared/utils/isNilOrEmpty";

import {
  StyledBottomContent,
  StyledContainer,
  StyledContent,
  StyledContentWrapper,
  StyledSubTitle,
  StyledText,
  StyledTitle,
  StyledUpperContent,
  StyledWrapper,
} from "./diagnosis-styles";

import { TDiagnosisLayout, TFooterLayout } from "./types";
import getDiagnosisStatusPercentage from "../../shared/utils/get-diagnosis-status-percentage";
import LandingPage from "./landing-page/landing-page";
import { htmlToJsDom } from "../../shared/utils/html-to-js-dom";

const Diagnoses = () => {
  const [loading, setLoading] = useState(true);

  const [diagnosisLayout, setDiagnosisLayout] = useState<TDiagnosisLayout>({
    optionalButtonTitle: "botão opcional (SE ATIVO)",
    hasOptionalButton: false,
    backgroundColor: "#025DB1",
    description:
      "Confira nos gráficos o quanto sua empresa está sendo encontrada na internet.",
    optionalButtonColor: "",
    optionalButtonTextColor: "",
    redirect_link: "",
    textColor: "#FFFFF",
    title: "O seu relatório está pronto!",
    second_logo: "",
    showLandingPage: false,
  });

  const [footerLayout, setFooterLayout] = useState<TFooterLayout>({
    background_color: "",
    main_logo: "",
    secondary_logo: "",
    text_color: "",
  });
  const { saasUserToken } = useSelector(
    (state: IRootState) => state.authenticated
  );

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
  });

  const [diagnosisData, setDiagnosisData] = useState<TDiagnosisCompanyData[]>([
    {
      address: "",
      company: "",
      directory: "",
      name_status: "",
      address_status: "",
      phone_number_status: "",
      phone: "",
      not_available: true,
    },
  ]);

  useEffect(() => {
    const facebookDirectory = diagnosisData.find(
      (diagnosis) => diagnosis.directory === "Facebook"
    );

    if (facebookDirectory)
      console.log({
        phone: facebookDirectory?.phone,
        name: facebookDirectory?.company,
        address: facebookDirectory?.address,
      });
  }, [diagnosisData]);

  const { diagnosisId } = useParams();

  const [correctPercentage, setCorrectPercentage] = useState(0);
  const [incorrectPercentage, setIncorrectPercentage] = useState(0);
  const [inexistentPercentage, setInexistentPercentage] = useState(0);
  const [relevance, setRelevance] = useState("Baixa");
  const [slugEvent, setSlugEvent] = useState("");
  const [jsCode, setJsCode] = useState("");
  const [htmlCode, setHTMLCode] = useState("");
  const [codeRunning, setCodeRunning] = useState(false);

  const getCurrentLead = useCallback(async () => {
    if (isNil(diagnosisId)) return;

    const getCurrentLeadResponse = await getOneLead({
      leadId: diagnosisId,
      accessToken: saasUserToken,
    });

    const { event } = getCurrentLeadResponse;

    const slug = event.slug;
    setSlugEvent(slug);

    const { diagnosis } = event;
    const {
      form: { second_logo },
    } = event;
    const { footer } = event;
    const { sponsor } = footer;
    let currentSponsor = "";

    if (!isNilOrEmpty(sponsor)) {
      currentSponsor = sponsor[0].logo;
    }

    const {
      js_code,
      background_color,
      button_background_color,
      button_text,
      button_text_color,
      description,
      has_additional_button,
      redirect_link,
      text_color,
      title,
      html_code,
    } = diagnosis;

    setDiagnosisLayout({
      backgroundColor: background_color,
      description,
      hasOptionalButton: has_additional_button,
      optionalButtonTitle: button_text,
      textColor: text_color,
      title,
      optionalButtonColor: button_background_color,
      optionalButtonTextColor: button_text_color,
      redirect_link,
      second_logo,
      showLandingPage: event.show_landing_page,
    });

    setFooterLayout({ ...footer, secondary_logo: currentSponsor });
    setJsCode(js_code);
    setHTMLCode(html_code);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [diagnosisId, saasUserToken]);

  useEffect(() => {
    getCurrentLead();
  }, [getCurrentLead]);

  useEffect(() => {
    try {
      if (jsCode && !codeRunning) {
        new Function(jsCode)();
        setCodeRunning(true);
      }
      if (htmlCode && !codeRunning) {
        const htmlToJs = htmlToJsDom(htmlCode);
        new Function(htmlToJs.code!)();
        setCodeRunning(true);
      }
    } catch (err) {
      return;
    }
  }, [jsCode, codeRunning, htmlCode]);

  const getDiagnosis = useCallback(async () => {
    if (isEmpty(diagnosisId)) return;

    const diagnosisDataResponse = await getDiagnosisById({
      leadId: diagnosisId || "",
      accessToken: saasUserToken,
    });

    if (isNil(diagnosisDataResponse)) return setDiagnosisData([]);

    const diagnosisReturn = diagnosisDataResponse.map((event: any) => ({
      address: event?.address,
      company: event?.name === "Error" ? null : event?.name,
      directory: event?.platform,
      phone: event?.phone,
      name_status: event?.name_status,
      address_status: event?.address_status,
      phone_number_status: event?.phone_status,
      not_available: event?.not_available,
      order: platformsOrder[event?.platform],
      api_response: event?.api_response,
    }));

    diagnosisReturn.sort(
      (a: { order: number }, b: { order: number }) => a.order - b.order
    );

    const { correct, incorrect, nonexistent } = getDiagnosisStatusPercentage(
      diagnosisDataResponse
    );

    if (correct >= 70) {
      setRelevance("Alta");
    }

    setCorrectPercentage(correct);
    setIncorrectPercentage(incorrect);
    setInexistentPercentage(nonexistent);

    setDiagnosisData(diagnosisReturn);
  }, [diagnosisId, saasUserToken]);

  useEffect(() => {
    getDiagnosis();
  }, [getDiagnosis]);

  return !isEmpty(diagnosisData) ? (
    <StyledWrapper
      background={diagnosisLayout.backgroundColor}
      ref={componentRef}
    >
      <Helmet>
        <meta property="og:title" content="Login - Check Hublocal" />
        <meta
          property="og:description"
          content="Faça login em nossa plataforma."
        />
        <title>HubLocal Check | Diagnóstico</title>
      </Helmet>

      {loading && <OverlayLoading textToLoading="" />}

      {!loading && (
        <ReactFadeIn delay={0}>
          <>
            <DiagnosisHeader
              handlePrint={handlePrint}
              textColor={diagnosisLayout.textColor}
              backgroundColor={diagnosisLayout.backgroundColor}
              description={diagnosisLayout.description}
              logo2={diagnosisLayout.second_logo}
              title={diagnosisLayout.title}
            />

            <StyledContainer background={diagnosisLayout.backgroundColor}>
              <StyledContent>
                <StyledUpperContent>
                  <StyledContentWrapper>
                    <StyledText>
                      Essa é a <strong>qualidade dos seus dados</strong> de
                      localização{" "}
                      <strong>
                        nos principais mapas e listas da internet:
                      </strong>
                    </StyledText>

                    <RelevancePanel
                      relevance={relevance}
                      relevanceRate={{
                        correct: correctPercentage,
                        incorrect: incorrectPercentage,
                        nonexistent: inexistentPercentage,
                      }}
                    />
                  </StyledContentWrapper>

                  <StyledContentWrapper>
                    <Retest
                      hasOptionalButton={diagnosisLayout.hasOptionalButton}
                      buttonTitle={diagnosisLayout.optionalButtonTitle}
                      buttonColor={diagnosisLayout.optionalButtonColor}
                      buttonTextColor={diagnosisLayout.optionalButtonTextColor}
                      redirect_link={diagnosisLayout.redirect_link}
                      slugEvent={slugEvent}
                    />
                  </StyledContentWrapper>
                </StyledUpperContent>

                <StyledBottomContent>
                  <StyledTitle>
                    Será que suas informações estão corretas?
                  </StyledTitle>
                  <StyledSubTitle>
                    Seu cliente utiliza diversas plataformas para achar sua
                    empresa. Descubra agora se suas informações estão corretas
                    em todas elas.
                  </StyledSubTitle>
                  <DiagnosisTable isUserView check={diagnosisData} />
                  <DiagnosisTableMobile data={diagnosisData} />
                </StyledBottomContent>
              </StyledContent>
            </StyledContainer>

            {diagnosisLayout.showLandingPage && (
              <LandingPage
                hasOptionalButton={diagnosisLayout.hasOptionalButton}
                buttonTitle={diagnosisLayout.optionalButtonTitle}
                redirectLink={diagnosisLayout.redirect_link}
              />
            )}

            <FormFooter
              backgroundColor={footerLayout.background_color}
              mainLogo={footerLayout.main_logo}
              poweredBy={footerLayout.secondary_logo}
              textColor={footerLayout.text_color}
            />
          </>
        </ReactFadeIn>
      )}
    </StyledWrapper>
  ) : (
    <Navigate to="/404" />
  );
};

export default Diagnoses;
