import Icon from '../../../shared/components/Icon/Icon';
import { Box, IconButton, Link, Stack, Typography } from '@mui/material';
import LocaleCard from '../../../shared/components/locale-card/locale-card';
import Carousel from './swiper';
import HublocalIcon from '../../../shared/components/icons/hublocal-logo';
import { useNavigate, useParams } from 'react-router-dom';
import ContactUs from '../../../shared/components/contact-us/contact-us';
import StackCard from '../../../shared/components/stack-card/stack-card';
import CirclesPercentage from '../../../shared/components/circles-percentage/circles-percentage';
import { useEffect, useState } from 'react';
import { findBatchResultSumary } from '../../../shared/services/batch-check/find-result-sumary';
import { useIntl } from 'react-intl';
import GoogleAsButtonIcon from '../../../shared/components/icons/google-as-button'
import YelpAsButtonIcon from '../../../shared/components/icons/yelp-as-button'
import FoursquareAsButtonIcon from '../../../shared/components/icons/foursquare-as-button'
import { findAllBatchCheckLocatios } from '../../../shared/services/batch-check-location/find-all';
import ErrorIcon from '../../../shared/components/icons/error';
import SuccessIcon from '../../../shared/components/icons/success';
import { useSelector } from 'react-redux';
import { CustomTypography, FooterWrapper, PageContainer, PageWrapper, ResultContainer, ResultWrapper } from './batch-diagnosis-styles';
import CirclesPercentageAnimated from '../../../shared/components/circle-percentage-animated/circle-percentage-animated';


export type Plataforms = 'google' | 'foursquare' | 'yelp';
function BatchDiagnosis() {
    const isAuthenticated = useSelector<any>(({ authenticated }) => authenticated.isAuthenticated)
    const history = useNavigate();
    const { id } = useParams();
    const [data, setData] = useState<any>({})
    const [list, setList] = useState([])
    const intl = useIntl()

    const onSuccessCards = (data: any) => {
        setList(data[0].map(({ location_name, ...rest }: any) => {
            return { ...rest, name: location_name }
        }));
    }


    const fetch = async () => await findBatchResultSumary(id, { onSuccess: setData })
    const fetchLocaleCards = async () => await findAllBatchCheckLocatios(id, { params: { pageSize: 9999 }, onSuccess: onSuccessCards })

    const percentages = {
        correct: data?.results?.byStatus?.correct || 0,
        incorrect: data?.results?.byStatus?.incorrect || 0,
        nonexistent: data?.results?.byStatus?.nonexistent || 0,
    };

    const platforms = Object.entries(data?.results?.byPlatform || {}).map(([prop, value]: any) => {
        const key = prop as Plataforms;
        const imgs = {
            google: GoogleAsButtonIcon,
            foursquare: FoursquareAsButtonIcon,
            yelp: YelpAsButtonIcon,
        }
        const descriptions = {
            google: 'O Google, como líder no setor, não apenas gerencia o Card do Google, mas também administra outros mapas e listas da internet, ampliando ainda mais a visibilidade das empresas e aumentando suas chances de serem descobertas por um público mais amplo.',
            yelp: 'O perfil no Yelp é de extrema importância para as empresas, pois oferece uma plataforma confiável para avaliações e opiniões dos clientes. Através do Yelp, as empresas podem construir uma reputação sólida, aumentar a visibilidade e atrair novos clientes. Além disso, assim como o Google, o Yelp administra outras listas e diretórios online, ampliando ainda mais o alcance das empresas e possibilitando que se conectem com uma audiência diversificada.',
            foursquare: 'O Foursquare desempenha um papel importante para empresas, permitindo que sejam descobertas e recomendadas por uma base de usuários engajada em busca de novos lugares para explorar. Além disso, o Foursquare também administra outros diretórios e serviços, ampliando ainda mais a exposição das empresas e suas oportunidades de conexão com uma ampla audiência online.'
        }
        return {
            name: key,
            img: imgs[key] || '',
            description: descriptions[key],
            relevance: value > 0.8 ? 'Alta relevancia' : 'Baixa relevancia',
            percentage: Number((value * 100).toFixed(0)),
            icon: value > 0.8 ? SuccessIcon : ErrorIcon,
        }
    })

    const carousel = Object.entries(data?.results?.byPlatform || {}).map(([prop, value]: any) => {
        const key = prop as Plataforms
        const imgs = {
            google: GoogleAsButtonIcon,
            foursquare: FoursquareAsButtonIcon,
            yelp: YelpAsButtonIcon
        }
        const descriptions = {
            google: 'SEO crucial: Google gerencia mapas e listas para visibilidade.',
            yelp: 'Essencial para descoberta de locais, e ampliar exposição empresarial.',
            foursquare: 'Descoberta de empresas, amplia exposição e conexão online.'
        }
        return {
            stack: key,
            description: descriptions[key],
            img: imgs[key],
            percentage: intl.formatNumber(value, { style: 'percent' }),
        }
    })


    useEffect(() => {
        fetch()
        fetchLocaleCards()
    }, [])
    return (
        <PageWrapper>
            <Box maxWidth="1300px" mx="auto">
                <HublocalIcon />
                <CustomTypography fontWeight={400} mt={-6} fontSize="16px" color="#ffffff">CheckList de diagnóstico para a empresa:</CustomTypography>
                {isAuthenticated ? (
                    <Link onClick={() => history('/dashboard/batch/list')} underline="always" color="#ffffff">
                        <CustomTypography mb={[4, 2]} fontWeight={500} fontSize="32px">
                            {data?.company_name}
                        </CustomTypography>
                    </Link>
                ) : <CustomTypography mb={[4, 2]} fontWeight={500} fontSize="32px" color="white">
                    {data?.company_name}
                </CustomTypography>
                }
                <IconButton>
                    <Icon name="download" prefix="sr" fontSize='20px' />
                </IconButton>
            </Box>
            <PageContainer>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Icon name="building" prefix="sr" color="#ffffff" fontSize="42px" />
                    <CustomTypography fontWeight={700} color="#ffffff" variant="h2">Desempenho Geral</CustomTypography>
                    <CustomTypography color="#ffffff" fontWeight={500}>Essa é a qualidade dos seus dados de localização nos principais mapas e listas da internet</CustomTypography>
                </Box>
                <Box mx="auto" display="flex" flexDirection="column" alignItems="center" justifyContent="center" my={4}>
                    <CirclesPercentageAnimated {...percentages} />
                </Box>
                <Box mx="auto" mt={4} px={[0, 4]}>
                    <CustomTypography color="#65FFC4" fontWeight={500}>Plataformas integradas</CustomTypography>
                    <CustomTypography mb={2} color="#FFFFFF" fontSize="40px" fontWeight={700}>Ferramentas poderosas para o seu negócio ser encontrado</CustomTypography>
                    <CustomTypography mb={4} lineHeight="30px" color="#FFFFFF" fontWeight={500} fontSize="20px">Se você possui um negócio, é importante que ele seja facilmente encontrado na internet. Para isso, existem diversas ferramentas poderosas que podem ser utilizadas para aumentar a visibilidade da sua empresa online.</CustomTypography>
                </Box>
                <Box mx="auto" >
                    {platforms.map((props) => <StackCard {...props} />)}
                </Box>
            </PageContainer>
            <Box mx="auto" marginBottom={10}>
                <Typography textAlign="center" mb={[0, 10]} color="#FFFFFF" fontSize="40px" fontWeight={700}>Desempenho nas principais plataformas</Typography>
                <Carousel data={carousel} />
            </Box>

            <ResultWrapper>
                <ResultContainer>
                    <Stack alignItems="center" mx="auto" gap={2} mb={8}>
                        <Icon name="building" prefix="sr" color="#ffffff" fontSize="42px" />
                        <Typography color="#FFFFFF" fontSize="40px" fontWeight={700} textAlign="center">Desempenho por local</Typography>
                        <Typography color="#FFFFFF" fontSize="20px" fontWeight={500} textAlign="center">Essa é a qualidade dos seus dados de localização, em cada um dos seus {list.length} locais, nos principais mapas e listas da internet.</Typography>
                    </Stack>
                    <Stack gap={2} mx="auto" >
                        {list.map((props: any) => (
                            <LocaleCard id={props.id} {...props} />
                        ))}
                    </Stack>
                </ResultContainer>
                <FooterWrapper>
                    <HublocalIcon />
                    <CustomTypography color="white" mb={2} mt={-4}>Ficou alguma dúvida sobre os nossos serviços ou quer conhecer melhor nossos planos?</CustomTypography>
                    <ContactUs />
                </FooterWrapper>
            </ResultWrapper>
        </PageWrapper>
    );
}

export default BatchDiagnosis