/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from "@mui/icons-material";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode";

import DeleteEventDialog from "../../shared/components/delete-event-dialog";
import { TEventDelete } from "../../shared/components/delete-event-dialog/types";
import DuplicateEventDialog from "../../shared/components/duplicate-event-dialog";
import EventsTable from "../../shared/components/events-table";
import FormButton from "../../shared/components/form-button";
import FormFilter from "../../shared/components/form-filter";
import { statusSelected } from "../../shared/components/form-filter/helpers";
import Header from "../../shared/components/header";
import QrcodeDialog from "../../shared/components/qrcode-dialog";
import getAllEvents from "../../shared/services/events/getAllEvents";
import { IRootState } from "../../shared/store-redux";
import isNilOrEmpty from "../../shared/utils/isNilOrEmpty";
import {
  StyledContainer,
  StyledDashboard,
  StyledUrl,
} from "./dashboard-styles";
import { TTableEvent } from "./types";
import Breadcrumbs from "../../shared/components/breadcrumbs/breadcrumbs";

const Dashboard = () => {
  const navigate = useNavigate();
  const { saasUserToken } = useSelector(
    (state: IRootState) => state.authenticated
  );
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [page] = useState(0);
  const [pageSize] = useState(10);
  const [tableData, setTableData] = useState<TTableEvent[]>([]);
  const [isQrCodeDialogOpen, setIsQrCodeDialogOpen] = useState(false);
  const [isDuplcateDialogOpen, setIsDuplicateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<TEventDelete>(Object);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [currentEvent, setCurrentEvent] = useState({
    id: "",
    name: "HubLocal Check - Evento X",
  });

  const handleSearchDebounce = useCallback(
    debounce((text: string) => setSearch(text), 500),
    []
  );

  const generateQRCode = useCallback((url: string) => {
    QRCode.toDataURL(
      url,
      {
        width: 900,
        margin: 2,
      },
      (err, qrCodeUrl) => {
        if (err) return console.error(err);
        setQrCodeUrl(qrCodeUrl);
      }
    );
  }, []);

  const getAllEventData = useCallback(async () => {
    const eventsDataResponse = await getAllEvents({
      accessToken: saasUserToken,
      search,
      status,
      page,
      pageSize,
    });

    if (isNilOrEmpty(eventsDataResponse)) return;

    const eventCheck = eventsDataResponse[0].map((event: any) => ({
      id: event.id,
      status: event.status,
      eventName: event.name,
      event_url: (
        <StyledUrl
          href={process.env.REACT_APP_EVENT_URL_CHECK + event.slug}
          target="_blank"
          rel="noreferrer"
        >
          {process.env.REACT_APP_EVENT_URL_CHECK + event.slug}
        </StyledUrl>
      ),
      created_at: format(new Date(event.created_at), "dd 'de' MMMM 'de' yyyy", {
        locale: ptBR,
      }),
    }));

    setTableData(eventCheck);
  }, [saasUserToken, search, status, page, pageSize]);

  const formatTableData = useMemo(() => {
    return tableData.map((row) => [
      row.id,
      row.status,
      row.eventName,
      row.event_url,
      row.created_at,
      "",
    ]);
  }, [tableData]);

  useEffect(() => {
    getAllEventData();
  }, [getAllEventData]);

  const breadcrumbItems = [
    { label: "Check individual", href: "/dashboard/event/list" },
    { label: "Check em massa", href: "/dashboard/batch/list" },
  ];

  return (
    <StyledContainer>
      <Helmet>
        <meta
          property="og:title"
          content="Painel Administrativo - Check Hublocal"
        />
        <meta
          property="og:description"
          content="Painel Administrativo Check Hublocal."
        />
        <title>Painel Administrativo - Check HubLocal</title>
      </Helmet>
      <Header />
      <StyledDashboard>
        <Breadcrumbs items={breadcrumbItems} />
        <FormButton
          icon={<Add sx={{ height: 16, width: 16 }} />}
          onClick={() => navigate("/dashboard/event/create")}
          uppercase
        >
          Criar um evento
        </FormButton>
        <FormFilter
          statuses={statusSelected}
          onSearchWritten={handleSearchDebounce}
          onStatusSelected={setStatus}
          statusSelected={status}
        />
        <EventsTable
          onDiagnosis={(eventId) => navigate(`/dashboard/event/${eventId}`)}
          onQrCode={(url: string) => {
            generateQRCode(url);
            setIsQrCodeDialogOpen(true);
          }}
          onCopy={(id: string, name: string) => {
            setCurrentEvent({ id, name });
            setIsDuplicateDialogOpen(true);
          }}
          onDelete={() => setIsDeleteDialogOpen(true)}
          onEdit={(eventId) => navigate(`/dashboard/event/edit/${eventId}`)}
          setEventToDelete={setEventToDelete}
          data={formatTableData}
        />

        <QrcodeDialog
          isOpen={isQrCodeDialogOpen}
          onClose={() => setIsQrCodeDialogOpen(false)}
          handleDownload={() => {
            const a = document.createElement("a");
            a.href = qrCodeUrl;
            a.download = "qrcode";
            a.click();
          }}
        >
          <img src={qrCodeUrl} alt="QrCode do Evento" />
        </QrcodeDialog>
        <DuplicateEventDialog
          eventName={currentEvent.name}
          isOpen={isDuplcateDialogOpen}
          onClose={() => setIsDuplicateDialogOpen(false)}
          currentEvent={currentEvent}
          getAllEventData={getAllEventData}
        />
        <DeleteEventDialog
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          eventToDelete={eventToDelete}
          tableData={tableData}
          setTableData={setTableData}
        />
      </StyledDashboard>
    </StyledContainer>
  );
};

export default Dashboard;
