import * as Yup from "yup";
import { TFieldValue, TFormsInfo } from "./types";

const Validations = [
  Yup.object({
    name: Yup.string().required('O seu nome é obrigatório!'),
  }),
  Yup.object({
    email: Yup.string().email('E-mail inválido!').required('E-mail é obrigatório!'),
  }),
  Yup.object({
    phone: Yup.string().length(11).required('O seu telefone é obrigatório!'),
  }),
  Yup.object({
    pdv_quantity: Yup.string().required('A quantidade de pontos físicos é obrigatório.')
  }),
  Yup.object({
    company: Yup.string().required('O nome da empresa é obrigatório!'),
  }),
  Yup.object({
    companyPhone:Yup.string().min(8).required('O telefone da empresa é obrigatório!'),
  }),
  Yup.object({
    company_field: Yup.string().required('O segmento da empresa é obrigatório!'),
  }),
  Yup.object({
    position_in_company: Yup.string().required('A sua posição na empresa é obrigatória!'),
  }),
  Yup.object({
    address: Yup.string().required('O endereço é obrigatório!'),
    number: Yup.string().required('O número é obrigatório!'),
    zipCode: Yup.string().required('O cep é obrigatório!'),
  }),
];

const fields = [
  ['name'],
  ['email'],
  ['phone'],
  ['pdv_quantity'],
  ['company'],
  ['companyPhone'],
  ['company_field'],
  ['position_in_company'],
  ['address', 'number', 'zipCode'],
];

export const getValidation = (position: number) => Validations[position];

export const getFieldValues = (formInfo: TFormsInfo, position: number): TFieldValue => {
  return fields[position].reduce((result, field) => ({...result, [field]: formInfo[field]}), {});
};

export const inputsAttributes = [
  {
    label:"Qual é seu nome? *",
    name:"name",
    placeHolder:"Digite aqui",
  },
  {
    name: "email",
    placeHolder: "Digite aqui",
  },
  {
    label: "E o seu número de telefone / WhatsApp? *",
    name: "phone",
    placeHolder: "Ex: (00) 99999-9999",
    mask: "(99) 99999-9999"
  },
  {
    label: "A sua empresa possui quantos estabelecimentos físicos com fachada? *",
    name: "pdv_quantity",
    placeHolder: "Selecione",
    isSelect: true,
    options: [
      {
        text: 'Nenhum Local',
        value: 'NONE',
      },
      {
        text: '1 Local',
        value: 'ONE',
      },
      {
        text: '2 a 10 Locais',
        value: 'TWOTOTEN',
      },
      {
        text: 'Acima de 10 Locais',
        value: 'MORETHANTEN',
      },
    ],
  },
  {
    label: "Qual o nome da sua empresa? *",
    name: "company",
    placeHolder: "Digite aqui",
  },
  {
    label: "Qual o número de telefone da sua empresa? *",
    name: "companyPhone",
    placeHolder: "Ex: (00) 9999-9999",
  },
  {
    label: "Qual o segmento da sua empresa? *",
    name: "company_field",
    placeHolder: "Selecione",
    isSelect: true,
    options: [
      {
        text: 'Academia',
        value: 'GYM',
      },
      {
        text: 'Alimentação',
        value: 'FOOD',
      },
      {
        text: 'Automotivo',
        value: 'AUTOMOBILE',
      },
      {
        text: 'Beleza',
        value: 'BEAUTY',
      },
      {
        text: 'Moda',
        value: 'FASHION',
      },
      {
        text: 'Saúde',
        value: 'HEALTH',
      },
      {
        text: 'Serviços',
        value: 'SERVICES',
      },
      {
        text: 'Varejo e Atacado',
        value: 'RETAIL',
      },
      {
        text: 'Outros',
        value: 'OTHER',
      },
    ],
  },
  {
    label: "Qual o seu cargo na empresa? *",
    name: "position_in_company",
    placeHolder: "Selecione",
    isSelect: true,
    options: [
      {
        text: 'Sócio / CEO',
        value: 'CEO',
      },
      {
        text: 'Diretor',
        value: 'DIRECTOR',
      },
      {
        text: 'Gerente',
        value: 'MANAGER',
      },
      {
        text: 'Coordenador',
        value: 'COORDINATOR',
      },
      {
        text: 'Supervisor',
        value: 'SUPERVISOR',
      },
      {
        text: 'Analista',
        value: 'ANALYST',
      },
      {
        text: 'Atendente',
        value: 'CLERK',
      },
      {
        text: 'Outros cargos',
        value: 'OTHER',
      },
    ],
  }
]