import axios from 'axios';
import { isNil } from 'ramda';

type TPutObject = {
    batchFile?: boolean;
    accessToken: string | null;
    file: File;
    eventCheck?: string;
    bucketName?: string;
    isCreating?: React.Dispatch<React.SetStateAction<boolean>>;
};

export default async function putObject({
  accessToken,
  file,
  bucketName,
  batchFile = false,
  isCreating,
  eventCheck,
}: TPutObject) {

  if(isNil(file)) return { fileUrl: null };

  const requestOptions = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      bucketName,
      eventCheck,
      batchFile,
    }
  };

  const bodyFormData = new FormData();
  bodyFormData.append('file', file);

  try {
    if (isCreating) isCreating(true);

    const { data } = await axios.post(
      `${process.env.REACT_APP_AWS_API_URL}/upload-file`,
      bodyFormData,
      requestOptions,
    );

    return data;
  } catch (err) {
    return null;
  } finally {
    if (isCreating) isCreating(false);
  }
}