import axios from "axios";
import { TCreateCheckProps } from "./types";

export const createCheck = async ({
  address,
  companyPhone,
  zipCode,
  companyName,
  city,
  state,
  number,
  leadId,
  isFetching,
}: TCreateCheckProps) => {
  try {
    const body = {
      company: companyName,
      address: address,
      city,
      state,
      country: "Brazil",
      phone: companyPhone,
      zipcode: zipCode,
      number,
      lead: leadId,
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_CHECK_API_URL}/create`,
      body
    );
    return data;
  } catch (error) {
    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};
