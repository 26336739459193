import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { breakpoints } from "../../../assets/styles/devices";

export const PageWrapper = styled(Box)`
    background: linear-gradient(180deg, #0080FF 0%, #0039A8 100%);
    height: 100%;
    padding-top: 20px;
    padding-bottom: 80px;
`;

export const PageContainer = styled.div`
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
`

export const ResultWrapper = styled.div`
    background: linear-gradient(180deg, #0080FF 0%, #0039A8 100%);
    padding-top: 80px;
`;

export const ResultContainer = styled.div`
    max-width: 1340px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 16px;
    padding-right: 16px;
`;


export const CustomTypography = styled(Typography)`
text-align: left;
@media screen and (max-width: ${breakpoints.tabletLandscape}) {
text-align: center;
  }
`;

export const FooterWrapper = styled.div`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icon {
    width: 32px;
    height: 32px;
  }
`