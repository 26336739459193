import styled from "styled-components";

export const StyledDashboard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 4rem;
  width: 100%;
  min-height: calc(100vh - 95px);
`;

export const StyledContainer = styled.main`
  max-width: 100%;
  background-color: ${props => props.theme.page.body};
`;

export const StyledUrl = styled.a`
  color: #4d4d4d;
  text-decoration: none;
  transition: 0.2s;

  :hover {
    color: ${props => props.theme.colors.primary};
    text-decoration: underline;
  }
`;