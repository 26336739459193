import axios from "axios";

type TUpdateDiagnosisProps = {
  id: string;
  accessToken: string | null;
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  description?: string;
  text_color?: string;
  background_color?: string;
  redirect_link?: string;
  has_additional_button?: boolean;
  button_text?: string;
  button_background_color?: string;
  button_text_color?: string;
  js_code?: string;
  html_code?: string;
};

export default async function updateDiagnosis({
  id,
  accessToken,
  setIsFetching,
  background_color,
  button_background_color,
  button_text,
  button_text_color,
  description,
  has_additional_button,
  js_code,
  redirect_link = "",
  text_color,
  title,
  html_code,
}: TUpdateDiagnosisProps) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const requestBody = {
    title,
    description,
    text_color,
    background_color,
    redirect_link,
    has_additional_button,
    button_text,
    button_background_color,
    button_text_color,
    js_code,
    html_code,
  };

  try {
    if (setIsFetching) setIsFetching(true);

    await axios.put(
      `${process.env.REACT_APP_DIAGNOSIS_API_URL}/update/${id}`,
      requestBody,
      requestOptions
    );
  } catch (err: any) {
    return err;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
