import { TableCell, TableRow } from "@mui/material";
import styled, { css } from "styled-components";

export const StyledTableBodyRow = styled(TableRow)`
`;

export const StyledTableBodyCell = styled(TableCell)`
  padding: 1rem 1.6rem;
  font-size: 1.6rem;
  border: none;
  text-align: center;
`;

export const StyledStatus = styled.div<{isorange?: boolean; isgrey?: boolean;}>`
  width: 16px;
  height: 16px;
  border-radius: 50%;

  background-color: #52AE3B;
  border: none;

  margin: 0 auto;

  ${ props => props.isorange && css`
    background-color: #E3871A;
  `}

  ${ props => props.isgrey && css`
    background-color: ${props.theme.colors.grey};
  `}
`;

export const StyledActions = styled.div`
  display: flex;
  gap: 1rem;
`;
