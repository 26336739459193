import { isEmpty } from "ramda";

export function htmlToJsDom(htmlString: string) {
  const dom = new DOMParser();

  try {
    const elements = Array.from(
      dom.parseFromString(htmlString, "text/html").head.children
    );

    const jsCode = elements
      .reduce((code, element, index) => {
        const elementName = `element${index}`;

        const createElementCode = `const ${elementName} = document.createElement('${element.tagName.toLowerCase()}');\n`;
        const setAttributesCode = Array.from(element.attributes)
          .map(
            (attr) =>
              `${elementName}.setAttribute(\`${attr.name}\`, \`${attr.value}\`);`
          )
          .join(" ");

        const setInnerHtmlCode = !isEmpty(element.innerHTML)
          ? `${elementName}.innerHTML = \`${element.innerHTML}\`;`
          : "";

        return (
          code +
          createElementCode +
          (setAttributesCode || "") +
          setInnerHtmlCode +
          `head.appendChild(${elementName});\n`
        );
      }, "const head = document.querySelector('head');")
      .replace(/(\r\n|\n|\r)/gm, "");

    new Function(jsCode)();

    return {
      label: "SUCCESS",
      code: jsCode,
    };
  } catch (e) {
    return {
      label: "ERROR",
      code: null,
      error: e,
    };
  }
}
