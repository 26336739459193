export const accordionHelper = [
  {
    question: "A HubLocal trabalha com marketing para redes sociais?",
    answer:
      "Não, não somos uma agência de marketing digital. Não gerenciamos ou produzimos conteúdo para redes sociais. Trabalhamos para tornar seu negócio facilmente encontrado em mapas e listas da internet, o que inclui grandes plataformas como Google Maps, Facebook Places, Apple Maps e mais de cinquenta diretórios.",
  },
  {
    question: "A HubLocal trabalha com tráfego pago?",
    answer:
      "Não, trabalhamos com o tráfego orgânico. Com essa estratégia, a sua empresa é encontrada de forma natural por potenciais clientes, sem depender de estratégias engessadas e repetitivas de mídia paga.",
  },
  {
    question: "Como funciona a estratégia de SEO Local?",
    answer:
      "A estratégia de SEO local é uma abordagem para otimizar seu site de forma a aparecer nos resultados de pesquisa quando as pessoas procuram produtos ou serviços próximos à sua localização. Isso envolve otimizar palavras-chave locais, configurar perfis em plataformas locais, obter avaliações positivas e garantir que as informações de localização estejam precisas. O objetivo é atrair clientes locais para o seu negócio.",
  },
  {
    question: "Eu consigo gerenciar mais de um ponto de venda na HubLocal?",
    answer:
      "Não importa quantos pontos de venda seu negócio possui, seja um ou cem. Centralizando suas informações dentro da plataforma HubLocal, você consegue atualizar as informações de todos eles, de forma fácil, automática e simultânea.",
  },
  {
    question: "Em quanto tempo começo a ver os resultados?",
    answer:
      "Para ser encontrado de forma orgânica por potenciais clientes, trabalhamos em um processo de construção e fortalecimento do seu negócio nos motores de busca online. Os primeiros resultados começam a aparecer após 45 dias de otimização e se concretizam a cada mês junto da HubLocal.",
  },
  {
    question: "Como funciona o pagamento?",
    answer:
      "O pagamento é feito através da modalidade de assinatura, com recorrência mensal através de boleto bancário ou cartão. O contrato não possui fidelidade.",
  },
];
