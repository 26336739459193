import {
  StyledActions,
  StyledButton,
  StyledContent,
  StyledHeader,
  StyledModalContainer,
} from "./modal-styles";

type TModalProps = {
  header: string;
  onAccept: () => void;
  setIsModalOpen: (state: boolean) => void;
  children: string | JSX.Element | JSX.Element[];
};

export const Modal = ({
  onAccept,
  setIsModalOpen,
  header,
  children,
}: TModalProps) => {
  return (
    <StyledModalContainer>
      <StyledHeader>{header}</StyledHeader>
      <StyledContent>
        <p>{children}</p>
        <StyledActions>
          <StyledButton onClick={() => setIsModalOpen(false)} isGrey={true}>
            Cancelar
          </StyledButton>
          <StyledButton onClick={onAccept}>Aceitar</StyledButton>
        </StyledActions>
      </StyledContent>
    </StyledModalContainer>
  );
};
