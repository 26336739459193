import axios from "axios";
import { TCreateLeadProps } from "./types";

export const createLead = async ({
  address,
  companyPhone,
  email,
  eventId,
  name,
  number,
  phone,
  zipCode,
  companyName,
  city,
  state,
  company_field,
  pdv_quantity,
  position_in_company,
  isFetching,
}: TCreateLeadProps) => {
  try {
    const body = {
      client_name: name,
      client_email: email,
      client_phone_number: phone,
      company_name: companyName,
      company_phone_number: companyPhone,
      cep: zipCode,
      address: address,
      number: number,
      event: eventId,
      city,
      state,
      company_field,
      pdv_quantity,
      position_in_company,
    };
  
    const { data } = await axios.post(`${process.env.REACT_APP_LEAD_API_URL}/create`, body);

    return data;
  } catch(error) {
    return null;
  } finally {
    if (isFetching) isFetching(false);
  }
};