import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import BaseIcon from '../../components/Icon'
import styled from 'styled-components';
import png from './background.png'
import { ResultsType, getResultLabel } from '../locale-card/locale-card';

export type StackAppProps = {
    icon: any;
    name: string;
    address: string;
    phone: string;
    appName: string;
    result: ResultsType;
}

const Container = styled(Box)`
  background: url(${png});
  backdrop-filter: blur(5px);
  background-blend-mode: overlay, normal;
  backdrop-filter: blur(40px);
  border-radius: 32px;
`
const StackApp: React.FC<StackAppProps> = ({ icon: Icon, name, address, phone, appName, result }) => {
    return (
        <Container minWidth="400px" maxWidth="400px" p={4}>
            <Stack direction="row" alignItems="center" gap={1} mb={3}>
                <Icon sx={{ fontSize: '32px' }} />
                <Typography fontSize="24px" fontWeight={500} color="white">{appName}</Typography>
            </Stack>
            <Stack gap={2}>
                <Stack direction="row" alignItems="center" gap={2}>
                    <BaseIcon fontSize='24px' color="white" name="home" prefix='sr' />
                    <Typography color="white">{getResultLabel(result.name_status, result.name)}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={2}>
                    <BaseIcon fontSize='24px' color="white" name="map-marker" prefix='sr' />
                    <Typography color="white">{getResultLabel(result.address_status, result.address)}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={2}>
                    <BaseIcon fontSize='24px' color="white" name="phone-call" prefix='sr' />
                    <Typography color="white">{getResultLabel(result.phone_status, result.phone)}</Typography>
                </Stack>
            </Stack>
        </Container>
    )
}

export default StackApp;