import axios from "axios";
import { useFormik } from "formik";
import { SnackbarProvider, useSnackbar } from "notistack";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import hublocalCheckLogo from "../../assets/images/logo_hublocal_check.svg";
import { Button, InputLogin } from "../../shared/components";
import { store } from "../../shared/store-redux";
import { addUserAuthenticated } from "../../shared/store-redux/modules/authenticated/actions";
import { IUser } from "../../shared/store-redux/modules/authenticated/types";
import {
  StyledButtonsGroup,
  StyledContainer,
  StyledForm,
  StyledLogin,
  StyleInputGroup,
  StyleMensage,
} from "./login-styles";

const Login = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Deve ser um e-mail válido")
        .max(255)
        .required("E-mail Obrigatório"),
      password: Yup.string().max(255).required("Senha Obrigatória"),
    }),
    onSubmit: async ({ email, password }) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_AUTH_API_URL}/signin`,
          {
            email,
            password,
          }
        );

        const { user } = response.data;
        const userRedux: IUser = {
          id: user.id,
          email: user.email,
          isActive: user.is_active,
          name: user.name,
          profile_name: user.profiles[0].name,
          profile_description: user.profiles[0].description,
        };
        store.dispatch(
          addUserAuthenticated(userRedux, response.data.accessToken, true)
        );
        navigate("/dashboard/event/list");
      } catch (err: any) {
        if (err.response.status === 401 || err.response.status === 400)
          enqueueSnackbar("Usuário e/ou senha invalido(s).", {
            variant: `error`,
            autoHideDuration: 1500,
          });
        if (!err.response.data || err.response.status === 500)
          enqueueSnackbar("Erro interno do servidor.", {
            variant: `error`,
            autoHideDuration: 1500,
          });
      }
    },
  });

  return (
    <SnackbarProvider maxSnack={1} preventDuplicate>
      <StyledContainer>
        <StyledLogin>
          <Helmet>
            <meta property="og:title" content="Login - Check Hublocal" />
            <meta
              property="og:description"
              content="Faça login em nossa plataforma."
            />
            <title>Login - Check HubLocal</title>
          </Helmet>
          <img src={hublocalCheckLogo} alt="Hublocal Check Logo" />

          <StyledForm onSubmit={formik.handleSubmit}>
            <StyleInputGroup>
              <InputLogin
                error={Boolean(formik.touched.email && formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                label="Email: "
                type="email"
                name="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />

              <InputLogin
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                helperText={formik.touched.password && formik.errors.password}
                label="Senha: "
                type="password"
                name="password"
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                showPassword
              />
            </StyleInputGroup>

            <StyleMensage>
              O acesso para o ambiente HubLocal Check será o mesmo utilizado
              para o ambiente Saas HubLocal. Qualquer dúvida, entre em contato
              com nosso suporte.
            </StyleMensage>

            <StyledButtonsGroup>
              <Button
                className="buttons-group-button"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Entrar
              </Button>
            </StyledButtonsGroup>
          </StyledForm>
        </StyledLogin>
      </StyledContainer>
    </SnackbarProvider>
  );
};

export default Login;
