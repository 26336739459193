import { Reducer } from "redux";
import { IEventState } from './types';

const INITIAL_STATE: IEventState = {
  eventCheck: [],
}

const addEventCheck: Reducer<IEventState> = (state= INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_EVENT_CHECK': {
      const eventCheck = action.payload;
      return {
        ...state,
        addEventCheck: [
          ...state.eventCheck,
          {
            eventCheck,
          }
        ]
      }
    }
    default: {
      return state;
    }
  }
}

export default addEventCheck;