/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";

import {
  StyledContainer,
  StyledHeader,
  StyledTitle,
  StyledFooter,
  StyledContent,
  StyledGroup,
  StyledButton,
  ButtonJS,
} from "./main-edit-form-styled";
import JavascriptIcon from "../../../../assets/images/javascript.svg";
import { TMainEditFormEntity } from "../../../../pages/create-event/types";
import CodeDialog from "../../code-dialog";
import { imageResolution, inputsAttrs } from "./helpers";
import FileInput from "../file-input";
import HTMLIcon from "../../../../assets/images/html.svg";

interface MainEditFormProps {
  mainFormData: TMainEditFormEntity;
  setMainFormData: any;
}

const MainEditForm = ({ mainFormData, setMainFormData }: MainEditFormProps) => {
  const [isModal, setIsModal] = useState<{
    isOpen: boolean;
    type: "html" | "js" | null;
  }>({ isOpen: false, type: null });
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeMainForm = useCallback(
    (name: string, value: File) => {
      const fr = new FileReader();
      fr.onload = () => {
        const img = document.createElement("img");
        const url = fr.result;
        img.src = String(url) || "";
        img.style.position = "absolute";
        img.style.opacity = "0";
        document.body.appendChild(img);

        img.onload = () => {
          const { width, height } = img;
          const { maxWidth, maxHeight, minWidth, minHeight } =
            imageResolution[name];

          document.body.removeChild(img);

          if (width > maxWidth || height > maxHeight) {
            return enqueueSnackbar(
              `A resolução máxima permitida e de ${maxWidth}X${maxHeight}`,
              {
                variant: `error`,
                autoHideDuration: 2000,
              }
            );
          }
          if (width < minWidth || height < minHeight) {
            return enqueueSnackbar(
              `A resolução mínima permitida e de ${minWidth}X${minHeight}`,
              {
                variant: `error`,
                autoHideDuration: 2000,
              }
            );
          } else {
            setMainFormData((state: TMainEditFormEntity) => ({
              ...state,
              [name]: value,
            }));
          }
        };
      };
      fr.readAsDataURL(value);
    },
    [enqueueSnackbar, setMainFormData]
  );

  const handleRemoveImage = () => {
    setMainFormData((state: TMainEditFormEntity) => ({
      ...state,
      second_logo: null,
    }));
  };

  const handleChangeJSCode = useCallback(
    (js_code: string) => {
      setMainFormData((prevState: TMainEditFormEntity) => ({
        ...prevState,
        js_code,
      }));
    },
    [setMainFormData]
  );

  const handleChangeHTMLCode = useCallback(
    (html_code: string) => {
      setMainFormData((prevState: TMainEditFormEntity) => ({
        ...prevState,
        html_code,
      }));
    },
    [setMainFormData]
  );

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledTitle>2. Primeira página: Formulário</StyledTitle>
      </StyledHeader>

      <StyledContent>
        {inputsAttrs.map((attr) => {
          const { name } = attr;
          const { maxWidth, maxHeight, minWidth, minHeight } =
            imageResolution[name];

          return (
            <FileInput
              {...attr}
              onChange={handleChangeMainForm}
              value={mainFormData}
              handleRemoveImage={handleRemoveImage}
              fileOrFiles={mainFormData.second_logo}
              titleTooltip={
                <>
                  <p>Resolução Suportada</p>
                  <p>
                    Min: {minWidth}px x {minHeight}px
                  </p>
                  <p>
                    Max: {maxWidth}px x {maxHeight}px
                  </p>
                </>
              }
            />
          );
        })}
      </StyledContent>

      <StyledFooter>
        <StyledGroup>
          <StyledButton>
            <ButtonJS
              type="button"
              onClick={() => setIsModal({ isOpen: true, type: "html" })}
            >
              <img src={HTMLIcon} alt="logotipo html" />
              ADICIONAR CÓDIGO HTML
            </ButtonJS>
            <ButtonJS
              type="button"
              onClick={() => setIsModal({ isOpen: true, type: "js" })}
            >
              <img src={JavascriptIcon} alt="logotipo javascript " />
              ADICIONAR CÓDIGO JAVASCRIPT
            </ButtonJS>
          </StyledButton>
        </StyledGroup>
      </StyledFooter>

      {isModal.isOpen && (
        <CodeDialog
          isOpen={isModal.isOpen}
          onClose={() => setIsModal({ isOpen: false, type: null })}
          handleCode={
            isModal.type === "js" ? handleChangeJSCode : handleChangeHTMLCode
          }
          code={
            isModal.type === "js"
              ? mainFormData.js_code
              : mainFormData.html_code
          }
          type={isModal.type}
        />
      )}
    </StyledContainer>
  );
};

export default MainEditForm;
