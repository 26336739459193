import styled from 'styled-components';
import { breakpoints } from '../../../assets/styles/devices';

export const StyledFooter = styled.footer<{ backgroundColor: string; textColor: string; }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4rem 8rem;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    padding: 2rem 4rem;
    flex-direction: column;
  }

  @media print {
    display: none;
  }
`;

export const StyledLogo = styled.img`
  max-width: 220px;
`;

export const StyledFooterContentContainer = styled.div<{ isFlexEnd?: boolean; center?: boolean;}>`
  display: flex;
  width: 30%;
  flex-direction: column;
  align-items: ${props => props.isFlexEnd ? 'flex-end' : 'flex-start'};
  justify-content: center;
  gap: 1rem;
  padding: 1rem;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    align-items: ${props => props.center ? 'center': 'flex-end'};
    justify-content: ${props => props.center ? 'center' : 'flex-start'};
    width: 100%;
  }
`;

export const StyledIconContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StyledSocialLink = styled.a<{ backgroundColor: string; }>`
  display: grid;
  place-items: center;
  color: ${props => props.backgroundColor};
  text-decoration: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #3A83F5;
  transition: background-color 0.2s;

  & .MuiSvgIcon-root {
    font-size: 1rem;
  }

  &:hover {
    background-color: #2d68c4;
  }
`;

export const StyledContentHeader = styled.h3`
  font-size: 1rem;

  & .MuiSvgIcon-root {
    color: #3A83F5;
    margin-right: 0.25rem;
    font-size: 1.2rem;
  }
`;

export const StyledText = styled.p`
  font-size: 0.8rem;
`;

export const StyledContactBtn = styled.a`
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 5px;
`;

export const StyledAddress = styled.address`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 1rem;
  font-style: normal;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) { 
    width: 100%;
  }
`;

export const StyledAddressWrapper = styled.div`
  text-align: end;
  margin-top: 1rem;
`;

export const StyledCity = styled.p`
  font-weight: 600;
`;