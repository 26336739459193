import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Stack, TableHead, Typography, Box } from '@mui/material';
import Icon from '../Icon/Icon';
import EllipseIcon from '../icons/elipse';
import { isNil } from 'ramda';
import { ResultsType, getRelevanceColor, getResultLabel } from './locale-card';
import ReactInputMask from 'react-input-mask';
import { format } from 'libphonenumber-js'
import GoogleAsButtonIcon from '../icons/google-as-button';
import FoursquareAsButtonIcon from '../icons/foursquare-as-button';
import YelpAsButtonIcon from '../icons/yelp-as-button';

const imgs = {
    Google: GoogleAsButtonIcon,
    Foursquare: FoursquareAsButtonIcon,
    Yelp: YelpAsButtonIcon,
}

export type ExpandRowProps = {
    data: ResultsType[];
    name: string;
    phone: string;
    address: string;
    number: string;
    neighborhood: string;
    state: string;
    city: string;
    cep: string;
    country: string;
}

function ExpandRow(props: ExpandRowProps) {
    const { data, address, name, phone, ...rest } = props;
    const formattedAddress = `${address}, ${rest.number}, ${rest.neighborhood}, ${rest.city}, ${rest.state}, ${rest.country}, ${rest.cep}`;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell color="red" >
                            <Typography fontWeight={700}>Plataforma</Typography>
                        </TableCell>
                        <TableCell color="red">
                            <Stack gap={0.5} alignItems="left">
                                <Stack direction={"row"} gap={1}>
                                    <Icon color="#415D78" name="home" prefix='sr' />
                                    <Typography fontWeight={700}>Nome do local</Typography>
                                </Stack>
                                <Typography fontWeight={700} fontSize="14px">{name}</Typography>
                            </Stack>
                        </TableCell>
                        <TableCell color="red" align='left'>
                            <Stack gap={0.5} alignItems="left">
                                <Stack direction={"row"} gap={2} alignItems="center" >
                                    <Icon color="#415D78" name="phone-call" prefix='sr' />
                                    <Typography fontWeight={700}>Telefone</Typography>
                                </Stack>
                                <Typography fontWeight={700} fontSize="14px">
                                    {!isNil(phone) ? format(phone, 'BR', 'NATIONAL') : ''}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell color="red">
                            <Stack gap={0.5} alignItems="left">
                                <Stack direction={"row"} gap={2}>
                                    <Icon color="#415D78" name="map-marker" prefix='sr' />
                                    <Typography fontWeight={700}>Endereço</Typography>
                                </Stack>
                                <Typography fontWeight={700} fontSize="14px">{formattedAddress}</Typography>
                            </Stack>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item: ResultsType) => {
                        const IconPlatform = imgs[item.platform] || <></>
                        return (
                            <TableRow key={item.id}>
                                <TableCell>
                                    <Stack flexDirection="row" alignItems="center" gap={1}>
                                        {<IconPlatform />}
                                        {item.platform}
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Stack gap={2} direction="row" alignItems="center">
                                        <Icon color="#415D78" name="home" prefix='sr' />
                                        {getResultLabel(item.name_status, item.name)}
                                        <EllipseIcon color={getRelevanceColor(item.name_status)} fontSize="16px" />
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Stack gap={2} direction="row" alignItems="center">
                                        <Icon name="phone-call" prefix='sr' />
                                        {getResultLabel(item.phone_status, item.phone)}
                                        <EllipseIcon color={getRelevanceColor(item.phone_status)} fontSize="16px" />
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Stack gap={2} direction="row" alignItems="center">
                                        <Icon color="#415D78" name="map-marker" prefix='sr' />
                                        {getResultLabel(item.address_status, item.address)}
                                        <EllipseIcon color={getRelevanceColor(item.address_status)} fontSize="16px" />
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ExpandRow;
