import axios from "axios";

type TUpdateEventProps = {
  id: string;
  accessToken: string | null;
  setIsFetching?: React.Dispatch<React.SetStateAction<boolean>>;
  name?: string;
  slug?: string;
  request_information?: boolean;
  request_company_field?: boolean;
  request_pdv_quantity?: boolean;
  request_terms_of_service?: boolean;
  show_landing_page?: boolean;
  request_position_in_company?: boolean;
  status?: string;
  webhooks?: string[];
};

export default async function updateEvent({
  id,
  accessToken,
  setIsFetching,
  name,
  slug,
  request_information,
  request_company_field,
  request_pdv_quantity,
  request_position_in_company,
  request_terms_of_service,
  show_landing_page,
  status,
  webhooks = [],
}: TUpdateEventProps) {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const requestBody = {
    name,
    slug,
    request_information,
    request_company_field,
    request_pdv_quantity,
    request_position_in_company,
    request_terms_of_service,
    show_landing_page,
    status,
    webhooks,
  };

  try {
    if (setIsFetching) setIsFetching(true);

    const { data } = await axios.put(
      `${process.env.REACT_APP_EVENT_API_URL}/update/${id}`,
      requestBody,
      requestOptions
    );

    return data;
  } catch (err: any) {
    return err;
  } finally {
    if (setIsFetching) setIsFetching(false);
  }
}
