export const inputsAttrs = [
  {
    label: 'Logotipo principal*:',
    name: 'first_logo',
  },
  {
    label: 'Banner lateral desktop*:',
    name: 'left_first_layer',
   
  },
  {
    label: 'Footer do Banner desktop*:',
    name: 'left_second_layer',
  },
  {
    label: 'Imagem de fundo*:',
    name: 'background_image',
  },
  {
    label: 'Logotipo secundário:',
    name: 'second_logo',
    activeBottomClose: true,
  },
  {
    label: 'Banner lateral mobile*:',
    name: 'mobile_second_layer',
  },
  {
    label: 'Footer do Banner mobile*:',
    name: 'mobile_first_layer',
  }
]

type imageResolutionProps = { 
  maxWidth: number;
  maxHeight: number;
  minWidth: number;
  minHeight: number;
}

export const imageResolution: Record<string, imageResolutionProps> = {
  'first_logo': {
    maxWidth: 2000,
    maxHeight: 700,
    minWidth: 140,
    minHeight: 100,
  },

  'second_logo': {
    maxWidth: 2000,
    maxHeight: 700,
    minWidth: 140,
    minHeight: 100,
  },

  'background_image': {
    maxWidth: 1060,
    maxHeight: 1080,
    minWidth: 800,
    minHeight: 815,
  },

  'left_first_layer': {
    maxWidth: 860,
    maxHeight: 1080,
    minWidth: 600,
    minHeight: 753,
  },
  
  'left_second_layer': {
    maxWidth: 860,
    maxHeight: 1080,
    minWidth: 600,
    minHeight: 753,
  },

  'mobile_second_layer': {
    maxWidth: 1080,
    maxHeight: 1920,
    minWidth: 800,
    minHeight: 1422,
  },
  
  'mobile_first_layer': {
    maxWidth: 1080,
    maxHeight: 1920,
    minWidth: 800,
    minHeight: 1422,
  }
}