export const switchOptions = [
  {
    label: "Solicitar dados pessoais?",
    name: "request_information",
    tooltip:
      "Atenção: Ao desativar esta opção, o formulário do Check deste evento não irá solicitar os dados do cliente que está executando a checagem do seu local.",
  },
  {
    label: "Solicitar quantidade de pontos de venda?",
    name: "request_pdv_quantity",
    tooltip:
      "Atenção: Ao desativar esta opção, o formulário do Check deste evento não irá solicitar a quantidade de pontos de vendas físicos do cliente que está executando a checagem do seu local.",
  },
  {
    label: "Solicitar segmento da empresa?",
    name: "request_company_field",
    tooltip:
      "Atenção: Ao desativar esta opção, o formulário do Check deste evento não irá solicitar o segmendo da empresa do cliente que está executando a checagem do seu local.",
  },
  {
    label: "Solicitar cargo na empresa?",
    name: "request_position_in_company",
    tooltip:
      "Atenção: Ao desativar esta opção, o formulário do Check deste evento não irá solicitar o cargo na empresa do cliente que está executando a checagem do seu local.",
  },
  {
    label: "Solicitar autorização de termo",
    name: "request_terms_of_service",
    tooltip:
      "Atenção: Ao ativar esta opção, o formulário do Check deste evento só poderá ser submetido caso o usuário aceite os termos.",
  },
  {
    label: "Habilitar landing page de vendas?",
    name: "show_landing_page",
    tooltip:
      "Atenção: Ao ativar esta opção, uma landing page será adicionada ao final do resultado do check.",
  },
];
