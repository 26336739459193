import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const TrivagoIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox="0 0 500 500" {...props}>
            <path d="M193.457 424.579C-4.78444 324.677 24.8738 146.728 76.3854 67.1194C62.3368 81.168 49.8491 96.7775 37.3615 113.948C23.3128 137.362 -11.0283 201.362 9.26419 280.97C27.9957 360.579 84.1903 415.213 129.458 444.871C201.262 491.7 271.505 502.626 319.895 499.504C393.26 494.822 441.65 452.676 466.625 413.652C480.674 390.237 488.479 368.384 490.04 360.579C482.235 371.506 388.577 521.358 193.457 424.579Z" fill="#C93F1F" />
            <path d="M326.135 419.899C323.013 419.899 319.891 419.899 315.208 421.46C274.623 421.46 229.355 407.411 198.136 391.801C174.722 379.314 143.503 359.021 120.088 327.802C3.01636 176.389 155.99 21.8545 198.136 3.123C190.331 4.68396 180.966 6.24492 170.039 9.36683C145.064 17.1716 109.162 35.9031 76.3814 67.1223C71.6986 73.3661 67.0157 81.1709 62.3328 87.4147C46.7232 110.829 15.504 171.706 32.6746 249.754C51.4061 327.802 106.04 380.875 149.747 408.972C219.99 455.801 287.111 465.166 335.501 462.044C407.305 457.362 454.134 415.216 477.548 379.314C482.231 373.07 485.353 366.826 488.475 360.582C488.475 357.46 490.036 355.899 490.036 355.899C491.597 349.656 493.158 337.168 494.719 334.046C499.401 302.827 496.279 271.608 491.597 245.071C483.792 299.705 440.085 408.972 326.135 419.899Z" fill="#F39200" />
            <path d="M454.146 149.855C449.463 142.05 443.219 132.684 443.219 131.123C436.975 123.318 430.731 113.953 426.048 107.709C413.561 92.0993 391.707 90.5383 376.098 103.026C360.488 115.514 358.927 137.367 369.854 152.977C383.902 170.147 391.707 184.196 401.073 206.049C405.756 215.415 413.561 237.268 418.244 263.805C421.365 287.219 424.487 334.048 399.512 371.511C391.707 383.998 371.415 410.535 332.391 418.339C330.83 418.339 327.708 419.9 324.586 419.9C329.269 419.9 332.391 419.9 337.074 419.9C399.512 415.217 441.658 379.315 461.95 346.535C477.56 321.56 483.804 296.585 483.804 296.585C485.365 290.341 486.926 280.975 486.926 277.853C488.487 266.926 490.048 256 488.487 245.073C485.365 232.585 483.804 221.659 480.682 212.293C475.999 188.879 465.072 168.586 454.146 149.855Z" fill="#007DB0" />
            <path d="M104.474 298.143C99.7909 287.217 95.108 274.729 91.9861 262.241C87.3032 245.071 85.7423 198.242 112.279 159.218C141.937 117.072 174.717 90.5361 251.204 88.9751C276.179 88.9751 294.911 76.4875 298.033 51.5121C301.155 26.5368 283.984 4.68342 257.448 1.56151C248.082 0.000551224 238.716 0.000551224 229.351 0.000551224C229.351 0.000551224 216.863 0.000551224 198.131 3.12247C166.912 15.6101 124.766 43.7073 96.669 88.9751C84.1813 109.268 54.5231 162.34 71.6936 232.583C81.0594 271.607 99.7909 302.826 121.644 327.802C115.401 318.436 109.157 309.07 104.474 298.143Z" fill="#007DB0" />
        </SvgIcon>
    )
}

export default TrivagoIcon;