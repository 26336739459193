import { Dialog } from "@mui/material";
import { darken, lighten } from "polished";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
  padding: 1rem 2rem;

  & .MuiPaper-root {
    width: 80%;
    max-width: 440px;
    border-radius: 5px;
  }
`;

export const StyledDeleteEventDialogHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid ${(props) => lighten(0.9, props.theme.colors.black)};
  font-weight: 600;
  background: ${(props) => props.theme.colors.primary};

  button {
    margin-left: 0.8rem;
  }
`;

export const StyledDialogTitle = styled.h3`
  font-size: 1rem;
  font-family: Poppins;
  font-weight: normal;
  color: ${(props) => props.theme.colors.white};
`;

export const StyledDialogContent = styled.div`
  text-align: center;
  font-size: 0.8rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  hr {
    border: none;
    outline: 0.001rem solid ${(props) => props.theme.colors.grey};
    width: 100%;
  }
`;

export const StyledEventName = styled.h2``;

export const StyledEventInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const StyledButton = styled.button<{
  isgrey?: boolean;
  disabledStyle?: boolean;
}>`
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  width: 10rem;
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.white};

  background-color: ${(props) =>
    props.isgrey
      ? lighten(0.1, props.theme.colors.grey)
      : props.theme.colors.primary};

  background-color: ${(props) =>
    props.disabledStyle && lighten(0.1, props.theme.colors.grey)};

  font-weight: bold;
  cursor: pointer;
  flex: 1;

  &:hover {
    background-color: ${(props) =>
      props.isgrey
        ? lighten(0.05, props.theme.colors.grey)
        : darken(0.15, props.theme.colors.primary)};

    background-color: ${(props) =>
      props.disabledStyle && lighten(0.1, props.theme.colors.grey)};
  }

  &:active {
    background-color: ${(props) =>
      props.isgrey
        ? props.theme.colors.grey
        : darken(0.3, props.theme.colors.primary)};

    background-color: ${(props) =>
      props.disabledStyle && lighten(0.1, props.theme.colors.grey)};
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StyledEventDelete = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
