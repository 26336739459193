import { TextField } from "@mui/material";
import styled from "styled-components";

export const StyledSlugInput = styled(TextField)`
  width: 100%;
  margin: 1rem 0 !important;

  & .MuiInputBase-input {
    padding: 0.25rem 1rem;
  }
`;

export const StyledErrorMessage = styled.p<{ hasError?: boolean; }>`
  opacity: ${ props => props.hasError? 1 : 0 };
  color: ${props => props.theme.colors.red};
  height: 10px;
  line-height: 10px;
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 1rem;
`;