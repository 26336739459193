export enum LeadCompanyFieldEnum {
  "GYM" = "Academia",
  "FOOD" = "Alimentação",
  "AUTOMOBILE" = "Automotivo",
  "BEAUTY" = "Beleza",
  "CONSTRUCTION" = "Home Center",
  "FASHION" = "Moda",
  "HEALTH" = "Saúde",
  "SERVICES" = "Serviços",
  "RETAIL" = "Varejo e Atacado",
  "OTHER" = "Outros",
  "NOT_APPLICABLE" = "Não se Aplica",
}
