import React, { useEffect, useRef } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Box,
    IconButton,
    Stack,
} from '@mui/material';
import Icon from '../../../../../shared/components/Icon/Icon';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledForm, StyledInput } from './batch-form-styles';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';


const schema = yup.object({
    company: yup.string().required('Este campo é obrigatório'),
    email: yup.string().email(),
    file: yup.mixed().test((value: any) => {
        if (value?.name && value?.name?.endsWith('xlsx')) {
            return true
        } else {
            return false
        }
    })
})

const DEFAULT_VALUE = {
    company: '',
    file: undefined,
    email: ''
}

interface ExcelUploadModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: { file: File, company: string, email: string }) => void;
}

function ExcelUploadModal({ open, onClose, onSubmit }: ExcelUploadModalProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const user: any = useSelector<any>(state => state?.authenticated?.user);

    const form = useForm<{ company: string, email: string, file: File | undefined }>({
        defaultValues: {
            company: '',
            email: user.email,
            file: undefined
        },
        resolver: yupResolver(schema)
    });

    function handleFileRemove() {
        form.setValue('file', undefined)
    }

    async function handleSubmit(data: any) {
        return onSubmit(data);
    }

    function handleButtonClick() {
        fileInputRef.current?.click();
    }

    useEffect(() => {
        return () => {
            form.reset(DEFAULT_VALUE)
            form.setValue('email', user.email)
        }
    }, [open]);

    const handleDownload = () => {
        const downloadLink = 'https://hub-saas.s3.amazonaws.com/Planilha_Modelo_Check_em_Massa.xlsx'
        window.open(downloadLink, '_blank');
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" >
            <div
                style={{ boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.24)', isolation: 'isolate', backgroundColor: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <DialogTitle
                    textAlign="center"
                    bgcolor="white"
                    sx={{
                        flex: 1
                    }}
                >
                    Submeter arquivo de diagnóstico
                </DialogTitle>
                <IconButton style={{ marginRight: 2 }} onClick={onClose}>
                    <Icon name="cross" prefix='br' fontSize='14px' />
                </IconButton>
            </div>
            <StyledForm style={{ backgroundColor: '#EFEFEF', }} onSubmit={form.handleSubmit(handleSubmit)}>
                <DialogContent>
                    <Box>
                        <Box display="flex" justifyContent="flex-end">
                            <Button variant="contained" color="warning" onClick={handleDownload}>Baixar planilha modelo</Button>
                        </Box>
                        <Box
                            mt={4}
                            p={4}
                            flexDirection="column"
                            bgcolor="white"
                            sx={{ display: 'flex', alignItems: 'center', gap: '1rem', mb: '1rem' }}
                            style={{ boxShadow: 'inset 1px 1px 2px rgba(0, 0, 0, 0.25)' }}
                            key={JSON.stringify(form.getValues())}
                        >
                            <Icon name='file' prefix='sr' fontSize='24px' />
                            {form.watch('file') ? form.watch('file')?.name : 'Arquivo precisa ser menor que 5MB'}
                            {form.watch('file') ? (
                                <Button variant="contained" color="error" onClick={handleFileRemove}>
                                    Remover arquivo
                                </Button>
                            ) : <Button onClick={handleButtonClick} variant="contained">Upload de arquivo</Button>}
                            <Controller name="file" control={form.control} render={
                                ({ field: { onChange, value } }) =>
                                    <StyledInput
                                        id="file-input"
                                        ref={fileInputRef}
                                        type="file"
                                        accept=".xlsx, .xls"
                                        onChange={(event) => {
                                            if (event.target.files) {
                                                onChange(event.target.files[0]);
                                            }
                                        }}
                                    />} />
                        </Box>
                    </Box>
                    <Stack direction={"column"} gap={2}>
                        <TextField
                            {...form.register('company')}
                            size='small'
                            InputProps={{ sx: { bgcolor: 'white' } }}
                            label="Nome da empresa"
                            fullWidth
                            error={!!form.formState.errors?.company}
                            helperText={form.formState.errors?.company?.message}
                        />
                        <TextField
                            error={!!form.formState.errors?.email}
                            helperText={form.formState.errors?.email?.message}
                            {...form.register('email')}
                            size='small'
                            InputProps={{ sx: { bgcolor: 'white' } }}
                            label="Email"
                            fullWidth
                        />
                    </Stack>
                </DialogContent>
                <DialogActions style={{ backgroundColor: '#EFEFEF', justifyContent: 'center', paddingBottom: '24px' }}>
                    <LoadingButton loading={form.formState.isSubmitting} type="submit" variant="contained">
                        Submeter Empresa
                    </LoadingButton>
                </DialogActions>
            </StyledForm>
        </Dialog >
    );
}

export default ExcelUploadModal;
