import * as Icons from '@mui/icons-material';
import { useMemo } from "react";
import { StyledIconButton, StyledTooltip, StyledWrapper } from "./icon-button-styles";
import { TIconButtonProps } from "./types";

const IconButton = ({
  iconSize = 2,
  isWhite = false,
  onClick,
  iconType = 'Close',
  title = 'Fechar',
  disabled = false,
  CustomIcon,
}: TIconButtonProps) => {

  const Icon = useMemo(() => {
    const iconIndex = Object.keys(Icons).indexOf(iconType);

    if (iconIndex === -1) {
      return Icons.Close;
    }

    return Object.values(Icons)[iconIndex];
  }, [iconType]);

  return (
    <StyledTooltip arrow title={title} classes={{
      popper: 'tooltip-color',
      tooltip: 'icon-button-tooltip',
    }}>
      <StyledIconButton
        iconsize={iconSize}
        iswhite={isWhite? 1: 0}
        onClick={onClick}
        disabled={disabled}
      >
        <StyledWrapper>
          {
            CustomIcon ?
              <CustomIcon /> :
              disabled ?
                <Icon className="" /> :
                <Icon className="icon-button__icon" />
          }
        </StyledWrapper>
      </StyledIconButton>
    </StyledTooltip>
  );
};

export default IconButton;
