/* eslint-disable react/style-prop-object */
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const FoursquareAsButtonIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon viewBox="0 0 100 100" {...props}>
            <image style={{ overflow: 'visible' }} width="3000" height="3000" href={require('./yelp.png')} transform="matrix(3.333334e-02 0 0 3.333334e-02 0 0)">
            </image>
        </SvgIcon>
    )
}

export default FoursquareAsButtonIcon;