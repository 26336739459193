import styled from "styled-components";
import { breakpoints } from "../../../assets/styles/devices";

export const StyledContainer = styled.div<{ direction?: "column" | "row" }>`
  display: flex;
  flex-direction: ${(props) => props.direction ?? "row"};
  gap: 1rem;
  width: 40%;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: 70%;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    width: 90%;
  }
`;

export const StyledEnableAddressButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${(props) => props.theme.colors.white};
  font-size: 1rem;
  line-height: 2.5rem;
  cursor: pointer;
  text-decoration: underline;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    font-size: 0.8rem;
  }
`;
