import { StyledInput, StyledInputBox } from "./form-input-styles";
import { TFormInputProps } from "./types";
import { debounce } from "lodash";

const FormInput = ({ 
  name, 
  onChange, 
  type, 
  value, 
  text, 
  multiline, 
  rows, 
  isInputProps,
  defaultValue, 
  required = false 
}: TFormInputProps) => {
  const delayInMs = 500;

  const handleChangeColor = debounce(( name: string, value: string ) => {
    onChange(name, value);
  }, delayInMs);

  return (
    <StyledInputBox>
    <label htmlFor={name}>{text}</label>
    <StyledInput 
      id={name}
      name={name}
      type={type}
      value={value[name]}
      defaultValue={defaultValue}
      onChange={(e) => {
        if(type === 'color') {
          handleChangeColor(name, e.target.value);
        } else {
          onChange(name, e.target.value)
        }
      }}
      rows={rows}
      multiline={multiline}
      InputProps={{
        endAdornment: isInputProps && <p>{value[name] || '#000000'} </p>
      }}
      required={required}
    />
  </StyledInputBox>
 );
}

export default FormInput;