import styled from "styled-components";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &::before {
    content: none;
  }
`;
