import { Dialog, DialogContent } from '@mui/material';
import { lighten, shade } from 'polished';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)``;

export const StyledDialogTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid ${props => lighten(0.9, props.theme.colors.black)};
  font-weight: 600;
  background: ${props => props.theme.colors.primary};

  button {
    margin-left: 0.8rem;
  }
`;

export const StyledDialogTitle = styled.h3`
  font-size: 1rem;
  font-family: Poppins;
  font-weight: normal;
  color: ${props => props.theme.colors.white};
`;

export const StyledDialogContent = styled(DialogContent)`
  margin-top: 1rem;
  padding: 0 !important;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${props => shade(0.1, props.theme.colors.white)};
`;

export const StyledDownloadBtn = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto 2rem auto;
  font-size: 0.75rem;
  color: ${props => props.theme.colors.primary};
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.7);
  }

  & .MuiSvgIcon-root {
    margin-right: 5px;
    font-size: 1.2rem;
  }
`;