import { useSnackbar } from "notistack";

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { ReactComponent as HubLocalLogo } from '../../../assets/images/logo_hublocal_branco.svg';
import { ReactComponent as LinkIcon } from '../../../assets/images/ui_link.svg';

import isNilOrEmpty from '../../utils/isNilOrEmpty'

import { TDiagnosisHeaderProps } from "./types";

import {
  StyledDescription,
  StyledHeader,
  StyledButton,
  StyledLinksContainer,
  StyledLogoContainer,
  StyledTitle
} from "./diagnosis-header-styles";

const DiagnosisHeader = ({
  handlePrint,
  backgroundColor = '#025DB1',
  description = 'Confira nos gráficos o quanto sua empresa está sendo encontrada na internet.',
  title = 'O seu relatório está pronto!',
  textColor = '#FFFFFF',
  logo2 = '',
}: TDiagnosisHeaderProps) => {
  const { enqueueSnackbar } = useSnackbar();

  function handleClipboard () {
    const currentUrl = window.location.href;
      navigator.clipboard.writeText(currentUrl).then(function() {
        enqueueSnackbar('Link copiado com sucesso!')
    }, function(err) {
      enqueueSnackbar('Não foi possível copiar o texto: ', err);
    });
  }

  return (
    <StyledHeader bkColor={backgroundColor}>
      <StyledLogoContainer>
        <HubLocalLogo width={110} />
        {!isNilOrEmpty(logo2) && <img src={logo2} width={110} alt="Logo 2" />}
      </StyledLogoContainer>
      <StyledTitle color={textColor}>{title}</StyledTitle>
      <StyledDescription color={textColor}>{description}</StyledDescription>
      <StyledLinksContainer>
        <StyledButton color={textColor} onClick={handleClipboard}>
          <LinkIcon />
          Compartilhar link
        </StyledButton>
        <StyledButton color={textColor} onClick={handlePrint}>
          <CloudDownloadIcon />
          Fazer download
        </StyledButton>
      </StyledLinksContainer>
    </StyledHeader>
  );
};

export default DiagnosisHeader;