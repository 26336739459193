import {
  StyledAuthor,
  StyledCardContainer,
  StyledCardFooter,
  StyledCardHeader,
  StyledCardText,
} from "./card-styles";

import Saga from "../../../../assets/images/svg/landing-page/feedbacks/saga.png";
import SagaAvatar from "../../../../assets/images/svg/landing-page/feedbacks/avatar-saga.png";
import Quotes from "../../../../assets/images/svg/landing-page/feedbacks/quotes.svg";
import Rating from "../../../../assets/images/svg/landing-page/feedbacks/rating.svg";

type TProps = {
  text: string;
  logo: string;
  avatar: string;
  author: string;
  position: string;
};

const Card = ({ text, logo, avatar, author, position }: TProps) => {
  return (
    <StyledCardContainer>
      <StyledCardHeader>
        <img src={logo} alt="Logotipo da empresa Saga" />
        <img src={Rating} alt="" />
      </StyledCardHeader>
      <StyledCardText>“{text}”</StyledCardText>
      <StyledCardFooter>
        <img src={avatar} alt="" />
        <StyledAuthor>
          <strong>{author}</strong>
          <span>{position}</span>
        </StyledAuthor>
        <img src={Quotes} alt="" />
      </StyledCardFooter>
    </StyledCardContainer>
  );
};

export default Card;
