import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  html, body {
    height: 100%;
  }
  
  body::-webkit-scrollbar {
    display: none;
  }

  html {
    height: -webkit-fill-available;
  }

  body {
    background-color: ${(props) => props.theme.page.body};
    display: flex;
    flex-direction: column;
  }

  body,
  input,
  button,
  textarea,
  .MuiPaper-root,
  .MuiPopover-paper {
    font-size: 1rem;
    font-family: Poppins !important;
    font-weight: normal;
  }

  img {
    display: block;
    max-width: 100%;
  }

  ul {
    list-style: none;
  }

  .MuiTooltip-tooltip {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    font-size: 1rem;
  }

  #notistack-snackbar {
    font-size: 1rem;
    font-weight: 500;
    max-width: 25rem;
  }

  .MuiGrid-grid-xs-6 {
    max-width: 100%;
  }

  .MuiGrid-item {
    flex-grow: 1;
  }
`;
