import axios from "axios";
import { Dispatch, SetStateAction } from "react";

type TCreateFooterFormProps = {
    accessToken: string | null;
    main_logo: string;
    text_color: string;
    background_color: string;
    isFetching?: Dispatch<SetStateAction<boolean>>;
}

export const createFooterForm = async ({ 
    accessToken,
    main_logo, 
    text_color = '#000000', 
    background_color = '#000000',
    isFetching,
}: TCreateFooterFormProps) => {
    
    try {
        if (isFetching) isFetching(true);
        const body = {
            main_logo,
            background_color,
            text_color,
        };
        const { data } = await axios.post(
            `${process.env.REACT_APP_FOOTER_API_URL}/create`, 
            body,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,    
                }
            },
        );
        return data;
    } catch (err) {
        return null;
    } finally {
        if (isFetching) isFetching(false);
    }
}